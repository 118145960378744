import {Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked} from '@angular/core';
import {SharedDataService} from "../shared-data.service";
import {Location} from "@angular/common";
import {MainService} from "../main.service";
import {MainMenuService} from "../main-menu.service";
import {Subscription} from "rxjs";
import {MapComponent} from "../map/map.component";

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.css'],
})
export class DeliveryComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('childMapID') childMap?: MapComponent;
  isChildMapShowing: boolean = false;
  last_address;
  related;
  restaurants_polygons: any[] = [];
  canWeDeliver;
  company;
  restaurants: [] = [];
  client;
  subscriptions: Subscription[] = [];

  @ViewChild('deliveryStreet') deliveryStreetInput: ElementRef;

  constructor(
    private location: Location,
    private mainService: MainService,
    private sharedService: SharedDataService,
    private menuService: MainMenuService,
    private cdRef: ChangeDetectorRef
  ) {
  }

    getMapData() {
    this.mainService.getRestaurants().subscribe(
      response => {
        this.client = response['client'];
        this.company = response['company'];
        this.restaurants = response['restaurants'];
        for (const key in this.restaurants) {
          if (this.restaurants[key]['delivery_area_polygon'] !== null) {
            this.restaurants_polygons.push(this.restaurants[key]['delivery_area_polygon']);
          }
        }
        response['title'] = 'Зоны доставки';
        this.sharedService.changeData(response);
      }
    );
  }

  setSubscriptions() {
    this.subscriptions.push(
      this.sharedService.currentLastAddress.subscribe(data => this.last_address = data),
      this.sharedService.currentCanWeDeliver.subscribe(data => this.canWeDeliver = data),
      this.sharedService.currentRelatedStreets.subscribe(data => this.related = data));
  }

  goBack() {
    this.menuService.goBack();
  }

  checkIsChildMapShowing() {
    return typeof this.childMap !== 'undefined';
  }

  detectChangesForMap() {
    const isChildMapShowing = this.checkIsChildMapShowing();
    if (isChildMapShowing !== this.isChildMapShowing) {
      this.isChildMapShowing = isChildMapShowing;
      this.cdRef.detectChanges();
    }
  }

  isSuccessAddress(): boolean {
    return this.childMap && this.childMap.canWeDeliver && !this.childMap.deliveryImpossibilityReason
      && this.related.includes(this.last_address.street);
  }

  ngOnInit() {
    this.setSubscriptions();
    this.getMapData();
  }

  ngAfterViewChecked() {
   this.detectChangesForMap();
  }

  ngOnDestroy() {
    this.menuService.setDeafaultDataMap();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

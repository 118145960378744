import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-yes-no-dialog',
  templateUrl: './yes-no-dialog.component.html',
  styleUrls: ['./yes-no-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class YesNoDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  noClose(): void {
    this.dialogRef.close(false);
  }

  yesClose(): void {
    this.dialogRef.close(true);
  }

  ngOnInit() {
  }

}

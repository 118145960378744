<div class="container">
  <ol class="breadcrumb" *ngIf="menu || ( company && company.default_bg_image )">
        <li><a routerLink="/">вход</a></li>
        <li>профиль</li>
  </ol>
  <div class="primary">
    <div class="profile-holder order" *ngIf="client !== undefined">
        <div class="row">
          <div id="user_info" class="col-12">
            <div class="profile-wrap">
              <h2>Ваши данные:</h2>
              <form action="#" class="profile-form" #profileForm="ngForm">
                <div class="form-group">
                  <span class="tel">+{{ client.phone }}</span>
                </div>

                <div class="form-group">
                  <span class="tel">Бонусных баллов: {{ client.loyalty_points }}</span>
                </div>

                <div class="form-group">
                  <input id="email" name="email" class="form-control" type="email" placeholder="Email" [(ngModel)]="client.email">
                </div>
                <div class="form-group">
                  <input id="first_name" name="first_name" class="form-control" type="text" placeholder="Имя" [(ngModel)]="client.first_name">
                </div>
                <!--<div class="form-group radio-group">
                    <span>Пол</span>
                    <div class="radio">
                      <label>
                        <input type="radio" name="optionsRadios" id="optionsRadios1" value="option1" checked>
                        <span class="fake-input"></span>
                        <span class="fake-label">муж</span>
                      </label>
                    </div>
                    <div class="radio">
                      <label>
                        <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2">
                        <span class="fake-input"></span>
                        <span class="fake-label">жен</span>
                      </label>
                    </div>
                 </div>-->
                <div class="form-group">
                  <input id="last_name" name="last_name" class="form-control" type="text" placeholder="Фамилия" [(ngModel)]="client.last_name">
                </div>
                <div class="form-group select-group">
                  <label for="dateSelect">Дата рождения</label>
                  <select id="dateSelect" name="dateSelect" class="form-control" [(ngModel)]="bDay">
                    <option *ngFor="let day of days">{{ day }}</option>
                  </select>
                  <select id="monthSelect" name="monthSelect" class="form-control" [(ngModel)]="bMonth">
                    <option *ngFor="let month of months" [value]="month.id">{{ month.name }}</option>
                  </select>
                  <select id="yearSelect" name="yearSelect" class="form-control" [(ngModel)]="bYear">
                    <option *ngFor="let year of years">{{ year }}</option>
                  </select>
                </div>
                <div class="form-group inform-email">
                  <section>
                    <mat-checkbox name="email_subscription" [(ngModel)]="client.notify_about_orders_by_email" class="green-style">
                      Получать информацию о заказах по email
                    </mat-checkbox>
                  </section>
                </div>
                <div class="form-group btn-wrap">
                  <button type="button" id="changeUserInfo" class="btn btn-info" (click)="changeUserInfo(profileForm.value, profileForm.valid)">Сохранить</button>
                </div>
              </form>
              <form #form [action]="getcard_url" method="post" *ngIf="getcard_url" class="loyalty-card">
                <input type="hidden" name="identity" [value]="client.id"/>
                <input type="hidden" name="phoneNumber" [value]="client.phone"/>
                <input type="hidden" name="points" [value]="client.loyalty_points"/>
                <input type="hidden" name="firstName" [value]="client.first_name"/>
                <input type="hidden" name="secondName" [value]="client.last_name"/>
                <input type="hidden" name="notify_about_orders_by_email" [value]="client.notify_about_orders_by_email"/>
                <input type="hidden" name="crc" [value]="client.crc"/>
                <button type="submit" style="background-image: url('assets/images/add-to-passbook.svg'); border: solid 0px #000000; width: 240px; height: 80px;" (click)="form.submit()"></button>
              </form>
            </div>
          </div>
        </div>
    </div>
  </div>
  <a (click)="goBack()" class="back-page"> назад</a>
</div>

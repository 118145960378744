var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainService } from '../main.service';
import { OnInit } from '@angular/core';
import { MainMenuService } from '../main-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from '../shared-data.service';
import { ImageCompressService, ResizeOptions } from 'ng2-image-compress';
var SurveyComponent = /** @class */ (function () {
    function SurveyComponent(route, router, mainService, sharedService, menuService) {
        this.route = route;
        this.router = router;
        this.mainService = mainService;
        this.sharedService = sharedService;
        this.menuService = menuService;
        this.questions = {};
        this.rawQuestions = [];
        this.client = null;
        this.orderIdentifierWrite = null;
        this.restaurants = null;
        this.selectedRest = null;
        this.selectedTime = null;
        this.selectedDate = null;
        this.firstName = null;
        this.lastName = null;
        this.phoneInput = null;
        this.uploadFile = [];
        this.imagesFullSize = 0;
        this.isShowAdditionalInfo = false;
    }
    SurveyComponent.prototype.setData = function () {
        var _this = this;
        var result;
        if (this.orderIdentifier === null) {
            result = this.mainService.getSurvey();
            this.isShowAdditionalInfo = true;
        }
        else {
            result = this.mainService.getSurveyByOrderNumber(this.orderIdentifier);
        }
        result
            .subscribe(function (response) {
            _this.sharedData = response;
            _this.maxDataSizeToUpload = response['data_upload_max_memory_size'];
            _this.restaurants = response['restaurants'] || null;
            _this.client = response['client'] || null;
            if (_this.selectedRest !== null && !_this.checkValidRest()) {
                _this.selectedRest = null;
                _this.showErrorWithoutScroll('Ресторан не найден');
            }
            if (_this.client !== null) {
                _this.phoneInput = _this.client.phone.slice(1);
                _this.lastName = _this.client.last_name;
                _this.firstName = _this.client.first_name;
            }
            if (response['date_now']) {
                _this.dateNow = new Date(response['date_now']);
                _this.minDateSurvey = new Date(response['date_now']);
                _this.minDateSurvey.setFullYear(_this.minDateSurvey.getFullYear() - 1);
            }
            _this.sharedData.title = 'Отзыв по заказу';
            _this.sharedService.changeData(_this.sharedData);
        }, function (error) {
            if (error['status'] === 400 || error['status'] === 404) {
                _this.menuService.openDialog('Ошибка!', error['error']['error']['description'], true);
                _this.router.navigate(['/заказы.html']);
            }
            else {
                _this.menuService.defaultErrorDialog();
                _this.router.navigate(['/заказы.html']);
            }
        });
    };
    SurveyComponent.prototype.getSurveyQuestions = function () {
        var _this = this;
        this.mainService.getSurveyQuestions()
            .pipe(catchError(function (error) {
            return throwError(error.error);
        }))
            .subscribe(function (response) {
            _this.rawQuestions = response['result'];
            _this.rawQuestions.map(function (question) {
                if (question['question_type'] === 'open_ended') {
                    _this.questions[question['sort_order']] = [question['question'], ''];
                }
                else {
                    if (question['with_option']) {
                        _this.questions[question['sort_order']] = [question['question'], '', ''];
                    }
                    else {
                        _this.questions[question['sort_order']] = [question['question'], ''];
                    }
                }
            });
            _this.questionNumberWithFile = _this.rawQuestions[Object.keys(_this.rawQuestions).length - 1]['sort_order'] + 1;
        });
    };
    SurveyComponent.prototype.deleteFile = function (index) {
        var img = this.uploadFile.splice(index, 1)[0];
        if (img && img.image_base64) {
            this.imagesFullSize -= img.image_base64.length;
        }
    };
    SurveyComponent.prototype.uploadImg = function (files) {
        var _this = this;
        var err = false;
        var option = new ResizeOptions();
        option.Resize_Quality = 800;
        if (files && files.length > 0 && files.length <= 3) {
            var images_1 = [];
            ImageCompressService.filesToCompressedImageSourceEx(files, option).then(function (observableImages) {
                observableImages.subscribe(function (image) {
                    images_1.push(image);
                }, function (error) {
                    _this.menuService.openDialog('Ошибка!', 'Не удалось сжать изображение.', true);
                }, function () {
                    for (var i = 0; images_1 && i < images_1.length; i++) {
                        var image = images_1[i];
                        if (image['type'] !== 'image/jpeg' && image['type'] !== 'image/png') {
                            err = true;
                            continue;
                        }
                        var image_base64 = image.imageDataUrl;
                        if (image_base64 && image_base64.length > (_this.maxDataSizeToUpload / 3 - 1000)) {
                            image_base64 = image.compressedImage.imageDataUrl;
                        }
                        var data = {
                            image_base64: image_base64,
                            image_name: image.fileName
                        };
                        if (image_base64) {
                            _this.imagesFullSize += image_base64.length;
                        }
                        _this.uploadFile.push(data);
                        if (_this.uploadFile && _this.uploadFile.length > 3) {
                            var img = _this.uploadFile.shift();
                            if (img && img.image_base64) {
                                _this.imagesFullSize -= img.image_base64.length;
                            }
                        }
                        if (_this.imagesFullSize > _this.maxDataSizeToUpload - 3000) {
                            _this.menuService.openDialog('Ошибка!', 'Не удалось загрузить все изображения: слишком большой размер файлов.', true);
                            var img = _this.uploadFile.pop();
                            if (img && img.image_base64) {
                                _this.imagesFullSize -= img.image_base64.length;
                            }
                            return;
                        }
                    }
                    if (err) {
                        _this.menuService.openDialog('Ошибка!', 'Можно прикреплять только фотографии.', true);
                    }
                });
            });
        }
        else {
            this.menuService.openDialog('Ошибка!', 'Максимальное количество фото для загрузки — 3.', true);
            return;
        }
    };
    SurveyComponent.prototype.scrollTo = function (el) {
        if (el) {
            el.parentNode.parentElement.scrollIntoView();
        }
    };
    SurveyComponent.prototype.scrollToInput = function (input_id) {
        var input = document.querySelector('#' + input_id);
        this.scrollTo(input);
    };
    SurveyComponent.prototype.scrollToError = function () {
        var firstElementWithError = document.querySelectorAll('.ng-invalid')[1];
        this.scrollTo(firstElementWithError);
    };
    SurveyComponent.prototype.isLastAnswer = function (question, index) {
        return question.length === index;
    };
    SurveyComponent.prototype.isInputHasError = function (idInput) {
        var input = document.getElementById(idInput);
        if (input) {
            return input.classList.contains('ng-invalid') && this.isErrorValidForm;
        }
        return false;
    };
    SurveyComponent.prototype.sendSurvey = function (isValid) {
        var _this = this;
        var errorText = '';
        if (!isValid) {
            errorText = "\u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0434\u0430\u0439\u0442\u0435 \u043E\u0442\u0432\u0435\u0442 \u043D\u0430 \u0432\u043E\u043F\u0440\u043E\u0441\u044B \u0441 \u043F\u043E\u043C\u0435\u0442\u043A\u043E\u0439 *.";
            if (this.orderIdentifier === null) {
                if (this.selectedRest === null) {
                    errorText = 'Пожалуйста, выберите посещённый ресторан.';
                }
                if (this.selectedTime === null || this.selectedTime === '') {
                    errorText = 'Пожалуйста, выберите время посещения ресторана.';
                }
                if (this.selectedDate === null) {
                    errorText = 'Пожалуйста, выберите дату посещения ресторана.';
                }
                if (this.phoneInput === null || this.phoneInput === '') {
                    errorText = 'Пожалуйста, заполните поле номера телефона.';
                }
                if (this.lastName === null) {
                    errorText = 'Пожалуйста, заполните вашу фамилию.';
                }
                if (this.firstName === null) {
                    errorText = 'Пожалуйста, заполните ваше имя.';
                }
            }
            this.showSurveyErrorMessage(errorText);
            return;
        }
        if (!this.checkValidDate() && this.orderIdentifier === null) {
            var dateNow = new Date(this.selectedDate);
            if (this.dateNow < dateNow) {
                errorText = 'Введите корректную дату.';
            }
            if (this.minDateSurvey > dateNow) {
                errorText = 'Оставить отзыв можно только по заказу, сделанному в течение года.';
            }
            this.showSurveyErrorMessage(errorText, 'date_input');
            return;
        }
        if (this.checkValidPhone() && this.orderIdentifier === null) {
            errorText = 'Пример правильного номера - 9211231212.';
            this.showSurveyErrorMessage(errorText, 'phone_input');
            return;
        }
        this.isErrorValidForm = false;
        var formattedQuestions = Object.values(this.questions).map(function (question) {
            if (question.length !== 2) {
                question = question.filter(function (el) { return el !== 'comments'; });
            }
            if (question.length >= 3) {
                question = question.slice(0, 2);
            }
            return question;
        });
        var dateTime = null;
        if (this.selectedDate !== null && this.selectedTime !== null) {
            dateTime = Date.parse(this.selectedDate + ' ' + this.selectedTime) / 1000;
        }
        var data = {
            type: 'by_order',
            order: this.orderIdentifier,
            result: formattedQuestions,
            images: this.uploadFile,
        };
        if (this.client === null && data.order === null) {
            data = __assign({}, data, { phone: '7' + this.phoneInput, first_name: this.firstName, last_name: this.lastName });
        }
        if (data.order === null) {
            data = __assign({}, data, { type: 'not_by_order', dtm: dateTime, restaurant: this.selectedRest, order_number: this.orderIdentifierWrite || null });
        }
        this.mainService.sendSurvey(data)
            .subscribe(function (response) {
            _this.menuService.openDialog('Отлично!', 'Спасибо за отзыв!', true);
            if (response.type_survey === 'by_order') {
                _this.router.navigate(['/заказы.html']);
            }
            else {
                _this.router.navigate(['/']);
            }
        }, function (error) {
            if (error['status'] === 400 || error['status'] === 404 || error['status'] === 500) {
                _this.menuService.openDialog('Ошибка!', error['error']['error']['description'], true);
                if (error['status'] !== 500) {
                    _this.router.navigate(['/заказы.html']);
                }
            }
            else {
                _this.menuService.defaultErrorDialog();
                _this.router.navigate(['/']);
            }
        });
    };
    SurveyComponent.prototype.dateToString = function (data) {
        if (data === undefined) {
            return null;
        }
        var year = data.getFullYear();
        var month = (data.getMonth() + 1).toString();
        if (Number(month) < 10) {
            month = '0' + month;
        }
        var day = data.getDate();
        return year + '-' + month + '-' + day;
    };
    SurveyComponent.prototype.checkValidDate = function () {
        var dateNow = new Date(this.selectedDate);
        return dateNow <= this.dateNow && dateNow >= this.minDateSurvey;
    };
    SurveyComponent.prototype.checkValidPhone = function () {
        return !/^[19]\d{9}$/.test(this.phoneInput);
    };
    SurveyComponent.prototype.checkValidRest = function () {
        for (var i = 0; i < this.restaurants.length; i++) {
            if (this.selectedRest === this.restaurants[i].id) {
                return true;
            }
        }
        return false;
    };
    SurveyComponent.prototype.showSurveyErrorMessage = function (errorText, id_error) {
        if (id_error === void 0) { id_error = null; }
        this.isErrorValidForm = true;
        if (id_error === null) {
            this.scrollToError();
        }
        else {
            this.scrollToInput(id_error);
        }
        this.menuService.openDialog('Ошибка!', errorText, true);
    };
    SurveyComponent.prototype.showErrorWithoutScroll = function (errorText) {
        this.menuService.openDialog('Ошибка!', errorText, true);
    };
    SurveyComponent.prototype.goBack = function () {
        this.menuService.goBack();
    };
    SurveyComponent.prototype.test = function () {
        console.log(1);
        this.selectedRest = 11;
    };
    SurveyComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.route.snapshot.paramMap.get('orderIdentifier') !== null) {
            if (!this.menuService.checkLogin({ show_info: true })) {
                return;
            }
        }
        if (this.route.snapshot.paramMap.get('restaurant')) {
            console.log(this.route.snapshot.paramMap.get('restaurant'));
            this.selectedRest = this.route.snapshot.paramMap.get('restaurant');
        }
        this.orderIdentifier = this.route.snapshot.paramMap.get('orderIdentifier');
        this.route.queryParams.subscribe(function (queryParam) {
            if (queryParam['restaurant']) {
                _this.selectedRest = Number(queryParam['restaurant']);
            }
        });
        this.setData();
        this.getSurveyQuestions();
    };
    return SurveyComponent;
}());
export { SurveyComponent };

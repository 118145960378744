/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./shared-products-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../products-carts/products-carts.component.ngfactory";
import * as i4 from "../products-carts/products-carts.component";
import * as i5 from "../shared-data.service";
import * as i6 from "./shared-products-list.component";
import * as i7 from "@angular/router";
import * as i8 from "../main.service";
import * as i9 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i10 from "../main-menu.service";
var styles_SharedProductsListComponent = [i0.styles];
var RenderType_SharedProductsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SharedProductsListComponent, data: {} });
export { RenderType_SharedProductsListComponent as RenderType_SharedProductsListComponent };
function View_SharedProductsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addAllUnitsToBasket() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0432 \u043A\u043E\u0440\u0437\u0438\u043D\u0443"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn btn-info"; var currVal_1 = (_co.justAddedToBasket ? "active" : ""); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SharedProductsListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "col-md-8 col-md-offset-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "cart text-center"], ["id", "order_table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-products-carts", [], null, null, null, i3.View_ProductsCartsComponent_0, i3.RenderType_ProductsCartsComponent)), i1.ɵdid(6, 114688, null, 0, i4.ProductsCartsComponent, [i5.SharedDataService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SharedProductsListComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 6, 0); var currVal_0 = _co.basket_positions; _ck(_v, 9, 0, currVal_0); }, null); }
export function View_SharedProductsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-shared-products-list", [], null, null, null, View_SharedProductsListComponent_0, RenderType_SharedProductsListComponent)), i1.ɵdid(1, 114688, null, 0, i6.SharedProductsListComponent, [i5.SharedDataService, i7.ActivatedRoute, i8.MainService, i7.Router, i9.CookieService, i10.MainMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharedProductsListComponentNgFactory = i1.ɵccf("app-shared-products-list", i6.SharedProductsListComponent, View_SharedProductsListComponent_Host_0, {}, {}, []);
export { SharedProductsListComponentNgFactory as SharedProductsListComponentNgFactory };

import { Component, OnInit } from '@angular/core';
import { MainService } from "../main.service";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import {SharedDataService} from "../shared-data.service";
import { Router } from "@angular/router";
import {MainMenuService} from "../main-menu.service";

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  menu: any;
  category: any;
  dishes = [];
  company: any;
  title: any;

  sharedData: any;

  constructor(
    private mainService: MainService,
    private sharedService: SharedDataService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private menuService: MainMenuService
  ) { }

  getCategories(): void {
    const id = this.route.snapshot.paramMap.get('categoryId-:categorySlug').split('-')[0];
    this.mainService.getCategoryFromId(id)
      .subscribe(
        response => {
        this.menu = response['menu'];
        this.company = response['company'];
        this.category = response['category'];
        this.title = response['category']['name'];
        this.dishes = response['dishes'];

        this.sharedData = response;
        this.sharedData.title = this.title;
        console.log('data in category: ', this.sharedData);
        this.sharedService.changeData(this.sharedData);
        this.menuService.setEcommerce(this.dishes, 'impressions');
        },error => {
          if (error.status === 423) {
            if (error.error.error.code === 221 || error.error.error.code === 232) {
              this.menuService.openBackToMenuDialog(
                  'Ошибка!',
                  'Выбранная категория недоступна, вы можете заказать блюда из другой категории',
                  'Перейти к списку меню');
            }
          }
          else
            this.menuService.defaultErrorDialog();
      }
      );
  }

  goBack(): void {
    this.menuService.goBack();
  }

  ngOnInit() {
    this.getCategories();
  }

}

<script src="https://api-maps.yandex.ru/2.1/?lang=ru_RU" type="text/javascript"></script>

<div class="container">
  <div class="primary">
    <div class="order">
      <div style="margin-top: 10px">
        <div *ngIf="restaurants_polygons.length!=0 || restaurants.length!=0">
          <app-map [restaurants]="restaurants" [restaurants_polygons]="restaurants_polygons" #childMapID style="width: 100% !important; display: block;"></app-map>
        </div>
        <div class="carousel-main-holder">
          <div class="top-row">
            <p>Единый номер телефона: <br/> <a class="tel-number" attr.href="tel:+{{ single_phone }}">{{ formatted_phone }}</a></p>
            <div (click)="getGeo();" class="delivery-location-icon history-icon"></div>
          </div>
          <div class="col-md-12 restaurant-holder">
            <ul>
              <li *ngFor="let restaurant of restaurants">
                <ul class="text-left">
                  <li *ngFor="let item of restaurant.metro_stations">
                    <div class="metro-station">
                      <div class="metro-icon">
                        <svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
                          <path attr.fill="#{{item.line_hex_color || '888'}}" fill-rule="evenodd"
                                d="M11.528 23.001c-6.354 0-11.529-5.119-11.529-11.444 0-6.383 5.175-11.558 11.529-11.558 6.124 0 11.473 5.175 11.473 11.558 0 6.353-5.119 11.444-11.473 11.444zm5.694-16.36c-.001-.575-.316-1.179-1.265-1.179-.718 0-1.006.317-1.322.949l-3.106 6.469H11.5L8.366 6.411c-.316-.633-.603-.949-1.322-.949-.949 0-1.266.604-1.266 1.178v10.034c0 .548.433.863.949.863.46 0 .977-.315.977-.863V9.2h.03l2.846 5.807c.202.403.461.634.921.634.459 0 .718-.231.919-.634L15.267 9.2h.029v7.474c0 .548.517.863.977.863.518 0 .949-.315.949-.863V6.641z"></path>
                        </svg>
                      </div>
                      <p>{{ item.name }}</p>
                    </div>
                  </li>
                  <li>
                    <a [attr.href]="'http://maps.apple.com/?q='+ restaurant.name + '&ll=' + restaurant.lat + ',' + restaurant.lon + 'z=10&t=s'" target="_blank">
                      <p>{{ restaurant.address }}</p>
                    </a>
                  </li>
                  <li *ngIf="restaurant.phone_ext">
                    <p class="additional-number">Добавочный номер: {{ restaurant.phone_ext }}</p>
                  </li>
                  <li *ngIf="restaurant.distance">
                    <span class="distance">Ресторан в {{ restaurant.distance }} км от вас</span>
                  </li>
                  <li *ngFor="let phone of restaurant.phones" class="tel-link">
                    <i class="icon-phone"></i>
                    <a href="tel:+{{ phone }}">+{{ phone }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="insta-holder">
          <div class="row">
            <div class="column">
              <h2><a attr.href="https://www.instagram.com/{{ insta_user || 'cafe_naparah' }}/">СЛЕДИТЕ ЗА НАМИ
                <strong>В INSTAGRAM</strong>
                {{ insta_user || 'cafe_naparah'}}
              </a>
              </h2>
              <a *ngFor="let photo of instagram_photos" attr.href="https://www.instagram.com/p/{{ photo.shortcode}}/">
                <img class="img" [src]="photo.thumbnail" class="insta-photo"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
   <a (click)="goBack()" class="back-page"> назад</a>
</div>

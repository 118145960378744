import {Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation, AfterViewInit} from '@angular/core';

import {SharedDataService} from "../shared-data.service";
import {MainService} from "../main.service";
import {MainMenuService} from "../main-menu.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TopMenuComponent implements OnInit, AfterViewInit {

  @Input() searchIsVisible: boolean;

  isLogged: boolean;
  isLoading: boolean;
  client: any;
  searchTerm: string;
  suggestions = [];
  @ViewChild('searchDish') searchDish: ElementRef;

  constructor(
    private sharedService: SharedDataService,
    private mainService: MainService,
    private menuService: MainMenuService,
    private router: Router
  ) {
  }

  getData() {
    this.sharedService.currentData.subscribe(
      data => {
        if (typeof data['basket_counter'] !== 'undefined') {
          this.client = data['client'];
        }
      }
    );
  }

  makeSearch() {
    this.router.navigate(['/поиск', this.searchTerm]);
  }

  public onSelect(dish) {
    this.searchTerm = '';
    this.sharedService.changeSearchTerm('');
    this.sharedService.changeSuggestions([]);
    this.menuService.showSelectedDish(dish);
  }

  logOut() {
    this.menuService.openYesNoDialog('Вы действительно хотите выйти?', 'Подтверждение выхода', 'Да', 'Отмена');
    const subscription = this.menuService.yesNoDialogResult.subscribe(
      data => {
        if (data['clicked']) {
          if (data['answer']) {
            this.menuService.logOutNow();
          }
          this.menuService.changeYesNoDialogResult({clicked: false, answer: false});
          subscription.unsubscribe();
        }
      }
    );
  }

  ngOnInit() {
    this.getData();
    this.sharedService.currentLogInStatus.subscribe(status => this.isLogged = status);
    this.sharedService.currentSuggestions.subscribe(data => this.suggestions = data);
    this.sharedService.currentSearchTerm.subscribe(term => this.searchTerm = term);
    this.sharedService.currentSuggestionsStatus.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  ngAfterViewInit() {
    this.menuService.search(this.searchDish.nativeElement);
  }
}

import {Component, OnInit, ViewChild, OnDestroy, ElementRef, AfterViewChecked, ChangeDetectorRef} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {MainService} from "../main.service";
import {SharedDataService} from "../shared-data.service";
import {MainMenuService} from "../main-menu.service";
import {DjangoRequestData} from "../dataDescription";
import {Metrika} from "ng-yandex-metrika";
import {MapComponent} from "../map/map.component";
import {Subscription, throwError} from "rxjs";
import {catchError} from "rxjs/operators";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css'],
})
export class BasketComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('childMapId') childMap: MapComponent;
  isChildMapShowing: boolean = false;
  @ViewChild('closeModalPayPoint') closeModalPayPoint: ElementRef;
  @ViewChild('deliveryStreet') deliveryStreetInput: ElementRef;
  basket_positions: any;
  times: any;
  price_total: any;
  loyalty_points_price_total: any;
  calories_total: any;
  restaurants: any[] = [];
  client: any;
  company: any;
  order_info_visible = false;
  delivery_times: any;
  canWeDeliver;
  finishedCheckingDeliveryPossibility;
  possible_delivery_times: string;
  possible_times: string;
  isMobileApp: boolean;
  selected_restaurant = '';
  selected_presence = '';
  selected_people = '';
  selected_time = '';
  selected_payment = '';
  cash_change = null;
  people = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  last_address;
  comment = '';
  dadata_access: string;
  clientside_id = '';

  visible_warn: boolean;

  remember_restaurant = true;
  should_not_call = false;
  payment_types = null;
  isOpenSharedLinkDiv = false;
  sharedUrl: any;
  isGeneratedSharedUrl = true;
  isShowedSharingBasketIcon = true;

  isPresenseSentEcommerce = false;
  isAddressSentEcommerce = false;
  isPaymentTypeSentEcommerce = false;
  isBasketSentEcommerce = false;
  isCheckoutSentEcommerce = false;
  dishesListEcommerce = [];

  restaurants_polygons: any[] = [];
  related = [];
  last_comments = [];

  last_five_address = [];

  payLoyalty: number = null;
  percentPayloyalty: any;

  inputCode: number = null;
  inProgressSend: boolean = false;
  loyaltyToken: string = '';
  isPointsPaymentConfirmationEnabled: boolean;

  errCodeLoyalty = [];
  countAttempts = 0;

  operationIsLoading = {
    increase_quantity: [],
    decrease_quantity: []
  };

  promoCode = '';
  isConfirmPromoCode = false;
  isPromoCodeWithDiscount = false;
  subscriptions: Subscription[] = [];

  constructor(
    private cookies: CookieService,
    private router: Router,
    private mainService: MainService,
    private sharedData: SharedDataService,
    private menuService: MainMenuService,
    private rootData: DjangoRequestData,
    private metrika: Metrika,
    private cdRef: ChangeDetectorRef
  ) {
  }

  openDialog(title: string, body: string, closeOnNextPage: boolean): void {
    this.menuService.openDialog(title, body, closeOnNextPage);
  }

  setBasketData() {
    this.mainService.getBasketData()
      .subscribe(
        response => {
          this.isPointsPaymentConfirmationEnabled = response['company']['is_points_payment_confirmation_enabled'];
          this.basket_positions = response['basket_positions'];
          this.times = response['times'];
          this.price_total = response['price_total'];
          this.loyalty_points_price_total = response['loyalty_points_price_total'];
          this.calories_total = response['calories_total'];
          this.restaurants = response['restaurants'];
          this.restaurants_polygons = response['restaurants_polygons'];
          this.client = response['client'];
          this.company = response['company'];
          this.percentPayloyalty = response['percent_pay_by_loyalty'];
          this.dadata_access = response['company']['dadata_access_token'];
          this.delivery_times = response['delivery_times'];
          this.possible_delivery_times = response['possible_delivery_times'];
          this.possible_times = response['possible_times'];
          this.last_five_address = response['last_addresses'];
          for (const key in this.last_five_address[0]) {
             this.last_address[key] = this.last_five_address[0][key];
          }
          if (!this.last_address.city) this.last_address.city = 'Санкт-Петербург';
          this.sharedData.changeLastAddress(this.last_address);
          if (response['client']['restaurant_id']) {
            this.selected_restaurant = response['client']['restaurant_id'];
          }
          response['title'] = 'Корзина';
          response['showControls'] = true;
          this.sharedData.changeData(response);
          this.payment_types = response['payment_types'];
          this.last_comments = response['last_comments'];

          this.dishesListEcommerce = this.menuService.prepareDishesForEcommerce(this.basket_positions);
          if (!this.isBasketSentEcommerce) {
            this.menuService.setEcommerce(this.dishesListEcommerce, 'checkout', null, 1);
            this.isBasketSentEcommerce = true;
          }
        },
        error => {
          console.log('Error while loading basket data: ', error);
            this.menuService.defaultErrorDialog();
        }
      );
  }

  checkCoupon() {
    this.mainService.checkCoupon({'coupon' : this.promoCode})
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.promoCode = '';
          const codeError = error.error.error.code;
          let err = 'Неверный промокод';
          if (codeError === 102)
            err = 'Промокод действителен только для первого заказа';
          this.menuService.openDialog(
          'Ошибка!',
          err,
          true
          );
          return throwError(error.error);
        })
      )
      .subscribe( response => {
        this.isConfirmPromoCode = true;

        if (response['discount'] > 0) {
          this.isPromoCodeWithDiscount = true;
          this.payLoyalty = Math.floor(this.price_total * (response['discount'] / 100));
        }
      });
  }

  delete(bp) {
    const bp_id = bp.id;
    const dataToSend = {
      'type': 'delete',
      'basket_position_id': bp_id
    };
    this.menuService.openYesNoDialog('Действительно удалить?', 'Подтверждение удаления блюда', 'Да, удалить!', 'Нет, спасибо');
    const subscriptions = this.menuService.yesNoDialogResult.subscribe(
      data => {
        if (data['clicked']) {

          if (data['answer']) {
            this.mainService.changeBasketPosition(dataToSend)
              .subscribe(
                response => {
                  this.basket_positions = response['basket_positions'];
                  this.price_total = response['price_total'];
                  this.calories_total = response['calories_total'];
                  this.client = response['client'];
                  this.loyalty_points_price_total = response['loyalty_points_price_total'];
                  this.sharedData.changeData(response);
                  this.menuService.setEcommerce([bp.position], 'remove');
                },
                error => {
                  console.log('error while deleting basket position: ', error);
                  this.menuService.defaultErrorDialog();
                }
              );
          }

          this.menuService.changeYesNoDialogResult({clicked: false, answer: false});
          subscriptions.unsubscribe();
        }
      }
    );
  }

  bpOperation(basket_position, operation) {
    const basket_position_id = basket_position.id;
    let isIncreasing = this.operationIsLoading.increase_quantity[basket_position_id],
      isDecreasing = this.operationIsLoading.decrease_quantity[basket_position_id];

    if (isIncreasing === undefined) isIncreasing = false;
    if (isDecreasing === undefined) isDecreasing = false;

    if (isIncreasing !== isDecreasing) {
      ((isIncreasing && operation === 'increase_quantity') || (isDecreasing && operation === 'decrease_quantity'))
        ? console.log("Ignore click. Please wait for the operation to be performed.")
        : console.log("Ignore click. Mutually exclusive operations cannot be performed at the same time.");
      return;
    }

    let ecommerceAction = 'add';

    if (operation === 'decrease_quantity') {
      ecommerceAction = 'remove';
    }

    this.operationIsLoading[operation][basket_position_id] = true;

    const dataToSend = {
      'basket_position_id': basket_position_id,
      'type': operation
    };

    this.mainService.changeBasketPosition(dataToSend)
      .subscribe(
        response => {
          this.operationIsLoading[operation][basket_position_id] = false;
          this.basket_positions = response['basket_positions'];
          this.price_total = response['price_total'];
          this.loyalty_points_price_total = response['loyalty_points_price_total'];
          this.calories_total = response['calories_total'];
          this.client = response['client'];
          this.sharedData.changeData(response);
          this.menuService.setEcommerce([basket_position.position], ecommerceAction);
        },
        error => {
          console.log('error while increase/decrease in basket: ', error);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  showOrderInfo() {
    if (this.client.loyalty_points < this.loyalty_points_price_total) {
      this.menuService.openDialog(
        'Ошибка!',
        'У вас недостаточно баллов для заказа (',
        true
      );
      return true;
    }
    if (this.client['last_name'] == '' || this.client['first_name'] == '') {
      this.menuService.checkNameDialog();
      return true;
    }

    this.metrika.fireEvent('create_order');
    this.sendEventToGoogle('create_order');

    this.order_info_visible = true;
    this.isShowedSharingBasketIcon = false;
    //scroll to element id #order_pos
  }

  selectedTimeCheck() {
    if (!this.selected_restaurant) {
      return;
    }

    for (let i = 0; i < this.times[this.selected_restaurant].length; i++) {
      if (this.times[this.selected_restaurant][i]['stamp'] === this.selected_time)
        return;
    }

    this.selected_time = '';
  }

  canOrder() {
    return ((this.selected_presence !== 'delivery' && this.selected_restaurant === '') ||
      this.selected_time === '' || this.selected_people === '' || this.selected_presence === '' ||
      (this.selected_payment === '' && this.selected_presence === 'delivery'));
  }

  sendOrder() {
    let rest_id = '';
    let time = '';
    let people = '';
    let p_state = '';
    let address: any;

    if (!this.isPromoCodeWithDiscount && this.canNotDeliverMinPriceWithPoint()) {
      this.showErrorModalMinPriceWithPoint();
      return;
    }

    if (this.isPointsPaymentConfirmationEnabled && this.payLoyalty > 0 && (!this.inProgressSend || this.loyaltyToken === '')) {
      this.menuService.openDialog(
            'Ошибка!',
            'Подтвердите оплату баллами!',
            true
          );
      return;
    }
    if (!this.isPromoCodeWithDiscount && this.payLoyalty !== null && this.payLoyalty !== 0 && !this.needConfirmPay()) {
      let text = 'Указанная сумма баллов больше, чем у вас имеется';
      if (this.payLoyalty < this.client.loyalty_points) {
        text = 'Указанная сумма баллов больше, чем у вас имеется';
      }
      if (this.payLoyalty > this.price_total * this.percentPayloyalty) {
        text = `Указанная сумма баллов больше, чем ${this.percentPayloyalty * 100}% от суммы заказа`;
      }
      if ((this.payLoyalty <= this.client.loyalty_points - this.loyalty_points_price_total) && this.loyalty_points_price_total > 0) {
        text = 'Вы не можете потратить введенную сумму баллов, так как у вас к оплате' + this.loyalty_points_price_total + 'баллов';
      }
      this.menuService.openDialog(
        'Ошибка!',
        text,
        true
      );
      return;
    }

    if (this.isPointsPaymentConfirmationEnabled && this.payLoyalty > 0 && (!this.inProgressSend || this.loyaltyToken === '')) {
      this.menuService.openDialog(
            'Ошибка!',
            'Подтвердите оплату баллами!',
            true
          );
      return;
    }

    this.metrika.fireEvent('send_order');
    this.sendEventToGoogle('send_order');

    if (this.selected_presence === '' && this.company.presences.length === 1) {
      this.selected_presence = this.company.presences[0].key;
    }
    if (this.client.role !== 'table') {
      let errorTrigger = false;

      if (this.canOrder()) {
        errorTrigger = true;
      }
      else {
        rest_id = this.selected_restaurant;
        time = this.selected_time;
        people = this.selected_people;
        p_state = this.selected_presence;
      }

      if (this.selected_presence === 'delivery') {
        if (!this.isCheckoutSentEcommerce) {
          this.menuService.setEcommerce(this.dishesListEcommerce, 'checkout', null, 6);
          this.isCheckoutSentEcommerce = true;
        }

        console.log(this.last_address.street);
        console.log(this.last_address.house);
      if (this.last_address.street === '' || this.last_address.house === '' || this.last_address.street == null || this.last_address.house == null) {
          this.menuService.openDialog(
            'Ошибка!',
            'Укажите адрес доставки',
            true
          );
          return;
        } else {
          address = this.last_address;
        }
      }

      if (errorTrigger) {
        this.menuService.openDialog(
          'Ошибка!',
          'Укажите все параметры заказа',
          true
        );
        return;
      }
    }

    if (this.selected_presence === 'delivery') {
      if (!this.finishedCheckingDeliveryPossibility) {
        this.menuService.openDialog(
          'Ошибка!',
          'Дождитесь окончания проверки возможности доставки и повторите попытку.',
          true
        );
        return;
      }
      if (!this.canWeDeliver) return;
    }

    let utm = null;
    if (this.cookies.get('utm')){
      utm = JSON.parse(this.cookies.get('utm'));
    }

    let data: any = {
      'restaurant_id': rest_id,
      'presence': p_state,
      'people_amount': people,
      'arriving': time,
      'comment': this.comment,
      'remember_restaurant': this.remember_restaurant,
      'address': address,
      'payment_type': this.selected_payment,
      'cash_change': this.cash_change === '' ? null : this.cash_change,
      'should_not_call': this.should_not_call,
      'clientside_id': this.clientside_id,
      'utm': utm,
      'pay_loyalty' : this.payLoyalty,
      'loyalty_token': this.loyaltyToken,
      'coupon': this.promoCode,
      'promocode': this.promoCode
    };

    console.log('promo code: ', this.promoCode);

    this.cookies.delete('utm', '/', '.' + location.hostname.replace(/^.+\.([^\.]+\.[^\.]+)$/, '$1'));
    if (navigator.userAgent.match(/naparah/i)) {
      if (window['MOBILE_PUSH_TOKEN']) {
        data['push_token'] = window['MOBILE_PUSH_TOKEN'];
      }
    }
    console.log('data for order to send: ', data);
    this.mainService.sendOrder(data)
      .subscribe(
        response => {
          this.sharedData.changeData(response);
          this.menuService.setEcommerce(this.dishesListEcommerce, 'purchase', response['order_number']);

          console.log(this.selected_payment);

          if (this.selected_payment !== 'yandex') {
            this.menuService.openDialog(
              'Отлично!',
              'Ваш заказ принят',
              false
            );
            console.log('order completed, response: ', response);
            this.router.navigate(this.client.role !== 'table' ? ['/заказы.html'] : ['/']);

            if (window.navigator.userAgent.match(/naparah/i)) {
              window.postMessage(this.cookies.get('access_token'), window.location.hostname);
            }
          } else {
            window.location.href = response['payment_url'];
          }


        },
        error => {
          console.log('error while sending order: ', error);
          if (error['status'] === 433 || error['status'] === 434 || error['status'] === 435) {
            const errorText = error['error']['error']['description'];
            this.menuService.openDialog(
              'Ошибка!',
              errorText + error['error']['error']['data'],
              true
            );
          } else if (error['status'] === 436) {
            const errorText = error['error']['error']['description'];
            this.menuService.openDialog(
              'Ошибка!',
              errorText,
              true
            );
          } else if (error['error'].hasOwnProperty('error')) {
            if (error['error']['error']['code'] === 215) {
              this.menuService.openDialog(
                '',
                'Заказ на это время уже недоступен, пожалуйста, выберите другое время.',
                true
              );
          } else if (error['error']['error']['code'] === 212) {
            this.menuService.openDialog(
              '',
              'Код доступа не подтвержден. Пожалуйста, подтвердите оплату баллами.',
              true
            );
            this.inProgressSend = false;
            this.loyaltyToken = '';
          } else if (error['error']['error']['code'] === 213) {
            this.menuService.openDialog(
              '',
              'Ваш код доступа уже был использован. Пожалуйста, введите новый код.',
              true
            );
            this.inProgressSend = false;
            this.loyaltyToken = '';
          } else if (error['error']['error']['code'] === 214) {
            this.menuService.openDialog(
              '',
              'Вы подтвердили оплату другим количеством баллов. Пожалуйста, подтвердите оплату баллами снова.',
              true
            );
            this.inProgressSend = false;
            this.loyaltyToken = '';
            }
          } else {
            this.menuService.defaultErrorDialog();
          }
        }
      );
  }

  goBack(): void {
    this.menuService.goBack();
  }

  getClientsideId(): string {
    const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const ID_LENGTH = 16;
    let rtn = '';

    for (let i = 0; i < ID_LENGTH; i++) {
      rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }
    return rtn;
  }

  handlePresenceChanged() {
    if (this.selected_presence !== 'delivery')
      this.selectedTimeCheck();
    if (this.selected_presence === 'delivery' && this.company.delivery_min_price > this.price_total) {
      this.visible_warn = true;
    }

    if (this.selected_presence === 'delivery' && this.delivery_times.length !== 0) {
      if (!this.selected_time) {
        this.selected_time = this.delivery_times.length > 0 ? this.delivery_times[0].stamp : '';
      }
      else {
        const isSelectedTimeCorrectForDelivery = this.delivery_times.some((time) => {
          return time.stamp === this.selected_time;
        });
        if (!isSelectedTimeCorrectForDelivery) {
          this.selected_time = this.delivery_times.length > 0 ? this.delivery_times[0].stamp : '';
        }
      }
      if (!this.isPresenseSentEcommerce) {
        this.menuService.setEcommerce(this.dishesListEcommerce, 'checkout', null, 3);
        this.isPresenseSentEcommerce = true;
      }
    }
  }

  sendEcommerceAddressStep() {
    if (this.canWeDeliver && !this.isAddressSentEcommerce) {
      this.menuService.setEcommerce(this.dishesListEcommerce, 'checkout', null, 4);
      this.isAddressSentEcommerce = true;
    }
  }

  onOrderInfoClosure() {
    this.order_info_visible = false;
    this.isShowedSharingBasketIcon = true;
    // this.selected_presence = '';
  }

  payOnline() {
    this.sendOrder();
  }

  showLinkShare() {
    if (!this.isOpenSharedLinkDiv) {
      setTimeout(() => {
        this.isOpenSharedLinkDiv = !this.isOpenSharedLinkDiv;
      }, 0);
      if (this.isGeneratedSharedUrl) {
        this.sharedUrl = this.mainService.getSharedLink()
          .subscribe(
            response => {
              this.sharedUrl = this.company.visible_root_domain_link + '/s/' + response['pretty_link'];
            }
          );
        this.isGeneratedSharedUrl = false;
      }
    }
  }

  hideShareDivByClickOutside() {
    document.addEventListener('click', ev => {
      const showHideSharingDiv = document.querySelector('#show-hide-sharing-div');
      const containerForSharingDiv = document.querySelector('#container-for-sharing-div');
      const placeForSharedUrl = document.querySelector('#place-for-shared-url');
      const descriptionSharingDiv = document.querySelector('#description-sharing-div');
      const copyIcon = document.querySelector('#copy-icon');
      if (ev.target !== showHideSharingDiv
        && ev.target !== containerForSharingDiv
        && ev.target !== placeForSharedUrl
        && ev.target !== descriptionSharingDiv
        && ev.target !== copyIcon
        && this.isOpenSharedLinkDiv) {
        this.isOpenSharedLinkDiv = !this.isOpenSharedLinkDiv;
      }
    });
  }

  copySharedLink(placeForSharedUrl){
    placeForSharedUrl.select();
    document.execCommand('copy');
  }

  sendPaymentTypeEcommerce() {
    if (!this.isPaymentTypeSentEcommerce) {
      this.menuService.setEcommerce(this.dishesListEcommerce, 'checkout', null, 5);
      this.isPaymentTypeSentEcommerce = true;
    }
  }

  closeConfirmPayBonus(){
    this.closeModalPayPoint.nativeElement.click();
    this.inputCode = null;
  }

  canNotDeliverMinPriceWithPoint() {
    return this.company && this.price_total - this.payLoyalty < this.company.delivery_min_price && this.selected_presence === 'delivery';
  }

  showErrorModalMinPriceWithPoint() {
    this.menuService.openDialog(
        'Ошибка!',
        `Извините, минимальная стоимость заказа с учётом скидки при доставке ${this.company.delivery_min_price} ₽! \n` +
        `Итоговая стоимость вашего заказа ${this.price_total - this.payLoyalty} ₽`,
        true
      );
  }

  needConfirmPay() {
    if (this.payLoyalty != null
      && this.payLoyalty > 0
      && this.payLoyalty <= this.client.loyalty_points
      && this.payLoyalty <= this.price_total * this.percentPayloyalty
      && this.payLoyalty <= this.client.loyalty_points - this.loyalty_points_price_total) {
      return true;
    }

    return false;
  }

  sendCode() {
    if (!this.needConfirmPay()) {
      this.closeConfirmPayBonus();
      return;
    }

    if (this.canNotDeliverMinPriceWithPoint()) {
      this.showErrorModalMinPriceWithPoint();
      return;
    }

    if (this.inProgressSend) {
      console.log('Trying to invoke sendCode 2nd time!');
      return;
    }
    this.errCodeLoyalty.length = 0;
    const data = {
      'phone': this.client.phone,
      'pay_loyalty': this.payLoyalty
    };
    this.mainService.sendPhoneCodeForPayPoints(data)
    .subscribe(
        (response) => {
          this.countAttempts = 3;
          this.inProgressSend = true;
        },
        (err) => {
          this.closeConfirmPayBonus();
          this.inProgressSend = false;
          if (err['status'] === 423) {
            this.openDialog('Ошибка!',
              'Вы пытаетесь получить смс-код слишком часто! Подождите немного.',
              true
            );
          }
          else if (err['error']['error']['code'] === 13) {
            this.countAttempts = 0;
            this.menuService.timeOutView(err['error']['error']['description']);
          }
          else {
            this.openDialog('Ошибка!',
              'Что-то пошло не так, попробуйте ещё раз!',
              true
            );
          }
        }
      );
  }

  acceptCode() {
    this.errCodeLoyalty.pop();
    if (this.loyaltyToken !== '') {
       console.log('Trying to invoke sendCode 2nd time!');
       return;
    }
    if (this.inputCode === null || this.inputCode === 0 || typeof this.inputCode === "undefined") {
       this.errCodeLoyalty.push('Введите код!');
      return;
    }
    if (!this.needConfirmPay()) {
      console.log('bonus points error!');
      this.closeConfirmPayBonus();
       return;
    }
    const data = {
      'phone': this.client.phone,
      'code': this.inputCode,
      'pay_loyalty': this.payLoyalty
    };
    this.errCodeLoyalty.length = 0;
    this.mainService.verifyPhoneCodeForPayPoints(data)
      .subscribe(
        (response) => {
          this.loyaltyToken  = response['loyalty_token'];
          this.inputCode = null;
          console.log('code confirmed, response is: ', response);
          this.closeConfirmPayBonus();
          this.sendOrder();
        },
        (error) => {
          this.loyaltyToken  = '';
          if (error && error['error']) {
            if (error['error']['error']['code'] === 128) {
              this.countAttempts--;
            }
            else if (error['error']['error']['code'] === 125) {
              this.countAttempts--;
              this.inProgressSend = false;
              this.closeConfirmPayBonus();
              this.openDialog('Ошибка!',
                this.menuService.confirmCodeError(error['error']['error']['code'], this.client.phone),
                true
              );
             return;
            }
              this.errCodeLoyalty.push(this.menuService.confirmCodeError(error['error']['error']['code']));
          }
        }
      );
  }

  sendEventToGoogle(action: string) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push({
      'event': 'ga-event',
      'eventAction': action,
      'eventCategory': 'submit'
    });
  }

  getComment(comment) {
    this.comment = comment;
  }

  isValidPromoCode() {
    if (this.promoCode !== '' && this.promoCode !== null)
      this.promoCode = this.promoCode.trim();
    this.promoCode = (this.promoCode !== null && this.promoCode !== '') ? this.promoCode.toUpperCase() : '';
    return this.promoCode !== '';
  }

  selectLastAddress(address) {
    this.last_address.street = address['street'] || '';
    this.last_address.house = address['house'] || '';
    this.last_address.corp = address['corp'] || '';
    this.last_address.flat  = address['flat'] || '';
    this.sharedData.changeLastAddress(this.last_address);
    this.childMap.isAddressChanged = false;
    this.childMap.checkIfWeCanDeliverOrder();
  }

  setSubscriptions() {
    this.subscriptions.push(
      this.sharedData.currentRelatedStreets.subscribe(data => this.related = data),
      this.sharedData.currentLastAddress.subscribe(data => this.last_address = data),
      this.sharedData.currentCanWeDeliver.subscribe(data => this.canWeDeliver = data),
      this.sharedData.currentFinishedCheckingDeliveryPossibility.subscribe(data => this.finishedCheckingDeliveryPossibility = data));
  }

  checkIsChildMapShowing() {
    return typeof this.childMap !== 'undefined';
  }

  detectChangesForMap() {
    const isChildMapShowing = this.checkIsChildMapShowing();
    if (isChildMapShowing !== this.isChildMapShowing) {
      this.isChildMapShowing = isChildMapShowing;
      this.cdRef.detectChanges();
    }
  }

  ngOnInit() {
    this.isMobileApp = this.rootData.is_mobile_app;
    this.setSubscriptions();
    this.setBasketData();
    this.clientside_id = this.getClientsideId();
    this.hideShareDivByClickOutside();
  }

  ngAfterViewChecked() {
    this.detectChangesForMap();
  }

  ngOnDestroy() {
    this.menuService.setDeafaultDataMap();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

import { environment } from '../environments/environment';
import * as Sentry from "@sentry/browser";
if (environment.dsn) {
    Sentry.init({ dsn: environment.dsn, environment: environment.dsn_environment });
}
var ɵ0 = environment.metrika;
// export class RavenErrorHandler implements ErrorHandler {
//   handleError(err:any) : void {
//     Raven.captureException(err);
//   }
// }
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./back-to-menu-dialog.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/button";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./back-to-menu-dialog.component";
var styles_BackToMenuDialogComponent = [i0.styles];
var RenderType_BackToMenuDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BackToMenuDialogComponent, data: {} });
export { RenderType_BackToMenuDialogComponent as RenderType_BackToMenuDialogComponent };
export function View_BackToMenuDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(5, null, [" ", "\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "button", [["cdkFocusInitial", ""], ["id", "back-button"], ["mat-raised-button", ""], ["routerLink", "/"], ["routerLinkActive", "active"], ["tabindex", "-1"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.backClose() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 16384, [[1, 4]], 0, i4.RouterLink, [i4.Router, i4.ActivatedRoute, [8, "-1"], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(10, 1720320, null, 2, i4.RouterLinkActive, [i4.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵdid(13, 180224, null, 0, i5.MatButton, [i1.ElementRef, i6.Platform, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(14, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_5 = "/"; _ck(_v, 9, 0, currVal_5); var currVal_6 = "active"; _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.body; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 13).disabled || null); var currVal_4 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_7 = _co.data.backButton; _ck(_v, 14, 0, currVal_7); }); }
export function View_BackToMenuDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-back-to-menu-dialog", [], null, null, null, View_BackToMenuDialogComponent_0, RenderType_BackToMenuDialogComponent)), i1.ɵdid(1, 49152, null, 0, i9.BackToMenuDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var BackToMenuDialogComponentNgFactory = i1.ɵccf("app-back-to-menu-dialog", i9.BackToMenuDialogComponent, View_BackToMenuDialogComponent_Host_0, {}, {}, []);
export { BackToMenuDialogComponentNgFactory as BackToMenuDialogComponentNgFactory };

<!--.page-header-->
<div class="container">

  <div *ngIf="basket_positions && basket_positions.length == 0">
    <div class="empty-box">
      <div>Корзина пуста</div>
    </div>
  </div>
  <div id="basket" class="primary" *ngIf="basket_positions && basket_positions.length > 0">
    <div class="row sharing-icon-div" *ngIf="isShowedSharingBasketIcon">
      <div class="col-sm-8 col-sm-offset-2">
        <div id="sharing-basket-icon" title="Поделитесь корзиной" (click)="showLinkShare()"></div>
      </div>
    </div>
    <div id="sharing-basket-div" *ngIf="isOpenSharedLinkDiv">
      <div id="show-hide-sharing-div" >
        <div id="container-for-sharing-div">
          <input id="place-for-shared-url" type="text" value="{{ sharedUrl }}" #placeForSharedUrl/>
          <div title="Копировать ссылку" (click)="copySharedLink(placeForSharedUrl)" id="copy-icon"></div>
        </div>
        <span id="description-sharing-div">Чтобы поделиться своей корзиной, скопируйте ссылку и отправьте другу!</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 col-md-offset-2">

        <div class="cart" id="order_table" *ngIf="!order_info_visible">

          <app-products-carts (bpOperationOutput)="bpOperation($event[0], $event[1])" (deleteOutput)="delete($event)"></app-products-carts>
          <!--.card-list-->

          <div class="cart-separator"></div>

          <div class="cart-footer">

            <div class="total-price" *ngIf="price_total > 0">
              ИТОГО
              <span class="bold">{{ price_total }}&nbsp;&#8381;</span>
            </div>

            <div class="total-price total-points" *ngIf="loyalty_points_price_total > 0">
              Будет списано баллов
              <span><span class="bold">{{ loyalty_points_price_total }}</span> (У вас баллов: {{ client.loyalty_points }})</span>
            </div>
            <div *ngIf="company.delivery_min_price > price_total">
              <div>Минимальная сумма при доставкe {{ company.delivery_min_price }} ₽</div>
            </div>
            <hr>
            <div class="total-price total-calories" *ngIf="calories_total > 0">
              <span>{{ calories_total }} ккал</span>
            </div>
            <button type="button" (click)="showOrderInfo()" class="btn btn-info btn-w-m">ЗАКАЗАТЬ</button>
          </div>
        </div>

        <a id="order_pos"></a>
        <div style="text-align: center" class="order" *ngIf="selected_presence === 'delivery' && company.delivery_min_price > price_total && visible_warn">
          Извините, минимальная стоимость заказа при доставке {{ company.delivery_min_price }}
          ₽, хорошо бы ещё что-то заказать.
          <hr>
          <button class="btn btn-info btn-w-m" type="button" routerLink="/">Перейти в меню</button>
        </div>
        <div class="order" *ngIf="order_info_visible && !visible_warn">
          <a (click)="onOrderInfoClosure()" class="closed-order"><i class="icon-remove"></i></a>

          <div class="order-title">
            ОФОРМЛЕНИЕ ЗАКАЗА
          </div>
          <div *ngIf="client && client.role!=='table' && company && company.presences.length == 1">
            <div class="form-group">
              {{ company.presences[0].name }}
            </div>
          </div>
          <div *ngIf="client && client.role!=='table' && company && company.presences.length > 1">
            <div class="form-group order-form-group">
              <mat-form-field class="order-form-group" floatLabel="never">
                <mat-select [(ngModel)]="selected_presence"
                            (selectionChange)="handlePresenceChanged()"
                            [placeholder]="'вариант заказа'" [required]="true" class="md-no-underline">
                  <mat-option *ngFor="let ps of company.presences" [value]="ps.key">
                    {{ ps.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>


          <div
            *ngIf="client && client.role!=='table' && selected_presence !== 'delivery' && (selected_presence || company.presences.length == 1)">
            <div class="form-group">
              <mat-radio-group [(ngModel)]="selected_restaurant" (ngModelChange)="selectedTimeCheck()"  name="restaurantOption" placeholder="ресторан" required
                               class="md-no-underline">
                <mat-radio-button *ngFor="let r of restaurants" [value]="r.id" class="green-style"
                                  style="margin-bottom: 16px;">{{ r.name }} - {{ r.address }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="form-group" *ngIf="selected_restaurant && times[selected_restaurant].length > 0">
              <label for="rememberRestaurant">
                <section>
                  <mat-checkbox [(ngModel)]="remember_restaurant" id="rememberRestaurant" name="rememberRestaurant"
                                class="green-style">запомнить ресторан как основной
                  </mat-checkbox>
                </section>
              </label>
            </div>
          </div>

          <div *ngIf="client && client.role!=='table'">
            <div class="form-group" *ngIf="delivery_times.length !== 0 && selected_presence === 'delivery'">
              <mat-form-field class="order-form-group" floatLabel="never">
                <mat-select [(ngModel)]="selected_time" placeholder="время" required class="md-no-underline">
                  <mat-option *ngFor="let t of delivery_times" [value]="t.stamp">{{ t.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group" *ngIf="selected_presence !== 'delivery' && selected_restaurant && times[selected_restaurant].length > 0">
              <mat-form-field class="order-form-group" floatLabel="never">
                <mat-select [(ngModel)]="selected_time" placeholder="время" required class="md-no-underline">
                  <mat-option *ngFor="let t of times[selected_restaurant]" [value]="t.stamp">{{ t.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group" *ngIf="delivery_times.length === 0">
              <h4 *ngIf="selected_presence && selected_presence === 'delivery'">Извините, доставка будет доступна с {{possible_delivery_times}}</h4>
            </div>
            <div *ngIf="client && client.role!=='table' && ((selected_restaurant && selected_presence !== 'delivery' && times[selected_restaurant].length > 0) || (selected_presence === 'delivery' && delivery_times.length !== 0))">
              <div class="form-group">
                <mat-form-field class="order-form-group" floatLabel="never">
                  <mat-select [(ngModel)]="selected_people" placeholder="количество персон" [required]="true"
                              class="md-no-underline">
                    <mat-option *ngFor="let p of people" [value]="p">
                      <span *ngIf="p === '0'" >Приборы не нужны</span><span *ngIf="p !== '0'" >{{ p }}</span>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="form-group" *ngIf="selected_restaurant && times[selected_restaurant].length == 0">
              <h4 *ngIf="selected_presence && selected_presence !== 'delivery'">Извините, забрать заказ можно будет с {{possible_times[selected_restaurant]}}</h4>
            </div>
          </div>

          <div id="deliveryAddressContainer"
               [ngClass]="{'form-group': true, 'has-error': (!canWeDeliver && last_address) }"
               *ngIf="isChildMapShowing && selected_presence === 'delivery' && client && client.role!=='table' && delivery_times.length!==0">
            <div class="order-title">
              Адрес доставки
               <div class="history-icon-address history-icon" data-toggle="modal" data-target="#modalInfoAddress" title="последние адреса">
              </div>
            </div>

            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 delivery-location">
                <label>Улица:</label>
                <input #deliveryStreet
                       type="text"
                       required
                       placeholder="Улица *"
                       class="form-control"
                       [(ngModel)]="last_address.street"
                       (focus)="childMap.searchStreet(deliveryStreetInput.nativeElement);"
                       (blur)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of related" (onSelectionChange)="childMap.onSelect(option)" [value]="option">
                    <span *ngIf="option">{{option}}</span>
                  </mat-option>
                </mat-autocomplete>
                  <div type="button" (click)="childMap.getClientGeo()" class="delivery-location-icon history-icon"></div>
              </div>
            </div>
            <div class="row m-t-md justify-content-center">
              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <label>Дом:</label>
                <input id="deliveryHouse"
                       type="text"
                       required
                       placeholder="Номер дома *"
                       class="form-control"
                       maxlength="6"
                       [(ngModel)]="last_address.house"
                       (blur)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       (keyup.enter)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       (focus)="childMap.onFocusInputAddress()">
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <label>Корпус:</label>
                <input id="deliveryCorp"
                       type="text"
                       placeholder="Корпус"
                       class="form-control"
                       maxlength="4"
                       [(ngModel)]="last_address.corp"
                       (blur)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       (keyup.enter)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       (focus)="childMap.onFocusInputAddress()">
              </div>
              <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <label>Квартира:</label>
                <input id="deliveryFlat"
                       type="text"
                       placeholder="Номер квартиры"
                       class="form-control"
                       maxlength="4"
                       [(ngModel)]="last_address.flat"
                       (blur)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       (keyup.enter)="childMap.checkIfWeCanDeliverOrder(); sendEcommerceAddressStep();"
                       (focus)="childMap.onFocusInputAddress()">
              </div>
            </div>
            <span class="help-block" *ngIf="isChildMapShowing && childMap && childMap.deliveryImpossibilityReason && !canWeDeliver && last_address">{{ childMap.deliveryImpossibilityReason }}</span>
          </div>
          <div id="deliveryMap"
               *ngIf="restaurants_polygons.length !== 0 || restaurants.length !== 0"
               [style.display]="(selected_presence === 'delivery' && client && client.role !== 'table' && delivery_times.length !== 0) ? 'block' : 'none'">
            <app-map [restaurants]="restaurants" [restaurants_polygons]="restaurants_polygons" #childMapId></app-map>
          </div>
          <div *ngIf="client && ((client.role!=='table' && selected_presence === 'delivery' && delivery_times.length !== 0) || (client.role==='eap' || client.role==='manager'))">
            <div class="form-group order-form-group">
              <mat-form-field class="order-form-group" floatLabel="never">
                <mat-select [(ngModel)]="selected_payment"
                            (selectionChange)="sendPaymentTypeEcommerce()"
                            [placeholder]="'тип оплаты'" [required]="true" class="md-no-underline">
                  <mat-option *ngFor="let type of payment_types" [value]="type[0]">
                    {{ type[1] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="form-group" *ngIf="selected_payment === 'cash'">
              <label>Нужна сдача с:</label>
              <mat-form-field class="md-block order-form-group change" floatLabel="never">
                <input matInput appNumbersOnly class="form-control" placeholder="размер купюры" [(ngModel)]="cash_change"/>
              </mat-form-field>
            </div>

          </div>

          <div class="form-group" *ngIf="(selected_presence === 'delivery' && delivery_times.length !== 0) || (selected_presence !== 'delivery' && selected_restaurant && times[selected_restaurant].length > 0)">
            <label>Комментарий:</label>
            <div data-toggle="modal" data-target="#modalInfoComments" class="history-icon history-icon-comments" title="последние комментарии"></div>
            <mat-form-field class="md-block order-form-group" floatLabel="never">
              <textarea matInput matTextareaAutosize [(ngModel)]="comment" id="#comment" name="comment" maxlength="250"></textarea>
              <mat-hint align="end">{{ comment?.length || 0 }}/250</mat-hint>
            </mat-form-field>
          </div>
          <div class="form-group" *ngIf="!canOrder() && (selected_presence === 'delivery' && delivery_times.length !== 0)">
            <label>Введите промокод</label>
              <div class="form-group">
                <div class="promo-div">
                  <input [disabled]="isConfirmPromoCode" id="promo_code" name="promo_code" class="form-control" type="text" placeholder="Промокод" [(ngModel)]="promoCode">
                  <button [disabled]="promoCode === '' || isConfirmPromoCode" id="btn_send_code" class="btn btn-info" (click)="checkCoupon()">Применить</button>
                </div>
              </div>
          </div>
          <div class="form-group" *ngIf="(client.role!=='table' && client.successful_orders > 3 && (!(selected_presence === 'delivery' && delivery_times.length === 0) || (selected_presence !== 'delivery' && selected_restaurant && times[selected_restaurant].length > 0 && selected_time != ''))) && !canOrder()">
            <mat-checkbox name="should_not_call" [(ngModel)]="should_not_call">
              Отказаться от звонка
            </mat-checkbox>
          </div>
          <div class="form-group" *ngIf="!canOrder() && !isPromoCodeWithDiscount && ((selected_presence === 'delivery' && delivery_times.length !== 0) || (selected_presence !== 'delivery' && selected_restaurant && times[selected_restaurant].length > 0))">
            <div class="order-title">
              оплата бонусными баллами
            </div>
            <mat-form-field class="md-block order-form-group change" floatLabel="never">
              <input type="number" placeholder="Количество бонусных баллов для оплаты:" [maxLength]="6" [(ngModel)]="payLoyalty" matInput appNumbersOnly class="form-control"/>
              <span class="help-block error_loyalty danger ng-star-inserted" *ngIf="payLoyalty > client.loyalty_points">Указанная сумма баллов больше, чем у вас имеется</span>
              <span class="help-block error_loyalty ng-star-inserted" *ngIf="payLoyalty > (price_total * percentPayloyalty - 0.5 | number:'1.0-0')">Указанная сумма баллов больше, чем {{ percentPayloyalty * 100 | number:'' }}% от суммы заказа</span>
              <span class="help-block error_loyalty danger ng-star-inserted" *ngIf="(client.loyalty_points - loyalty_points_price_total < payLoyalty) && loyalty_points_price_total>0">Вы не можете потратить введенную сумму баллов, так как у вас к оплате {{ loyalty_points_price_total }}&nbsp;баллов</span>
            </mat-form-field>
            <span>Максимальное количество бонусных баллов к списанию: <strong class="t-green">{{ price_total * percentPayloyalty - 0.5 | number:'1.0-0' | replace:',':'' }}</strong></span> <br>
            <span>У вас бонусных баллов: <strong class="t-green">{{ client.loyalty_points }}</strong></span>
          </div>
          <div class="text-center" *ngIf="delivery_times.length > 0 && selected_presence === 'delivery' || selected_restaurant && (selected_presence === 'takeaway' || selected_presence === 'stay') && times[selected_restaurant].length > 0 ">
            <div class="order-total-price">
              СУММА ЗАКАЗА
              <div class="t-green" *ngIf="price_total">{{ price_total - payLoyalty }}&nbsp;&#8381;</div>
              <div class="t-green" *ngIf="loyalty_points_price_total">{{ loyalty_points_price_total }}&nbsp;баллов</div>
            </div>
            <button type="button" *ngIf="isPointsPaymentConfirmationEnabled && needConfirmPay() && loyaltyToken === ''" data-toggle="modal" data-target="#modalInfoPayPoint" (click)="sendCode();" id="confirmPoints" class="btn btn-info btn-w-m">Отправить заказ</button>
            <button type="button" *ngIf="selected_payment !== 'yandex' && ((!needConfirmPay() || !isPointsPaymentConfirmationEnabled) || loyaltyToken !== '')" id="sendOrder" (click)="sendOrder()" class="btn btn-info btn-w-m">Отправить заказ</button>
            <button type="button" *ngIf="selected_payment === 'yandex' && ((!needConfirmPay() || !isPointsPaymentConfirmationEnabled) || loyaltyToken !== '')" id="payOnline" (click)="payOnline()" class="btn btn-info btn-w-m">Оплатить</button>
          </div>
        </div>


      </div>
    </div>
    <a (click)="goBack()" class="back-page"> назад</a>

  </div>
  <!--.primary-->

</div>

<!-- modal last address -->
<div class="modal fade" id="modalInfoAddress" tabindex="-1" role="dialog" aria-labelledby="modalInfoCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-uppercase" id="modalAddressLongTitle" >список последних комментариев</h5>
        <a class="closed-order" data-dismiss="modal" aria-label="Close"><i class="icon-remove" data-dismiss="modal" aria-label="Close"></i></a>
      </div>
      <div class="modal-body last-address" *ngIf="last_five_address.length !== 0">
        <span data-toggle="modal" data-target="#modalInfoAddress" (click)="selectLastAddress(address)" class="modal-body-text" *ngFor="let address of last_five_address;">{{ address.street + ', д. ' + address.house + ' к' + address.corp + ', кв. ' + address.flat}}</span>
      </div>
      <div class="modal-body last-address" *ngIf="last_five_address.length === 0">
        <span data-toggle="modal" data-target="#modalInfoAddress">У вас нет сохраненных адресов доставки</span>
      </div>
    </div>
  </div>
</div>

<!-- modal last comments -->
<div class="modal fade" id="modalInfoComments" tabindex="-1" role="dialog" aria-labelledby="modalInfoCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-uppercase" id="modalCommentsLongTitle">список последних комментариев</h5>
        <a class="closed-order" data-dismiss="modal" aria-label="Close"><i class="icon-remove" data-dismiss="modal" aria-label="Close"></i></a>
      </div>
      <div class="modal-body" *ngIf="last_comments.length!==0">
        <span data-toggle="modal" data-target="#modalInfoComments" (click)="getComment(comment)" class="commentsLast" *ngFor="let comment of last_comments;">{{ comment }}</span>
      </div>
      <div class="modal-body" *ngIf="last_comments.length===0">
        <span data-toggle="modal" data-target="#modalInfoComments">Вы ещё не оставили ни одного комментария</span>
      </div>
    </div>
  </div>
</div>

<!-- modal pay point -->
<div class="modal fade" id="modalInfoPayPoint" tabindex="-1" role="dialog" aria-labelledby="modalInfoCenterTitle" aria-hidden="true" *ngIf="!canNotDeliverMinPriceWithPoint()">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title text-uppercase" id="modalPayPointsConfirmLongTitle">подтверждение списания бонусных баллов</h5>
        <a #closeModalPayPoint class="closed-order" data-dismiss="modal" aria-label="Close"><i class="icon-remove" data-dismiss="modal" aria-label="Close"></i></a>
      </div>
      <div class="modal-body" data-target="#modalPayPoints">
        <div id="formCodeLoyalty">
          <div class="form-group" id="codeLoyaltyform" *ngIf="countAttempts > 0">
            <label>Код подтверждения:</label>
            <input type="text" class="form-control" maxlength="4" [(ngModel)]="inputCode">
            <span class="help-block error_loyalty danger ng-star-inserted" *ngIf="errCodeLoyalty.length > 0">{{ errCodeLoyalty[0] }}</span>
          </div>
          <button *ngIf="countAttempts>0" (click)="acceptCode()" class="btn btn-info btn-modal">ПОДТВЕРДИТЬ</button>
          <div class="count-attempts"><span *ngIf="countAttempts>0">у вас </span><span *ngIf="countAttempts>1">осталось </span> <span *ngIf="countAttempts == 1">осталась </span> <span *ngIf="countAttempts>0">{{ countAttempts }} </span><span *ngIf="countAttempts > 1">попытки</span><span *ngIf="countAttempts == 1">попытка</span></div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from "../main.service";
import { SharedDataService } from "../shared-data.service";
import {CookieService} from "ngx-cookie-service";

import {MainMenuService} from "../main-menu.service";

@Component({
  selector: 'app-shared-products-list',
  templateUrl: './shared-products-list.component.html',
  styleUrls: ['./shared-products-list.component.css']
})

export class SharedProductsListComponent implements OnInit {

  basket_positions: any;
  pretty_link: any;
  justAddedToBasket = false;


  constructor(
    private sharedData: SharedDataService,
    private route: ActivatedRoute,
    private mainService: MainService,
    private router: Router,
    private cookieService: CookieService,
    private mainMenuService: MainMenuService
  ) {}

  ngOnInit() {
    this.pretty_link = this.route.snapshot.paramMap.get('pretty_link');
    this.getSharedBasketProducts();
    this.mainService.getBasketData();
  }

  getSharedBasketProducts() {
    this.mainService.getSharedBasket(this.pretty_link).subscribe(
      response => {
          this.basket_positions = response['basket_positions'];
          response['title'] = 'Блюда друга';
          this.sharedData.changeData(response);
      }, error => {
          this.mainMenuService.defaultErrorDialog();
      });
  }

  getAdds(basketPosition) {
    const adds = [];
    for (let i = 0; basketPosition.parts && i < basketPosition.parts.length; i++) {
      const part_group = basketPosition.parts[i];
      adds.push({
        dish_id: part_group.position.id,
        group_id: part_group.group_id
      });
    }
    return adds;
  }

  getToppings(basketPosition) {
    const toppings = {};
    for (let i = 0; basketPosition.toppings && i < basketPosition.toppings.length; i++) {
      const topping = basketPosition.toppings[i];
      if (topping.quantity > 0) {
        toppings[topping.position.id] = topping.quantity;
      }
    }
    return toppings;
  }

  addAllUnitsToBasket() {
    let arrOfIdsOfProducts = [];
    this.basket_positions.forEach((basketPosition) => {
      let toSend = {
        position_id: basketPosition.position.id,
        quantity: basketPosition.quantity
      };

      if (basketPosition.position.pos_type === 'constructor') {
        toSend['adds'] = this.getAdds(basketPosition);
        toSend['toppings'] = this.getToppings(basketPosition);
      }
      arrOfIdsOfProducts.push(toSend);
    });

    let data = {
      'type': 'add_many',
      'positions': arrOfIdsOfProducts
    };

    if (this.mainMenuService.checkLogin({show_info: true})) {
      this.mainService.changeBasketPosition(data)
        .subscribe(response => {
          response['title'] = 'Корзина';
          this.sharedData.changeData(data);
          this.router.navigate(['/корзина']);
        });
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { SharedDataService } from "../shared-data.service";
import { MainService } from "../main.service";
import { MainMenuService } from "../main-menu.service";
import { Location } from  "@angular/common";
@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
})
export class PolicyComponent implements OnInit {
  sharedData: any;

  constructor(
    private menuService: MainMenuService,
    private mainService: MainService,
    private sharedService: SharedDataService,
  ) {
  }

  setData() {
    this.mainService.getCompany()
      .subscribe(response => {
        this.sharedData = response;
        this.sharedData.title = 'Пользовательское соглашение';
        this.sharedService.changeData(this.sharedData);
      }, error => {
        this.menuService.defaultErrorDialog();
      }
    );
  }

  goBack() {
    this.menuService.goBack();
  }

  ngOnInit() {
    this.setData();
  }
}

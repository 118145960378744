<nav class="menu">
  <ul>
    <li>
      <a routerLink="/">
        <i class="icon-menu"></i><span>Меню</span>
      </a>
    </li>
    <li style="display: none;">
      <a routerLink="/">
        <i class="icon-cutlery"></i><span>Рестораны</span>
      </a>
    </li>
    <li id="profileLink" class="dropdown" *ngIf="isLogged">
      <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true"
         aria-expanded="false"><i class="icon-user"></i><span *ngIf="client">{{ client.first_name }}<span
        class="caret"></span></span></a>

      <ul class="dropdown-menu">
        <li>
          <a routerLink="/корзина" id="basketLink">
            <i class="icon-busket"></i><span>Корзина</span>
          </a>
        </li>
        <li id="ordersLink">
          <a routerLink="/заказы.html">
            <i class="icon-order"></i><span>Заказы</span>
          </a>
        </li>
        <li>
          <a routerLink="/профиль"><i class="icon-icon"></i><span>Профиль</span></a>
        </li>
        <li id="logoutLink" *ngIf="isLogged" (click)="logOut()">
          <a aria-expanded="true" aria-haspopup="true"  data-toggle="dropdown" href="#" role="button">
            <i class="icon-logout"></i><span>Выход</span>
          </a>
        </li>
      </ul>
    </li>


    <li id="loginLink" *ngIf="!isLogged">
      <a routerLink="/вход">
        <i class="icon-logout"></i><span>ВХОД</span>
      </a>
    </li>

  </ul>
  <form class="form-search" (ngSubmit)="makeSearch()" *ngIf="searchIsVisible">
    <mat-form-field floatLabel="never">
      <input #searchDish matInput type="search" [(ngModel)]="searchTerm" name="search" placeholder="ПОИСК"
             [matAutocomplete]="auto" class="search-label">
      <i class="search-clear" mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear"
              (click)="searchTerm=''">
        <mat-icon style="height: 0;">close</mat-icon>
      </i>
      <mat-autocomplete #auto="matAutocomplete" minlength="2">
        <mat-option *ngFor="let option of suggestions" (click)="onSelect(option.dish)" (onSelectionChange)="onSelect(option.dish)" [value]="option.display">
          <span>{{ option.display }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  </form>
</nav>



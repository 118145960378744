import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-back-to-menu-dialog',
  templateUrl: './back-to-menu-dialog.component.html',
  styleUrls: ['./back-to-menu-dialog.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BackToMenuDialogComponent {

  constructor(
    private dialogRef: MatDialogRef<BackToMenuDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  backClose(): void {
    this.dialogRef.close(true);
  }

}

<div id="telegram_bot_info" class="container" style="text-align: center; padding-top: 25px;" *ngIf="!is_telegram_connected">
    <span *ngIf="!!company">Напишите нашему боту <a href="https://telegram.me/{{ company.telegram_bot_username }}">@{{ company.telegram_bot_username }}</a> чтобы получать обновления статусов заказов</span>
</div>


<div class="primary" style="padding-top: 50px !important;">
    <div class="container">

        <div id="empty_basket" class="panel panel-bordered" *ngIf="orders.length == 0">
            <div class="panel-body">
                У Вас еще нет заказов!
            </div>
        </div>

        <div id="not_empty_basket" *ngFor="let order of orders">
            <div class="row">
                <div class="col-lg-10  col-lg-offset-1 col-md-12 col-md-offset-0">
                    <div class="check">
                        <div class="check-heading">
                            <h3>
                                Заказ №
                                <small class="order-numb">{{ order.number }}</small>
                            </h3>
                            <h4>
                                Создан: {{ order.created | date:'dd-MM-yyyy HH:mm' }} <!-- date:'dd.MM.yyyy HH:mm' -->
                            </h4>
                            <div class="check-actions">
                                <a class="panel-action icon md-minus"
                                   (click)="showOrder(order.id)"></a>
                            </div>
                        </div>

                        <div class="check-body" id="body_{{ order.id }}">

                            <table class="table table-border-n">
                                <tbody>
                                <tr>
                                    <th>Ресторан</th>
                                    <td>
                                        <a routerLink="/рестораны/{{ order.restaurant.id }}"> <!-- restaurantId: -->
                                            {{ order.restaurant.name }}
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table class="table table-border-n">
                                <tbody>
                                <tr>
                                    <th>Статус</th>
                                    <td>
                                        {{ order.readable_state }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <table class="table">
                                <thead>
                                <tr>
                                    <th colspan="2">
                                        Блюда
                                    </th>
                                </tr>
                                </thead>
                                <tbody *ngFor="let pos of order.positions">
                                <tr class="active">
                                    <td>Название</td>
                                    <td>
                                        <a routerLink="/{{ pos.position.menu.id }}-{{ pos.position.menu.slug }}/{{ pos.position.id }}-{{ pos.position.slug }}.html" class="product-box"
                                           *ngIf="!pos.position.category && (pos.price_single_item > 0 || pos.price_single_item > 0)">
                                            {{ pos.extra.position.name }}
                                        </a>
                                        <a routerLink="/{{ pos.position.menu.id }}-{{ pos.position.menu.slug }}/{{ pos.position.category.id }}-{{ pos.position.category.slug }}/{{ pos.position.id }}-{{ pos.position.slug }}.html"
                                           class="product-box" *ngIf="!!pos.position.category && (pos.price_single_item > 0 || pos.price_single_item > 0)">
                                            {{ pos.extra.position.name }}
                                        </a>
                                      <span class="product-box" *ngIf="!!pos.position.category  && pos.price_single_item == 0 && pos.price_single_item === 0">
                                            {{ pos.extra.position.name }}
                                        </span>
                                    </td>
                                </tr>
                                <tr class="active" *ngIf="pos.price_single_item > 0">
                                    <td>Цена</td>
                                    <td>{{ pos.price_single_item }} &#8381;</td>
                                </tr>
                                <tr class="active" *ngIf="pos.loyalty_points_price_total > 0">
                                    <td>Цена в баллах</td>
                                    <td>{{ pos.loyalty_points_price_total }} баллов</td>
                                </tr>
                                <tr class="active" *ngIf="pos.extra.parts && pos.extra.parts.length > 0">
                                    <td>Варианты</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let part of pos.extra.parts">
                                    <td></td>
                                    <td>{{ part.position.name }}
                                        <div *ngIf="part.price_single_item > 0"> {{ part.price_single_item }} &#8381;</div>
                                    </td>
                                </tr>

                                <tr class="active" *ngIf="pos.toppings && pos.toppings.length > 0">
                                    <td>Топпинги</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let topping of pos.toppings">
                                    <td></td>
                                    <td>{{ topping.position.name }}
                                        <div *ngIf="topping.price_single_item > 0"><span *ngIf="topping.quantity > 1">{{ topping.quantity }} шт. * </span>{{ topping.price_single_item }} &#8381;</div>
                                    </td>
                                </tr>


                                <tr class="active">
                                    <td>Кол-во</td>
                                    <td>{{ pos.quantity }} шт.</td>
                                </tr>
                                <tr class="active" *ngIf="pos.price_single_item !== pos.price_total">
                                    <td>Итого</td>
                                    <td>
                                        <div *ngIf="pos.price_total"> {{ pos.price_total }} &#8381;</div>
                                        <div *ngIf="pos.loyalty_points_price_total"> Баллов: {{ pos.loyalty_points_price_total }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </table>

                            <table class="table order-info">
                                <tbody>
                                <tr>
                                    <th>Что делать</th>
                                    <td>{{ order.readable_presence_state }}</td>
                                </tr>
                                <tr>
                                  <th>Адрес</th>
                                  <td *ngIf="order.readable_presence_state === 'Доставка'">{{ order.address }}</td>
                                  <td *ngIf="order.readable_presence_state !== 'Доставка'">{{ order.restaurant.name }} - {{ order.restaurant.address }}</td>
                                </tr>
                                <tr>
                                    <th>Количество персон</th>
                                    <td>{{ order.people_amount }}</td>
                                </tr>
                                <tr>
                                    <th>Время доставки</th>
                                    <td>
                                        <div *ngIf="!order.is_asap">
                                        {{ order.arriving | date:'dd.MM.yyyy HH:mm' }}
                                        </div>
                                        <div *ngIf="order.is_asap">
                                        как можно быстрее
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Комментарий</th>
                                    <td class="order-info-comment">{{ order.comment }}</td>
                                </tr>
                                <tr *ngIf="order.is_survey_available">
                                  <th></th>
                                  <td>Будем рады получить от вас <a routerLink="/отзыв/{{ order.number }}">обратную связь</a></td>
                                </tr>
                                <tr *ngIf="!order.is_finished && (client.role === 'manager' || client.role === 'eap')">
                                    <th>Статус оплаты</th>
                                    <td>
                                        <div *ngIf="order.payment_type === 'yandex'">
                                            <div *ngIf="order.paid">
                                                оплачен
                                            </div>
                                            <div *ngIf="!order.paid && order.payment_status !== 'canceled'">
                                                не оплачен
                                                <br>
                                                <a href="{{ order.payment_url }}">оплатить заказ онлайн</a>
                                            </div>
                                            <div *ngIf="!order.paid && order.payment_status === 'canceled'">
                                                платеж был отменен
                                                <br>
                                                <a class="pay_link" (click)="createPayment(order.id)">попробовать оплатить снова</a>
                                            </div>
                                        </div>
                                        <div *ngIf="order.payment_type !== 'yandex'">
                                            тип оплаты:
                                            <span *ngIf="order.payment_type === 'cash'">наличными </span>
                                            <span *ngIf="order.payment_type === 'cashless'">картой </span>
                                            при получении
                                            <br><br>
                                            можно <a class="pay_link" (click)="createPayment(order.id)">оплатить онлайн</a>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>

                        <div class="check-footer">
                            <div class="text-center m-b-sm">
                                <div *ngIf="!!order.loyalty_points_price_total">
                                  <span *ngIf="order.promo_code_discount === 0">
                                    Списано баллов:
                                  </span>
                                  <span *ngIf="order.promo_code_discount > 0">
                                    Скидка:
                                  </span>
                                  <strong> {{ order.loyalty_points_price_total }}</strong>
                                </div>
                                <div *ngIf="!!order.price_total">Итого: <strong>{{ order.price_total }} &#8381;</strong></div>
                            </div>
                            <button id="addAgainButton{{ order.id }}" type="button" class="btn
                            btn-info btn-block"
                                    (click)="addAgain(order.id)">
                                Добавить блюда в корзину
                            </button>
                            <div id="addAgain{{ order.id }}" class="spinner" style="display: none;">
                                <div class="bounce1"></div>
                                <div class="bounce2"></div>
                                <div class="bounce3"></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!--<div (scroll)="onScroll()">-->

            <div class="spinnerAddOrder spinner" *ngIf="isLoading">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>

        <!--</div>-->
    </div>
</div>


<div class="container">
  <div class="primary">
    <div class="order">
      <p>
        <strong>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</strong>
      </p>
      <p align="center">
        <strong>1. </strong>
        <strong>Термины и понятия, используемые в настоящем Соглашении:</strong>
      </p>
      <p>
        <strong>Соглашение </strong>
        – настоящее Пользовательское соглашение, регулирующее отношения Оператора и
        Пользователя на протяжении всего периода предоставления доступа
        Пользователю к персонализированным сервисам Сайта.
      </p>
      <p>
        <strong>Оператор </strong>
        – лицо, осуществляющее свою деятельность в соответствии с законодательством
        Российской Федерации, в том числе, посредством Интернет-ресурса – Сайта,
        указанного в настоящем Соглашении. Пользователь предупрежден, что Оператор
        может не являться лицом, осуществляющим непосредственную продажу Товаров,
        данные о Продавце указаны в сопроводительных документах на Товар.
      </p>
      <p>
        <strong>Сайт </strong>
        – расположенный(-ые) в сети Интернет ресурс(-ы) по адресу(-ам):
      </p>
      <p>
        <a href="http://www.naparah.ru">naparah.ru</a>
      </p>
      <p>
        <a href="https://напарах.рф">напарах.рф</a>
      </p>
      <p>
        <a href="https://заказ.напарах.рф">заказ.напарах.рф</a>
      </p>
      <p>
        <strong>Служба поддержки Пользователей </strong>
        – специалисты Оператора осуществляющие консультации Пользователей, приём
        сообщений, уведомлений, претензий и иных обращений от Пользователей по
        телефону и/или E-mail адресу.
      </p>
      <p>
        <strong>Телефон Службы поддержки Пользователей </strong>
        – 8 (812) 640-05-06 (г. Санкт-Петербург).
      </p>
      <p>
        <strong>E</strong>
        <strong>-mail адрес Службы поддержки Пользователей: </strong>
        <strong>Info@naparah.ru</strong>
      </p>
      <p>
        <strong>Товар</strong>
        – продукция, информация о которой размещена в Интернет-магазине Сайта и
        дистанционная продажа которой не запрещена действующим законодательством
        РФ, в том числе готовой еды, продуктов питания и т.п.
      </p>
      <p>
        <strong>Сервисы</strong>
        – услуги, доступные Пользователям на Сайте.
      </p>
      <p>
        <strong>Приложение(-я)</strong>
        – программное обеспечение Оператора, посредством которого Пользователь
        осуществляет использование Сервисов. Под Приложением подразумеваются
        мобильное Приложение, устанавливаемое на мобильных устройствах связи
        Пользователя (телефоне, планшете и т.п.), сетевое Приложение,
        устанавливаемое на Сайте, а также Приложение, устанавливаемое в социальных
        сетях.
      </p>
      <p>
        <strong>Интернет-магазин</strong>
        – часть информационного массива, расположенного на Сайте, служащего для
        осуществления дистанционной продажи Товаров и является одним из Сервисов
        Сайта.
      </p>
      <p>
        <strong>Заказ</strong>
        – последовательность действий для выбора и резервирования Товара на Сайте.
      </p>
      <p>
        <strong>Партнер Оператора</strong>
        – лицо, осуществляющее совместное с Оператором исполнение Сервисов, в том
        числе изготовление Товара, доставку Товара, передачу Товара Пользователю,
      </p>
      <p>
        <strong>Продавец – </strong>
        лицо, являющееся Партнёром Оператора и осуществляющее свою деятельность в
        соответствии с законодательством Российской Федерации, в том числе
        реализующее Товар посредством Сервиса на Сайте.
      </p>
      <p>
        <strong>Пользователь</strong>
        – физическое лицо, добровольно прошедшее регистрацию на Сайте и
        авторизованное для пользования всеми доступными ему Сервисами Сайта.
        Оператор не берет на себя обязательства о работоспособности тех или иных
        Сервисов Сайта в любое время, а лишь предоставляет возможность их
        применения в момент их функционирования.
      </p>
      <p>
        <strong>Покупатель </strong>
        – Пользователь, осуществивший Заказ на Сайте посредством использования
        Сервиса, получивший и оплативший Товар, переданный Продавцом по данному
        Заказу.
      </p>
      <p>
        <strong>Персональная информация, персональные данные</strong>
        – информация, предоставляемая Пользователем о себе самостоятельно при
        регистрации (создании учётной записи/Личного кабинета) или в процессе
        использования Сервисов, включая, но не ограничиваясь, персональные данные
        (ПДн) Пользователя, как это понимается в соответствии с Федеральным законом
        РФ № 152-ФЗ «О персональных данных».
      </p>
      <p>
        <strong>Регистрация</strong>
        – действия по созданию Пользователем учетной записи с использованием
        электронной почты Пользователя, а именно на указанный Пользователем адрес
        электронной почты приходит письмо, регистрация завершается переходом по
        гиперссылке или получением письма с логином и паролем. Данными действиями
        (включая дальнейшее использование логина и пароля на Сайте) Пользователь
        дополнительно подтверждает, что условия настоящего Соглашения Пользователю
        полностью ясны и он выражает полное свое согласие с Соглашением.
      </p>
      <p>
        <strong>Учетная запись</strong>
        – персональный раздел Пользователя на Сайте, доступный только после
        прохождения аутентификации (ввода правильных логина и пароля), предназначен
        для хранения сведений, сообщенных о себе Пользователем, и управления
        Заказами.
      </p>
      <p>
        <strong>Личный Кабинет</strong>
        – персонализированный интерфейс Сайта с ограниченным доступом, обладающий
        набором программных инструментов для персонализированного использования
        Пользователем Сервисов Сайта, в том числе для осуществления Заказов и
        покупок Товаров в Интернет-магазине Сайта.
      </p>
      <p>
        <strong>Логин и Пароль</strong>
        - уникальные реквизиты авторизации Пользователя на Сайте, необходимые для
        пользования Сервисами Сайта, а также для осуществления покупок Товаров
        Интернет-магазина;
      </p>
      <p>
        <strong>Информационная рассылка </strong>
        – это информация, которая высылается в SMS-сообщении на номер телефона и
        электронную почту Пользователя, дающая возможность: получить номер и статус
        Заказа. Без информационной рассылки оформление Заказа невозможно, так как
        будет отсутствовать возможность информирования Пользователя/Покупателя о
        факте совершения Заказа, номере и статусе исполнения Заказа.
      </p>
      <p>
        <strong>Рекламная рассылка</strong>
        – рассылка, направленная на получение Пользователем/Покупателем информации
        о специальных предложениях Оператора/Продавца, о новых товарах и рекламных
        акциях по сетям электросвязи и по почтовой связи, включая, но не
        ограничиваясь: SMS-рассылки, e-mail-рассылки и т.п. по номеру телефона
        и/или по адресу электронной почты указанной Пользователем/Покупателем.
      </p>
      <p>
        <strong>Промокод</strong>
        – это специальная буквенно-цифровая последовательность, введя которую при
        оформлении Заказа, Пользователь может получить скидку на определённый Товар
        или на всю сумму Заказа. Промокоды могут быть предоставлены Пользователю
        путем размещения их в разделе «Личные промокоды» в Личном кабинете
        Пользователя, а также путем направления Пользователю смс-сообщения на номер
        мобильного телефона и/или электронного письма на адрес электронной почты,
        указанных Пользователем при Регистрации, и иными способами распространения
        Промокодов, при наличии согласия Пользователя на получение от Оператора
        рассылок согласно условиям настоящего Соглашения.
      </p>
      <p align="center">
        <strong>2. </strong>
        <strong>Общие положения:</strong>
      </p>
      <p>
        2.1. Перед тем как начать использовать Сервисы Пользователь обязан
        ознакомиться с настоящим Соглашением и присоединиться к нему.
      </p>
      <p>
        2.2. Пользователь соглашается пройти процедуру регистрации на Сайте,
        заполнив форму регистрации и выразив согласие с условиями Соглашения путем
        проставления отметки «V» в пункте «Я принимаю условия Соглашения» и
        присоединяется к настоящему Соглашению путем совершения следующего
        конклюдентного действия:
      </p>
      <p>
        · Нажатие кнопки «Зарегистрироваться» при регистрации на Сайте, в
        Приложении, в том числе, мобильном либо Приложении в социальных сетях;
      </p>
      <p>
        2.3. По завершении процесса регистрации Пользователь получает логин и
        временный пароль для доступа в Личный кабинет, в течение 3 (Трёх) дней
        Пользователю рекомендуется самостоятельно сменить пароль на постоянный.
        Пользователь несет ответственность за сохранность и неразглашение своей
        пары логин-пароль, а также за все действия, что будут произведены на Сайте
        под его логином-паролем. Пользователь соглашается с тем, что он обязан
        немедленно уведомить Оператора о любом случае неавторизованного (не
        разрешенного Пользователем) доступа со своим логином-паролем и/или о любом
        нарушении безопасности Личного кабинета.
      </p>
      <p>
        2.4. Настоящее Соглашение приравнивается к договору, составленному в
        письменной форме. Присоединяясь к настоящему Соглашению, Пользователь
        выражает полное и безоговорочное согласие со всеми его условиями, в том
        числе, в части предоставления согласия Оператору на обработку персональных
        данных Пользователя на условиях, указанных в разделе 6 настоящего
        Соглашения, и в части предоставления согласия Оператору на получение от
        него e-mail, sms и иных видов рассылок информационного и рекламного
        содержания, а также выражает согласие на дачу Оператором поручения третьим
        лицам, поименованным в настоящем Соглашении, на обработку персональных
        данных Пользователя, с целью исполнения Заказа Пользователя, передачи
        Товара Пользователю, осуществления информационной и рекламной рассылок.
      </p>
      <p>
        2.5. Соглашение может быть изменено Оператором в одностороннем порядке без
        предварительного уведомления Пользователя. Новая редакция Соглашения
        вступает в силу с момента ее опубликования на странице Сайта, если иное не
        предусмотрено новой редакцией Соглашения. Пользователь вправе отказаться от
        принятия изменений Соглашения, осуществленных Оператором, удалив свою
        авторизацию/Личный кабинет, что означает отказ Пользователя от
        использования Сервисов.
      </p>
      <p align="center">
        <strong>3. </strong>
        <strong>Предмет Соглашения</strong>
      </p>
      <p>
        3.1. Оператором по настоящему Соглашению выступает:
      </p>
      <p>
        ИП Краснощеков Александр Евгеньевич ОГРНИП 315450100021616, ИНН
        450132388551, адрес регистрации и места нахождения Оператора: 640020,
        Курганская обл., г. Курган, ул. Куйбышева, дом 15, кв. 10.
      </p>
      <p>
        3.2. Наименование и адрес Партнёра Оператора, осуществляющего обработку
        персональных данных Пользователя по поручению Оператора:
      </p>
      <p>
        ИП Литвинцев Александр Сергеевич ОГРНИП 318450100002821, ИНН 450143459557,
        адрес регистрации и места нахождения Партнёра Оператора: 640008, Курганская
        обл., г. Курган, буль. Солнечный, дом 4, кв. 22.
      </p>
      <p>
        3.3. Оператор предоставляет Пользователю доступ к сервису быстрого поиска и
        заказа Товара на условиях, предусмотренных настоящим Соглашением.
      </p>
      <p>
        3.4. Сайт является единой информационной системой Заказов, которая
        предлагает Пользователям широкие возможности для быстрого и простого поиска
        и Заказа Товара, реализуемого Партнерами Оператора, а также которая
        принимает от Пользователей денежные средства в счет оплаты Заказов.
        Указанный Сервис Оператора носит информационный характер и оказываются
        Пользователю безвозмездно. Пользователь соглашается с тем, что данный
        Сервис предоставляется «как есть» и что Оператор не несет ответственность
        за качество поставляемого Товара, за неверную или несвоевременную доставку
        Товара, а также за сбои в работе платежных систем, которые возникли не по
        вине Оператора. Оператор не несет ответственности за
        соблюдение/несоблюдение Партнёрами Оператора (службами доставки и т.п.)
        своих обязательств перед Пользователем, а также за достоверность
        информации, предоставленной такими службами. Оператор является лишь
        информационным связующим звеном между Пользователем и Партнёрами Оператора
        (службами доставки и т.п.).
      </p>
      <p>
        3.5. Пользователь самостоятельно оформляет Заказ на Сайте.
      </p>
      <p>
        3.6. Обязательства по реализации Товаров, согласно условиям оформленного
        Заказа Пользователя, возникают непосредственно у Партнера Оператора,
        реализующего Товар по данному Заказу.
      </p>
      <p>
        3.7. Для того, чтобы воспользоваться Сервисом Сайта, необходимо иметь
        компьютер/иное электронное устройство с возможностью доступа в Интернет
        (WWW) и доступ в Интернет.
      </p>
      <p>
        3.8. Пользователь оплачивает заказ одним из доступных способов:
      </p>
      <p>
        3.8.1. непосредственно при получении Товара по Заказу от службы доставки
        (Партнера Оператора) наличными денежными средствами, банковской картой или
        иными способами, предоставленными Партнером Оператора;
      </p>
      <p>
        3.8.2. непосредственно при получении Товара по Заказу в месте получения
        Товара у Партнера Оператора наличными денежными средствами, банковской
        картой или иными способами, предоставленными Партнером Оператора;
      </p>
      <p>
        3.8.3. on-line оплата на Сайте банковской картой VISA/MasterCard/МИР. Все
        дополнительные расходы (комиссии, сборы и т.п.) по перечислению денежных
        средств по оплате Товара несёт Пользователь.
      </p>
      <p>
        3.9. При наличии вопросов по совершенному платежу, Пользователь может
        обратиться по телефону в службу поддержки Пользователей.
      </p>
      <p>
        3.10. Пользователь имеет возможность зафиксировать данные своей банковской
        карты в своем Личном кабинете для оплаты Товара. В дальнейшем сумма любого
        последующего Заказа Пользователя будет списываться автоматически с
        указанной в Личном кабинете банковской карты без указания реквизитов, при
        подтверждении платежа со стороны Пользователя путем нажатия кнопки
        «Оплатить» в корзине Личного кабинета и ввода одноразового пароля, в
        случае, если банк-эмитент банковской карты Пользователя предусматривает
        использование данной защиты.
      </p>
      <p>
        3.11. В случае отказа Пользователя от Заказа, либо в случае нарушения
        Партнерами Оператора условий реализации Товара, в том числе, но не
        ограничиваясь: в случае одностороннего отказа от реализации Товара,
        нарушения срока доставки или несоответствия качества Товара и т.п.,
        Пользователь обязан в течение 24 часов с момента наступления времени
        доставки/получения Заказа сообщить Оператору на e-mail адрес Службы
        поддержки Пользователей о своём отказе от Заказа, представить сканкопии
        документов, подтверждающих оплату Заказа, и потребовать вернуть денежные
        средства, уплаченные за не предоставленный Товар или Товар
        несоответствующего качества.
      </p>
      <p>
        Возврат денежных средств производится в течение 3 (Трёх) банковских дней с
        момента получения требования от Пользователя о возврате денежных средств.
      </p>
      <p>
        Возврат денежных средств, оплаченных Пользователем через Сайт банковской
        картой, осуществляется только на ту карту, с которой была произведена
        оплата.
      </p>
      <p>
        3.12. По истечении 2 (Двух) рабочих дней с момента оплаты Заказа/Товара при
        неполучении Оператором уведомления от Пользователя об отказе от
        Заказа/Товара и требования о возврате денежных средств, Заказ считается
        выполненным надлежащим образом, Товар надлежащего качества полученным,
        подлежащим оплате и денежные средства за него возврату не подлежат.
      </p>
      <p>
        3.13. Оператор/Партнер Оператора вправе отказать Пользователю в оформлении
        Заказа с формой оплаты «наличными или картой курьеру», если ранее
        оформленные Заказы Пользователя не были доставлены по вине Пользователя, в
        том числе если Пользователь не открыл дверь или не ответил по телефону
        курьеру при доставке Заказа, или Пользователь указал несуществующий адрес
        доставки и т.п.
      </p>
      <p>
        3.14. Авторизация операций по банковским картам осуществляется банком. Если
        у банка есть основания полагать, что операция носит мошеннический характер,
        то банк вправе отказать в осуществлении данной операции. Мошеннические
        операции с банковскими картами являются уголовным преступлением.
      </p>
      <p>
        3.15. Во избежание случаев мошенничества (хищения банковской карты третьими
        лицами) при оплате банковскими картами платежи, оплаченные банковской
        картой, могут проверяться Оператором/Партнёром Оператора в рамках имеющихся
        законных возможностей. Пользователь, оформивший такой платеж, обязан по
        запросу, поступившему от Оператора/Партнера Оператора, предоставить копию
        необходимых документов для подтверждения правомерного использования
        банковской карты Пользователем.
      </p>
      <p align="center">
        <strong>4. </strong>
        <strong>Права и обязанности Сторон по Соглашению</strong>
      </p>
      <p>
        4.1. Оператор обязан предоставить Пользователю доступ к сервису быстрого
        поиска и Заказа Товара на условиях, предусмотренных настоящим Соглашением.
      </p>
      <p>
        4.2. Пользователь соглашается предоставить достоверную, полную и актуальную
        информацию по вопросам, предлагаемым при прохождении Регистрации и
        оформлении Заказа.
      </p>
      <p>
        4.3. Если Пользователь предоставляет неверную/некорректную информацию или у
        Оператора есть основания полагать, что предоставленная Пользователем
        информация неверна, неполна или неточна, то Оператор имеет право
        приостановить либо отменить Регистрацию Пользователя и отказать
        Пользователю в использовании Сервиса.
      </p>
      <p>
        4.4. Оператор имеет право распоряжаться статистической информацией,
        связанной с функционированием Сайта.
      </p>
      <p>
        4.5. Пользователю может быть предоставлен Промокод на определенный номинал
        для последующего его использования при приобретении услуг и/или товаров с
        помощью Сервиса. Условия предоставления Промокода указываются Оператором
        для каждого конкретного случая (акции) отдельно на Сайте.
      </p>
      <p>
        4.6. Пользователь вправе оформить с применением любых Промокодов не более 3
        (Трех) заказов в сутки.
      </p>
      <p>
        4.7. Осуществление и оплата Заказа с применением Промокода осуществляется в
        следующем порядке:
      </p>
      <p>
        4.7.1. В случае, если номинал Промокода больше или равен стоимости Заказа,
        то скидка составляет 100 % (Сто процентов) от стоимости Заказа, при этом
        разница между стоимостью Заказа и номиналом Промокода Пользователю не
        выплачивается.
      </p>
      <p>
        4.7.2. В случае, если номинал Промокода меньше стоимости Заказа,
        Пользователь в момент совершения Заказа/получения Товара должен будет
        оплатить разницу между общей суммой Заказа, совершенного на Сайте, и суммой
        номинала Промокода.
      </p>
      <p>
        4.7.3. При совершении одного Заказа может быть использован только 1 (Один)
        Промокод. Выплата Пользователю суммы, равной номиналу Промокода, в денежном
        эквиваленте не допускается.
      </p>
      <p>
        4.7.4. Промокод имеет ограниченный срок действия и может быть использован
        только при on-line оплате Заказа в течение срока действия соответствующего
        Промокода. Пользователь самостоятельно ознакамливается с условиями и
        сроками действия Промокодов путем обращения на Сайт или в Службу поддержки
        Пользователей.
      </p>
      <p>
        4.8. В случае успешной оплаты Заказа Пользователю в Личном кабинете
        начисляются баллы в размере и порядке, предусмотренном Правилами начисления
        баллов размещённым на Сайте. Начисленные баллы могут быть обменены на
        готовое блюдо питания в соответствии с представленным на Сайте
        ассортиментом с выборкой по критерию «Еда за баллы» (каждое блюдо содержит
        в описании количество требуемых баллов для обмена) либо могут быть обменены
        на электронные сертификаты (специальные предложения-промокоды от
        Оператора). При этом Пользователь соглашается с тем, что баллы не имеют
        денежной стоимости и не подлежат денежной оценке, не являются средством
        платежа, и их единственное назначение заключается в их обмене на готовые
        блюда питания/электронные сертификаты, доступные для Пользователя в момент
        обмена, с учетом положений настоящего Соглашения. При этом баллы нельзя
        обменять на наличные денежные средства или иное имущество, кроме выше
        перечисленных.
      </p>
      <p>
        4.8.1. Зачисление баллов производится в течение 24 (двадцать четыре) часа с
        момента успешной оплаты Заказа (при условии, что Заказ в дальнейшем не был
        отменен), в случае, если по истечении указанного периода баллы не были
        начислены, то Пользователь вправе обратиться в Службу поддержки Оператора.
        Количество начисленных баллов отражается в Личном кабинете Пользователя.
      </p>
      <p>
        4.8.2. Срок использования баллов - 180 (сто восемьдесят) календарных дней с
        момента их зачисления, по истечении указанного срока баллы аннулируются.
      </p>
      <p>
        4.8.3. В случае отмены Заказа, в состав которого было включено блюдо в
        обмен на баллы либо в оплату которого входил электронный сертификат, по
        основаниям, предусмотренным действующим законодательством Российской
        Федерации, Оператор осуществляет возврат соответствующего количества баллов
        Пользователю.
      </p>
      <p>
        4.8.4. Оператор/Партнеры Оператора вправе проводить рекламные акции, в
        соответствии с которыми Пользователю могут быть начислены дополнительные
        баллы (их количество, порядок зачисления, иные условия определяются
        Оператором) при условии обязательного выполнения Пользователем действий,
        необходимых для участия в таких акциях.
      </p>
      <p align="center">
        <strong>5. </strong>
        <strong>Информация и интеллектуальная собственность</strong>
      </p>
      <p>
        5.1. Сайт содержит материалы, охраняемые авторским правом, товарные знаки и
        иные охраняемые законом материалы, в том числе тексты, фотографии,
        графические изображения. При этом все содержание Сайта охраняется авторским
        правом как произведение, созданное коллективным творческим трудом в
        соответствии с законодательством Российской Федерации об авторском праве и
        смежных правах.
      </p>
      <p>
        5.2. Оператору принадлежит авторское право на использование содержания
        Сайта, в том числе, право на подбор, расположение, систематизацию и
        преобразование данных, содержащихся на Сайте, а также на сами исходные
        данные, кроме случаев, отдельно отмеченных в содержании опубликованных на
        сайте материалов.
      </p>
      <p>
        5.3. Пользователь не имеет права вносить изменения, публиковать, передавать
        третьим лицам, участвовать в продаже или уступке, создавать производные
        продукты или иным образом использовать в коммерческих целях, частично или
        полностью, содержание Сайта, без получения на то предварительного
        специального письменного разрешения Оператора.
      </p>
      <p>
        5.4. Пользователь признает и соглашается с тем, что Сервисы Сайта, Сайт и
        все необходимые программы, связанные с ним, содержат конфиденциальную
        информацию, которая защищена законами об интеллектуальной собственности и
        прочими российскими и международными законами. Пользователь соглашается не
        модифицировать, не продавать, не распространять этот контент и программы,
        целиком либо по частям без предварительного специального письменного
        разрешения Оператора.
      </p>
      <p>
        5.5. Пользователь Сети обязуется не размещать на Сайте и не направлять
        куда-либо через/посредством Сайта любые материалы следующего характера:
      </p>
      <p>
        5.5.1. охраняемые законодательством об интеллектуальной собственности, в
        том числе, авторским правом, законодательством о товарных знаках, и иные
        охраняемые законодательством материалы без получения выраженного разрешения
        обладателя прав на охраняемый материал;
      </p>
      <p>
        5.5.2. являющиеся рекламой каких-либо товаров или услуг, в том числе
        являющихся конкурентными товарам и услугам Оператора, без получения на то
        предварительного специального письменного разрешения Оператора;
      </p>
      <p>
        5.5.3. нарушающие законодательство, содержащие угрозы и оскорбления,
        дискредитирующие других лиц, нарушающие права граждан на частную жизнь или
        публичный порядок, носящие характер непристойности;
      </p>
      <p>
        5.5.4. нарушающие в той или иной степени честь и достоинство, права и
        охраняемые законом интересы других лиц;
      </p>
      <p>
        5.5.5. способствующие или содержащие призывы к разжиганию религиозной,
        расовой или межнациональной розни, содержащие попытки разжигания вражды или
        призывы к насилию;
      </p>
      <p>
        5.5.6. и иные материалы, которые побуждают других лиц на противоправное
        поведение, влекущее уголовную, гражданско-правовую и иную ответственность
        или каким-либо образом нарушающее положения законодательства.
      </p>
      <p>
        5.6. Оператор не несет ответственность за содержание отзывов,
        опубликованных Пользователем/Пользователями на Сайте. Оператора может не
        разделять опубликованное мнение Пользователя.
      </p>
      <p>
        5.7. Оператор оставляет за собой право размещать комментарии к отзыву
        Пользователя, а также не размещать и удалять отзывы Пользователя,
        нарушающие российское законодательство и наносящие ущерб законным интересам
        третьих лиц, в том числе, носящие оскорбительный характер либо порочащие
        честь, достоинство и репутацию таких лиц.
      </p>
      <p align="center">
        <strong>6. </strong>
        <strong>Персональные данные и конфиденциальность информации</strong>
      </p>
      <p>
        6.1. Конфиденциальность персональной информации физических лиц (субъектов
        персональных данных) действует в отношении всей информации, которую
        Оператор получает или может получить о лице во время использования им
        Сайта, Сервисов, служб, программ и иных продуктов Оператора.
      </p>
      <p>
        6.2. Персональные данные Пользователя обрабатываются на основании и в
        соответствии со статьей 24 Конституции РФ, ФЗ «О персональных данных»
        №152-ФЗ и иным действующим законодательством РФ.
      </p>
      <p>
        6.3. При Регистрации/оформлении Заказа на Сайте Пользователь даёт согласие
        на обработку своих персональных данных с соблюдением условия
        конфиденциальности персональных данных, указанных в настоящем Соглашении.
      </p>
      <p>
        6.4. Под обработкой Оператором персональных данных Пользователя понимается
        любое действие (операция) или совокупность действий (операций), совершаемых
        с использованием средств автоматизации или без использования таких средств
        с персональными данными, включая сбор, запись, систематизацию, накопление,
        хранение, уточнение (обновление, изменение), сопоставление, извлечение,
        использование, передача Партнерам Оператора, обезличивание, блокирование,
        удаление, уничтожение.
      </p>
      <p>
        6.5. Срок обработки персональных данных начинается с момента присоединения
        Пользователя к настоящему Соглашению и заканчивается в момент отзыва
        Пользователем своего согласия на обработку персональных данных в порядке,
        указанном в настоящем Соглашении.
      </p>
      <p>
        6.6. Пользователь соглашается, что Оператор вправе поручать Партнёрам
        Оператора обработку персональных данных Пользователя с целью исполнения
        настоящего Соглашения, в том числе изготовление блюд для Пользователя,
        доставку Заказа, оформленного Пользователем, приём оплаты за предоставление
        Пользователю Товара по Заказу и т.п.
      </p>
      <p>
        6.7. Перечень персональных данных, на обработку которых дает согласие
        Пользователь, принимая настоящее Соглашение: Фамилия Имя Отчество, пол,
        дата рождения, номер телефона, адрес электронной почты, адрес(-а) для
        доставки Товаров по Заказам.
      </p>
      <p>
        6.8. Оператор получает информацию об ip-адресе посетителя Сайта. Данная
        информация не используется для установления личности Посетителя, а
        исключительно в целях статистических и маркетинговых исследований.
      </p>
      <p>
        6.9. Пользователь соглашается с тем, что Оператор вправе осуществлять
        записи телефонных разговоров с Пользователем, в том числе с целью повышения
        качества обслуживания, разрешения спорных ситуаций и т.п. При этом Оператор
        обязуется предотвращать попытки несанкционированного доступа к информации,
        полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам,
        не имеющим непосредственного отношения к исполнению Заказов в соответствие
        с п. 4 ст. 16 Федерального закона «Об информации, информационных
        технологиях и защите информации».
      </p>
      <p>
        6.10. Пользователь может в любой момент изменить (обновить, дополнить)
        предоставленную им персональную информацию в личном кабинете в части ФИО,
        номера телефона, пароля, адреса для доставки Заказов.
      </p>
      <p>
        6.11. Оператор защищает персональную информацию Пользователя в соответствии
        с требованиями, предъявляемыми к защите такого рода информации. Для защиты
        персональной информации Пользователя, обеспечения ее надлежащего
        использования и предотвращения несанкционированного и/или случайного
        доступа к ней, Оператор применяет необходимые и достаточные технические и
        административные меры. Предоставляемая Пользователем персональная
        информация хранится на серверах с ограниченным доступом, расположенных в
        охраняемых помещениях. В отношении персональной информации Пользователя
        Оператор сохраняет ее конфиденциальность, кроме случаев добровольного
        предоставления Пользователем информации о себе для общего доступа
        неограниченному кругу лиц (в социальных сетях и т.п.). Оператор не несет
        ответственности за сведения, предоставленные Пользователем на Сайте в
        общедоступной форме, а не в Личном кабинете.
      </p>
      <p>
        6.12. Пользователь соглашается с тем, что Оператор имеет право отправлять
        Пользователю от своего имени самостоятельно или с привлечением технических
        партнеров информационные, в том числе сервисные и рекламные сообщения, на
        электронную почту Пользователя, мобильный телефон (смс, телефонные звонки)
        или через используемые им сервисы Партнеров Оператора (социальные сети,
        мессенджеры и иные). Пользователь вправе отказаться от получения рекламной
        и другой информации без объяснения причин отказа, путём письменного
        обращения в Службу поддержки Оператора на E-mail адрес. Сервисные
        сообщения, информирующие Пользователя о статусе исполнения Заказа и этапах
        его обработки, отправляются автоматически и не могут быть отклонены
        Пользователем.
      </p>
      <p>
        6.13. Согласие на обработку персональных данных может быть отозвано
        Пользователем путем письменного обращения (заявки) в Службу поддержки
        Оператора на E-mail адрес с указанием своих ФИО и номер телефона. В случае
        отзыва Пользователем согласия на обработку персональных одновременно
        прекращается использование Пользователем Сервисов Оператора и действие
        настоящего Соглашения в отношении Пользователя, за исключением исполнения
        Сторонами своих обязательств, возникших во время действия настоящего
        Соглашения.
      </p>
      <p>
        6.14. Оператор обязуется прекратить обработку персональных данных
        Пользователя и обеспечить прекращение такой обработки Партнерами Оператора,
        в срок, не превышающий тридцати дней с даты поступления в Службу поддержки
        заявки на отзыв согласия на обработку персональных данных и расторжение
        настоящего Соглашения.
      </p>
      <p align="center">
        <strong>7. </strong>
        <strong>Заключительные положения. Обратная связь.</strong>
      </p>
      <p>
        7.1. Настоящее Соглашение вступает в действия относительно регулирования
        отношений между Оператором и Пользователем с момента присоединения
        Пользователя к настоящему Соглашению в порядке, указанном в Соглашении.
      </p>
      <p>
        7.2. Настоящее Соглашение регулируется нормами действующего
        законодательства РФ.
      </p>
      <p>
        7.3. Все возможные споры, возникшие между Пользователем и Оператором в
        течение действия настоящего Соглашения и связанные с его исполнением,
        разрешаются согласно нормам действующего законодательства РФ с обязательным
        соблюдением претензионного порядка разрешения спора.
      </p>
      <p>
        7.4. В связи с безвозмездностью условий настоящего Соглашения нормы о
        защите прав потребителей не могут быть применимы к отношениям между
        Пользователем и Оператором. Оплата за получаемый Товар по Заказам,
        оформленным на Сайте поступает Партнеру Оператора, а не Оператору.
      </p>
      <p>
        7.5. Ничто в Соглашении не может пониматься как установление между
        Пользователем и Оператором агентских отношений, отношений товарищества,
        отношений по совместной деятельности, отношений личного найма, либо
        каких-то иных отношений, прямо не предусмотренных настоящим Соглашением.
      </p>
      <p>
        7.6. С предложениями и вопросами, связанными с исполнением Соглашения,
        необходимо обращаться в Службу поддержки Оператора по телефонной связи
        и/или на E-mail адрес.
      </p>
    </div>
  </div>
  <a (click)="goBack()" class="back-page"> назад</a>
</div>

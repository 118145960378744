import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { MainService } from "../main.service";
import { ActivatedRoute } from "@angular/router";
import { SharedDataService } from "../shared-data.service";
import { MainMenuService } from "../main-menu.service";


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnDestroy {

  searchTerm: string;
  dishes = [];
  company: any;
  suggestions = [];
  subscriptions = [];
  public isLoading: boolean;
  @ViewChild('searchDish') searchDish: ElementRef;

  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private sharedData: SharedDataService,
    private menuService: MainMenuService
  ) { }

  public makeSearch() {
    if (this.searchTerm !== '') {
      this.sharedData.changeSuggestionsStatus(true);
      this.subscriptions.push(this.mainService.requestForSuggestions({'q': this.searchTerm})
        .subscribe(
          response => {
            console.log('from search: ', response);
            this.dishes = response['dishes'];
            this.company = response['company'];
            response['title'] = 'Поиск';
            this.sharedData.changeData(response);
            this.sharedData.changeSuggestionsStatus(false);
          },
          error => {
            this.sharedData.changeSuggestionsStatus(false);
            console.log('error while searching: ', error);
            this.menuService.defaultErrorDialog();
          }
        ));
    }
  }

  public checkUrlAndSearch(): void {
    if (this.route.snapshot.paramMap.get('q')) {
      this.searchTerm = this.route.snapshot.paramMap.get('q');
      this.makeSearch();
    }
    else {
      this.searchTerm = '';
      let subscription = this.sharedData.currentData.subscribe(
        response => {
          console.log('response in search1: ', response);
          if (response['title'] !== 'Поиск') {
            response['title'] = 'Поиск';
            this.sharedData.changeData(response);
          }
        }, error => {
          this.menuService.defaultErrorDialog();
        }
      );
      subscription.unsubscribe();
    }
  }

  public onSelect(dish) {
    this.sharedData.changeSuggestions([]);
    this.searchTerm = '';
    this.menuService.showSelectedDish(dish);
  }

  ngOnInit() {
    this.menuService.search(this.searchDish.nativeElement);
    this.sharedData.changeSuggestionsStatus(false);
    this.checkUrlAndSearch();
    this.sharedData.currentSuggestions.subscribe( data => this.suggestions = data );
    this.sharedData.currentSuggestionsStatus.subscribe(isLoading => this.isLoading = isLoading);
  }

  ngOnDestroy() {
    this.sharedData.changeSuggestionsStatus(false);
    this.subscriptions.forEach( subscription => subscription.unsubscribe() );
  }

}

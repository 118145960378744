<div class="container">
  <div class="primary">
    <div class="order">
      <p>
        <strong>УСЛОВИЯ ДОСТАВКИ</strong>
      </p>
      <p>Доставка осуществляется бесплатно строго в соответствии с границами зон доставки ресторанов.
        Минимальная сумма заказа составляет {{ delivery_min_price }} рублей.
      </p>
      <p>Более подробно ознакомиться с зонами доставки вы можете при оформлении заказа.</p>
      <p><strong>Время работы доставки: </strong></p>
      <p>
        В понедельник: с {{ formatTime(delivery_timetable.monday_start) }} до {{ formatTime(delivery_timetable.monday_end) }}.<br>
        Во вторник: с {{ formatTime(delivery_timetable.tuesday_start) }} до {{ formatTime(delivery_timetable.tuesday_end) }}.<br>
        В среду: с {{ formatTime(delivery_timetable.wednesday_start) }} до {{ formatTime(delivery_timetable.wednesday_end) }}.<br>
        В четверг: с {{ formatTime(delivery_timetable.thursday_start) }} до {{ formatTime(delivery_timetable.thursday_end) }}.<br>
        В пятницу: с {{ formatTime(delivery_timetable.friday_start) }} до {{ formatTime(delivery_timetable.friday_end) }}.<br>
        В субботу: с {{ formatTime(delivery_timetable.saturday_start) }} до {{ formatTime(delivery_timetable.saturday_end) }}.<br>
        В воскресенье: с {{ formatTime(delivery_timetable.sunday_start) }} до {{ formatTime(delivery_timetable.sunday_end) }}
      </p>
      <p><strong>Способы оплаты: </strong></p>
      <ul>
        <li>Наличными курьеру.</li>
        <li>Банковской картой через терминал у курьера.</li>
      </ul>
      <p><strong>Реквизиты: </strong></p>
      <p>
        ООО «На Парах. Ленинский»<br>
        ИНН 7840059228/ КПП 784001001,<br>
        ОГРН 1167847419772
      </p>
      <p>
        ООО «На Парах. Коломяжский»<br>
        ИНН 7814251503 / КПП 781401001,<br>
        ОГРН 1157847223071
      </p>
    </div>
  </div>
      <a (click)="goBack()" class="back-page"> назад</a>
</div>

import { Component, OnInit } from '@angular/core';
import { MainService } from "../main.service";
import { Location } from "@angular/common";
import { SharedDataService } from "../shared-data.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {MainMenuService} from "../main-menu.service";
import { UrlSegment } from "@angular/router";


@Component({
  selector: 'app-position',
  templateUrl: './position.component.html',
  styleUrls: ['./position.component.css']
})
export class PositionComponent implements OnInit {

  sharedData: any;
  dish: any;
  client: any;

  price_total: number;

  ext_id: any = undefined;
  iiko_id: any;
  ext_name: string = undefined;
  iiko_name: string;
  selected_params: any = {parts: {},
                          tpn: {}};

  relatedDishes: any[] = null;

  proteinsTotal: number;
  fatsTotal: number;
  carbsTotal: number;
  gramsTotal: number;
  millilitersTotal: number;
  caloriesTotal: number;

  constructor(
    private mainService: MainService,
    private location: Location,
    private sharedService: SharedDataService,
    private route: ActivatedRoute,
    private menuService: MainMenuService,
    private router: Router,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };
  }

  goBack(): void {
    this.menuService.goBack();
  }

  private getPositionId(): string {
    switch (this.route.snapshot.url.length) {
      case(2):
        return this.route.snapshot.url[1].path.split('-')[0];
      default:
        return this.route.snapshot.url[2].path.split('-')[0];
    }
  }

  getPosition(): void {
    const id = this.getPositionId();
    this.mainService.getPositionFromId(id)
      .subscribe( response => {
        this.relatedDishes = (response['related_dishes'].length > 0) ? response['related_dishes'] : null;
        this.makeLinkrelatedDish();
        if (response['dish']['parent_id']) {
          this.router.navigate(['/position', {positionId: response['dish']['parent_id']}]);
          return;
        }
        this.dish = response['dish'];
        this.getNutritionalValue();
        this.client = response['client'];
        this.findExtId();

        this.sharedData = response;
        this.sharedData.title = response['dish']['name'];
        this.getTotal();
        this.sharedService.changeData(this.sharedData);
        this.menuService.setEcommerce([this.dish], 'detail');
        this.getUrlParams();
      },error => {
        if (error.status === 423) {
          if (error.error.error.code === 221 || error.error.error.code === 242) {
            this.menuService.openBackToMenuDialog(
                'Ошибка!',
                'Выбранное блюдо недоступно, вы можете заказать блюда из другого меню',
                'Перейти к списку меню');
          }
        }
        else
          this.menuService.defaultErrorDialog();
        }
      );
  }

  getNutritionalValue () {
    this.proteinsTotal = this.checkIsNotNullUndefinedOrZero(this.dish.proteins) ? this.dish.proteins : 0;
    this.fatsTotal = this.checkIsNotNullUndefinedOrZero(this.dish.fats) ? this.dish.fats : 0;
    this.carbsTotal = this.checkIsNotNullUndefinedOrZero(this.dish.carbs) ? this.dish.carbs : 0;
    this.gramsTotal = this.checkIsNotNullUndefinedOrZero(this.dish.grams) ? this.dish.grams : 0;
    this.millilitersTotal = this.checkIsNotNullUndefinedOrZero(this.dish.milliliters) ? this.dish.milliliters : 0;
    this.caloriesTotal = this.checkIsNotNullUndefinedOrZero(this.dish.calories) ? this.dish.calories : 0;
  }

  checkIsNotNullUndefinedOrZero(param) {
    return param !== null && param > 0 && typeof param !== 'undefined';
  }

  checkCountTopping(qty, nutritionalValue) {
    return qty > 0 && this.checkIsNotNullUndefinedOrZero(nutritionalValue);
  }

  toBasket() {
    if (!this.menuService.checkLogin({show_info: true})) return;

    const add_vals = [];
    const dish_to_add_id = this.dish.id;

    if (this.dish.pos_type === 'constructor') {
      for (let i = 0; this.dish.parts && i < this.dish.parts.length; i++) {
        const part_group = this.dish.parts[i];

        if (!part_group.selected) {
          this.menuService.openDialog(
            'Ошибка!',
            'Необходимо выбрать ' + part_group.name,
            true
          );
          return;
        }

        add_vals.push({dish_id: part_group.selected.id, group_id: part_group.id});
      }

      const toppings = {};
      for (let i = 0; this.dish.toppings && i < this.dish.toppings.length; i++){
        const toppingGroup = this.dish.toppings[i];
        for (let j = 0; j < toppingGroup.dishes.length; j++) {
          const topping = toppingGroup.dishes[j];
          if (topping.qty > 0) {
            toppings[topping.id] = topping.qty;
          }
        }
      }

      const data = {
        'type': 'add',
        'position_id': dish_to_add_id,
        'adds': add_vals,
        'toppings': toppings
      };
      this.mainService.changeBasketPosition(data)
        .subscribe(
          response => {
            this.sharedService.changeData(response);
            this.menuService.setEcommerce([this.dish], 'add');
          },
          error => {
            console.log('error while adding constructor to basket: ', error);
            this.menuService.defaultErrorDialog();
          }
        );
    }
    else this.menuService.addToBasket(this.dish);

  }

  makeLinkrelatedDish() {
    if (this.relatedDishes === null) return;

    this.relatedDishes.forEach(dish => {
      if (this.isEmptyCategory(dish))
        dish.link = dish.menu.id + "-" + dish.menu.slug + "/" + dish.id + "-" + dish.slug + ".html";
      else
        dish.link = dish.menu.id + "-" + dish.menu.slug + "/" + dish.category.id + "-" + dish.category.slug + "/" + dish.id + "-"
          + dish.slug + ".html";
    });
  }

  getTotal() {
    this.price_total =  this.dish.price ? this.dish.price : 0;
    this.getNutritionalValue();
    for (let i = 0; this.dish.parts && i < this.dish.parts.length; i++) {
      const part = this.dish.parts[i].selected;
      if (!part) {
        continue;
      }
        this.price_total += part.price ? part.price : 0;
        this.fatsTotal += part.fats ? part.fats : 0;
        this.carbsTotal += part.carbs ? part.carbs : 0;
        this.proteinsTotal += part.proteins ? part.proteins : 0;
        this.caloriesTotal += part.calories ? part.calories : 0;
        this.gramsTotal += part.grams ? part.grams : 0;
        this.millilitersTotal += part.milliliters ? part.milliliters : 0;
    }

    for (let i = 0; this.dish.toppings && i < this.dish.toppings.length; i++) {
      const toppingGroup = this.dish.toppings[i];
      for (let j = 0; j < toppingGroup.dishes.length; j++) {
        const topping = toppingGroup.dishes[j];
        this.price_total += topping.qty > 0 ? topping.qty * topping.price : 0;
        this.fatsTotal += this.checkCountTopping(topping.qty, topping.fats) ? topping.qty * topping.fats : 0;
        this.carbsTotal += this.checkCountTopping(topping.qty, topping.carbs) ? topping.qty * topping.carbs : 0;
        this.proteinsTotal += this.checkCountTopping(topping.qty, topping.proteins) ? topping.qty * topping.proteins : 0;
        this.caloriesTotal += this.checkCountTopping(topping.qty, topping.calories) ? topping.qty * topping.calories : 0;
        this.gramsTotal += this.checkCountTopping(topping.qty, topping.grams) ? topping.qty * topping.grams : 0;
        this.millilitersTotal += this.checkCountTopping(topping.qty, topping.milliliters) ? topping.qty * topping.milliliters : 0;
      }
    }
    this.findExtId();
  }

  decreaseAmount(topping) {
    if (!topping.qty) {
      topping.qty = 0;
    }
    if (topping.qty < 1) {
      return;
    }

    topping.qty--;
    this.changeUrlToppings(topping);
    this.getTotal();
  }

  increaseQuantity(topping) {
    if (!topping.qty) {
      topping.qty = 0;
    }

    topping.qty++;
    this.changeUrlToppings(topping);
    this.getTotal();
  }

  changeUrlToppings(topping) {
    if (topping.qty > 0){
      this.selected_params.tpn[topping.id] = topping.qty;
    } else {
      delete this.selected_params.tpn[topping.id];
    }
    this.refreshUrlParams();
  }

  delImg(imageLink) {
    const id = this.getPositionId();
    const data = {
      'type': 'del_image',
      'url_for_del': imageLink
    };
    this.mainService.toChangeImg(data, id)
      .subscribe(
        response => {
          this.menuService.openDialog(
            'Отлично!',
            'Картинка удалена.',
            true
          );
          this.getPosition();
        },
        error => {
          console.log('error while deleting img: ', error);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  uploadImg(files) {
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      const id = this.getPositionId();

      console.log(this.route.snapshot.paramMap);
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const data = {
              'image_base64': reader.result,
              'image_name': file.name,
              'type': 'add_image'
            };
        this.mainService.toChangeImg(data, id)
          .subscribe(
            response => {
              this.menuService.openDialog(
                'Отлично!',
                'Картинка добавлена.',
                true
              );
              this.getPosition();
            },
            error => {
              console.log('error while adding image: ', error);
              this.menuService.defaultErrorDialog();
            }
          );
      };
    }
  }

  getSelectedPartIds() {
    const add_vals = [];

    for (let i = 0; this.dish.parts && i < this.dish.parts.length; i++) {
      const part_group = this.dish.parts[i];
      if (part_group.selected) {
        add_vals.push(part_group.selected.id);
      }
    }
    return add_vals;
  }

  findExtId() {
    if (this.client && this.client.role === 'manager') {
      const add_vals = this.getSelectedPartIds();
      const data = {
        'adds': add_vals,
        'dish_id': this.dish.id
      };
      this.mainService.requestForExtId(data, 'find_single_dish')
        .subscribe(
          response => {
            if (response['ext_id']) {
              this.ext_id = response['ext_id'];
              this.ext_name = response['ext_name'];
            }
            else {
              this.ext_id = '';
            }
            if (response['iiko_id']) {
              this.iiko_id = response['iiko_id'];
              this.iiko_name = response['iiko_name'];
            }
            else {
              this.iiko_id = '';
            }
          },
          error => {
            console.log('error while findExtId: ', error);
            this.menuService.defaultErrorDialog();
          }
        );
    }
  }

  saveExtId() {
    const add_vals = this.getSelectedPartIds();
    const data = {
      'adds': add_vals,
      'ext_id': this.ext_id,
      'iiko_id': this.iiko_id,
      'dish_id': this.dish.id
    };

    this.mainService.requestForExtId(data, 'save_single_dish')
      .subscribe(
        response => {
          this.menuService.openDialog(
            'Отлично',
            'Сохранено.',
            true
          );
          this.getTotal();
        },
        error => {
          console.log('error while saving ExtId: ', error);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  getUrlParams() {
    let url_params: any = {};

    this.route.fragment.subscribe(params => {
      if (params) {
        url_params = Object.assign({}, JSON.parse(params));

        let parts = this.dish.parts;
        for (let i = 0; i < parts.length; i++) {
          for (let j = 0; j < parts[i].dishes.length; j++) {
            if (parts[i].dishes[j].id == url_params.parts[parts[i].id]) {
              parts[i].selected = parts[i].dishes[j];
              this.selected_params.parts[parts[i].id] = url_params.parts[this.dish.parts[i].id];
            }
          }
        }

        let toppings = this.dish.toppings;
        for (let i = 0; i < toppings.length; i++) {
          for (let j = 0; j < toppings[i].dishes.length; j++) {
            let tpn_id = toppings[i].dishes[j].id.toString();
            if (tpn_id in url_params.tpn) {
              toppings[i].dishes[j].qty = url_params.tpn[tpn_id];
              this.selected_params.tpn[tpn_id] = url_params.tpn[tpn_id];
            }
          }
        }

        this.refreshUrlParams();
        this.getTotal();
      }
    }, error => {
      console.log('error while trying getting url params', error);
      this.menuService.defaultErrorDialog();
    });
  }

  refreshUrlParams() {
    if (this.selected_params.parts || this.selected_params.tpn) {
      const url = this
        .router
        .createUrlTree([], {
          fragment: JSON.stringify(this.selected_params),
          relativeTo: this.route,
          skipLocationChange: true
        })
        .toString();
      this.location.replaceState(url);
    }
  }

  changeUrlParts(group, item) {
    this.selected_params.parts[group] = item.toString();
    this.refreshUrlParams();
    console.log(this.selected_params);
  }

  relatedToBasket(dish) {
    this.menuService.addToBasket(dish);
  }

  isEmptyCategory(dish) {
   return typeof dish.category === 'undefined';
  }

  ngOnInit() {
    this.getPosition();
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-menu-mobi.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./top-menu-mobi.component";
import * as i5 from "../shared-data.service";
import * as i6 from "../dataDescription";
import * as i7 from "../main-menu.service";
var styles_TopMenuMobiComponent = [i0.styles];
var RenderType_TopMenuMobiComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopMenuMobiComponent, data: {} });
export { RenderType_TopMenuMobiComponent as RenderType_TopMenuMobiComponent };
function View_TopMenuMobiComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [["class", "ng-hide"], ["id", "logoutLink-mobi"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["aria-expanded", "true"], ["aria-haspopup", "true"], ["data-toggle", "dropdown"], ["href", "#"], ["role", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "icon-logout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u042B\u0425\u041E\u0414"]))], null, null); }
function View_TopMenuMobiComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["routerLink", "/\u043A\u043E\u0440\u0437\u0438\u043D\u0430"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["class", "icon-busket"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041A\u041E\u0420\u0417\u0418\u041D\u0410"])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "li", [["id", "ordersLink-mobi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "a", [["routerLink", "/\u0437\u0430\u043A\u0430\u0437\u044B.html"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [["class", "icon-order"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0417\u0410\u041A\u0410\u0417\u042B"])), (_l()(), i1.ɵeld(15, 0, null, null, 6, "li", [["id", "profileLink-mobi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 5, "a", [["routerLink", "/\u043F\u0440\u043E\u0444\u0438\u043B\u044C"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "i", [["class", "icon-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041F\u0420\u041E\u0424\u0418\u041B\u042C"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopMenuMobiComponent_2)), i1.ɵdid(23, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/\u043A\u043E\u0440\u0437\u0438\u043D\u0430"; _ck(_v, 3, 0, currVal_2); var currVal_5 = "/\u0437\u0430\u043A\u0430\u0437\u044B.html"; _ck(_v, 10, 0, currVal_5); var currVal_8 = "/\u043F\u0440\u043E\u0444\u0438\u043B\u044C"; _ck(_v, 17, 0, currVal_8); var currVal_9 = _co.isLogged; _ck(_v, 23, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 10).target; var currVal_4 = i1.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵnov(_v, 17).target; var currVal_7 = i1.ɵnov(_v, 17).href; _ck(_v, 16, 0, currVal_6, currVal_7); }); }
function View_TopMenuMobiComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [["id", "loginLink-mobi"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "a", [["routerLink", "/\u0432\u0445\u043E\u0434"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "icon-logout"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u0425\u041E\u0414"]))], function (_ck, _v) { var currVal_2 = "/\u0432\u0445\u043E\u0434"; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_TopMenuMobiComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "mobi-menu"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mobiMenuOverlayClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "icon-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041C\u0415\u041D\u042E"])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "li", [["style", "display: none;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 5, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "menu-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "i", [["class", "icon-cutlery"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0420\u0415\u0421\u0422\u041E\u0420\u0410\u041D\u042B"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopMenuMobiComponent_1)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TopMenuMobiComponent_3)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(20, 0, null, null, 0, "div", [["class", "mobi-menu-overlay"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.mobiMenuOverlayClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/"; _ck(_v, 4, 0, currVal_3); var currVal_6 = "/"; _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.isLogged; _ck(_v, 17, 0, currVal_7); var currVal_8 = !_co.isLogged; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mobiMenuVisible; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 11).target; var currVal_5 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_9 = _co.mobiMenuVisible; _ck(_v, 20, 0, currVal_9); }); }
export function View_TopMenuMobiComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-top-menu-mobi", [], null, null, null, View_TopMenuMobiComponent_0, RenderType_TopMenuMobiComponent)), i1.ɵdid(1, 114688, null, 0, i4.TopMenuMobiComponent, [i5.SharedDataService, i6.DjangoRequestData, i5.SharedDataService, i7.MainMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopMenuMobiComponentNgFactory = i1.ɵccf("app-top-menu-mobi", i4.TopMenuMobiComponent, View_TopMenuMobiComponent_Host_0, {}, {}, []);
export { TopMenuMobiComponentNgFactory as TopMenuMobiComponentNgFactory };

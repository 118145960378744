import { Component, OnInit, ViewChild } from '@angular/core';
import { MainService } from "../main.service";
import { DjangoRequestData } from "../dataDescription";
import { DomSanitizer } from "@angular/platform-browser";
import { SharedDataService } from "../shared-data.service";
import { MainMenuService } from "../main-menu.service";
import { Router } from "@angular/router";
import {RoutingStateService} from "../routing-state.service";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('profileForm') profileForm;

  days = [];
  months = [
        {id: 1, name: '01 - Январь'},
        {id: 2, name: '02 - Февраль'},
        {id: 3, name: '03 - Март'},
        {id: 4, name: '04 - Апрель'},
        {id: 5, name: '05 - Май'},
        {id: 6, name: '06 - Июнь'},
        {id: 7, name: '07 - Июль'},
        {id: 8, name: '08 - Август'},
        {id: 9, name: '09 - Сентябрь'},
        {id: 10, name: '10 - Октябрь'},
        {id: 11, name: '11 - Ноябрь'},
        {id: 12, name: '12 - Декабрь'}
  ];
  years = [];

  menu: any;
  company: any;

  client: any;
  getcard_url: any;

  bDay: any;
  bMonth: any;
  bYear: number;
  cookieService: CookieService;

  constructor(
    private mainService: MainService,
    private menuService: MainMenuService,
    private sharedData: SharedDataService,
    private rootData: DjangoRequestData,
    private sanitizer: DomSanitizer,
    private router: Router,
    private routingState: RoutingStateService
  ) { }


  range (start, stop, step): any[] {
    if (typeof stop === 'undefined') {
      // one param defined
      stop = start;
      start = 0;
    }

    if (typeof step === 'undefined') {
      step = 1;
    }

    if ((step > 0 && start >= stop) || (step < 0 && start <= stop)) {
      return [];
    }

    let result = [];
    for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
      result.push(i);
    }

    return result;
  }

  getData() {
    this.sharedData.currentData.subscribe(
      response => {
        if (response['menu']) this.menu = response['menu'];
      }
    );
    this.mainService.dataForProfile({type: 'get'})
      .subscribe(
        response => {
          this.company = response['company'];
          this.client = response['client'];
          this.client.crc = response['crc'] || '';

          if (this.client.birthday){
            this.bDay = this.client.birthday.day;
            this.bYear = this.client.birthday.year;
          }

          if (this.client.birthday && this.client.birthday.month >= 1 && this.client.birthday.month <= 12) {
            this.client.birthday.month = {
              id: this.client.birthday.month,
              name: this.months.filter( (month) => { return month.id === this.client.birthday.month })[0].name
            };
            this.bMonth = this.client.birthday.month.id;
          }

          if (typeof response['company']['pass_template_id'] !== 'undefined' && (!this.rootData.is_mobile_app || response['client']['id'] === 3)) {
            this.getcard_url = this.sanitizer.bypassSecurityTrustResourceUrl('https://app.getcard.me/api2/get-or-recreate-pass/' + response['company']['pass_template_id']);
          }

          response['title'] = "Профиль";
          response['menu'] = this.menu;
          console.log('got for profile: ', response, this.client);
          this.sharedData.changeData(response);
        },
        error => {
          console.log('error while downloading profile data: ', error);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  changeUserInfo(profileForm, isValid: boolean) {
    const emailRegExp = new RegExp("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?");
    if (!emailRegExp.test(profileForm.email)) {
      this.menuService.openDialog(
        'Ошибка!',
        'Проверьте корректность электронного адреса.',
        true
      );
      return;
    }
    if (this.client.first_name == '' || this.client.last_name == '' || this.client.email == '') {
      this.menuService.openDialog(
        'Ошибка!',
        'Все поля должны быть заполнены.',
        true
      );
      return;
    }

    this.client.birthday = {
      day: this.bDay,
      month: this.bMonth,
      year: this.bYear
    };

    if (
      this.client.birthday &&
      ( this.client.birthday.day || this.client.birthday.month || this.client.birthday.year ) ) {
      if ( !(this.client.birthday.day && this.client.birthday.month && this.client.birthday.year) ) {
        this.menuService.openDialog(
          'Ошибка!',
          'Неверная дата рождения.',
          true
        );
        return;
      }
    }
    let data = {
      'type': 'change_user_info',
      'first_name': this.client.first_name,
      'last_name': this.client.last_name,
      'email': this.client.email,
      'notify_about_orders_by_email': this.client.notify_about_orders_by_email
    };

    if (
      this.client.birthday &&
      this.client.birthday.day &&
      this.client.birthday.year &&
      this.client.birthday.month
    ) {
      data['birthday'] = {
        'day': +this.client.birthday.day,
        'month': +this.client.birthday.month,
        'year': +(this.client.birthday.year)
      };
    }

    console.log('data to send: ', data);

    this.mainService.dataForProfile(data)
      .subscribe(
        response => {
          this.menuService.openDialog(
            'Отлично!',
            'Данные сохранены.',
            false
          );
          console.log('Profile data saved successfully, response: ', response);

          if (this.routingState.getUrlHistory().length === 3) {
            this.router.navigate([this.routingState.getUrlHistory()[0]]);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          console.log('there was an error while saving data for profile!', error);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  goBack() {
    this.menuService.goBack();
  }

  ngOnInit() {
    this.days = this.range(1,32,1);
    this.years = this.range(1950, (new Date()).getFullYear() - 7,1).reverse();
    this.getData();
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./map.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./map.component";
import * as i4 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i5 from "@angular/router";
import * as i6 from "../main.service";
import * as i7 from "../shared-data.service";
import * as i8 from "../main-menu.service";
import * as i9 from "../dataDescription";
var styles_MapComponent = [i0.styles];
var RenderType_MapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
function View_MapComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "mapEl"], ["style", "width: 100% !important; height: 400px !important; display: block;"]], null, null, null, null, null))], null, null); }
function View_MapComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "map"], ["src", "assets/images/delivery_area_pic.png"], ["style", "width: 100%; max-width: 654px; !important; height: auto !important;"]], null, null, null, null, null))], null, null); }
export function View_MapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MapComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isMobileApp; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.isMobileApp; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_MapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i1.ɵdid(1, 245760, null, 0, i3.MapComponent, [i4.CookieService, i5.Router, i6.MainService, i7.SharedDataService, i8.MainMenuService, i9.DjangoRequestData], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i1.ɵccf("app-map", i3.MapComponent, View_MapComponent_Host_0, { map_title: "map_title", restaurants: "restaurants", restaurants_polygons: "restaurants_polygons" }, {}, []);
export { MapComponentNgFactory as MapComponentNgFactory };

import { Injectable } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";

@Injectable()
export class RoutingStateService {

  private history = [];

  constructor(
    private router: Router
  ) { }

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(
        ( {urlAfterRedirects}: NavigationEnd ) => {
          this.history = [...this.history, urlAfterRedirects];
        }
      );
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/';
  }

  public getUrlHistory(): string[] {
    return this.history;
  }

}

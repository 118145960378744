<div class="product-header">
    <div class="product-name">
        <span [innerHTML]="dish.name"></span>
    </div>
    <div class="product-info-icons">
      <div class="product-info-ico can-be-not-spisy" title="Острое блюдо (можно сделать не острым)" *ngIf="dish.sharpness == 'can be not spicy'"></div>
      <div class="product-info-ico spicy" title="Острое блюдо" *ngIf="dish.sharpness == 'spicy'"></div>
      <div class="product-info-ico gluten" title="Глютеносодержащее блюдо" *ngIf="dish.gluten_level == 'gluten'"></div>
      <div class="product-info-ico gluten-free" title="Безглютеновое блюдо" *ngIf="dish.gluten_level == 'gluten_free'"></div>
    </div>
    <div class="product-info">
        <div class="label label-default" *ngIf="dish.milliliters">{{ dish.milliliters }} мл</div>
        <div class="label label-default" *ngIf="dish.grams && !dish.milliliters">{{ dish.grams }} г</div>
        <div class="label label-primary" *ngIf="dish.calories">{{ dish.calories }} ккал</div>
    </div>
</div>
<div class="product-img">
    <div class="product-img-slider">
        <div *ngFor="let image of dish.images"> <!-- track by $index" ng-class="$first ? 'active': ''"> -->
            <div class="product-link">
                <div class="product-img-slider-inner"
                     [ngStyle]="{'background-image': 'url(' + image + ')'}">
                </div>
            </div>
        </div>
    </div>

</div>
<div class="product-price" *ngIf="dish['price']">
    {{ dish.price }} &#8381;
</div>
<div class="product-price" *ngIf="dish.loyalty_points_price">
    {{ dish.loyalty_points_price }} баллов
</div>

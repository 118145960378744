<div class="container">
    <ol class="breadcrumb">
        <li><a routerLink="/">Меню</a></li>
    </ol>
    <div class="primary">

        <div class="dish-list" *ngIf="categories.length > 0">
            <div class="row">
                <div class="col-lg-3 col-md-6" *ngFor="let category of categories">
                    <div class="dish-box">
                        <a routerLink="/{{category.menu.id}}-{{category.menu.slug}}/{{ category.id }}-{{ category.slug }}">
                            <span class="dish-name">
                                {{  category.name }}
                            </span>
                            <span class="dish-icon" *ngIf="category.image">
                                <img [src]="category.image" width="63" height="48" [alt]="category.name">
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <app-dishes-list></app-dishes-list>

        <a (click)="goBack()" class="back-page"> назад</a>
    </div>
</div>

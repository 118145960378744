import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {MainService} from "./main.service";
import {SharedDataService} from "./shared-data.service";
import {MatDialog, MatDialogConfig} from "@angular/material";
import {OkDialogComponent} from "./login/ok-dialog.component";
import {Location} from "@angular/common";
import {CookieService} from "ngx-cookie-service";
import {YesNoDialogComponent} from "./basket/yes-no-dialog.component";
import {BackToMenuDialogComponent} from "./dialog-hidden/back-to-menu-dialog.component";
import {BehaviorSubject, fromEvent} from "rxjs";
import {RoutingStateService} from "./routing-state.service";
import {debounceTime, distinctUntilChanged, map} from "rxjs/operators";


@Injectable()
export class MainMenuService {

  private yesNoDialog = new BehaviorSubject({ clicked: false, answer: false });
  private backToMenuDialog = new BehaviorSubject({ clicked: false, answer: false });
  yesNoDialogResult = this.yesNoDialog.asObservable();
  backToMenuDialogResult = this.backToMenuDialog.asObservable();
  dictMinute = ['минуту', 'минуты', 'минут'];
  dictSeconds = ['секунду', 'секунды', 'секунд'];

  constructor(
    private mainService: MainService,
    private sharedService: SharedDataService,
    private dialog: MatDialog,
    private router: Router,
    private location: Location,
    private cookieService: CookieService,
    private routingState: RoutingStateService,
  ) { }

// for main menu(mobi and desktop)
  logOutNow() {
    this.mainService.callToLogOut()
      .subscribe(
        response => {
          console.log('response on logout: ', response);
          this.sharedService.changeLogInStatus(false);
          response['basket_counter'] = 0;
          response['title'] = 'Меню';
          this.sharedService.changeData(response);
          this.openDialog(
            'Отлично!',
            'Вы вышли.',
            false
          );
          if (this.cookieService.get('access_token')) {
            this.cookieService.delete('access_token');
          }
          this.router.navigate(['/']);
        },
        error => {
          console.log('Error while logout: ', error);
          this.defaultErrorDialog();
        }
      );
  }

// opens dialogs in any component
  defaultErrorDialog() {
    this.dialog.open(OkDialogComponent, {
      data: {
        title: 'Ошибка!',
        body: 'Извините, что-то пошло не так.',
        closeOnNextPage: true
      }
    });
  }
  checkNameDialog() {
    const dialogRef = this.dialog.open(OkDialogComponent, {
      data: {name: 'Ошибка!', body: 'Для оформления заказа необходимо заполнить все поля профиля.'}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/профиль']);
    });
  }

  openDialog(title: string, body: string, closeOnNextPage: boolean, checkBasket?: boolean) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.closeOnNavigation = closeOnNextPage;
    dialogConfig.data = {
      title: title,
      body: body
    };

    const dialogRef = this.dialog.open(OkDialogComponent, dialogConfig);
    if (checkBasket) {
      return dialogRef.afterClosed();
    }
  }

  openYesNoDialog(title: string, body: string, yesBtn: string, noBtn: string) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: title,
      body: body,
      yesButton: yesBtn,
      noButton: noBtn
    };

    const dialogRef = this.dialog.open(YesNoDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        console.log('data from yes/no dialog: ', data);
        this.changeYesNoDialogResult({ clicked: true, answer: data });
      }
    );
  }

  changeYesNoDialogResult(data) {
    this.yesNoDialog.next(data);
  }

  openBackToMenuDialog(title: string, body: string, backButton: string) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.data = {
        title: title,
        body: body,
        backButton: backButton
      };

      this.dialog.open(BackToMenuDialogComponent, dialogConfig);
  }

// ---- for basket ----

  addToBasket(dish) {
    if (!this.checkLogin({show_info: true})) return;

    if (dish['pos_type'] === 'constructor' || dish['pos_type'] === 'group') {
      if (!dish['category']) {
        this.router.navigate([[dish.menu.id, dish.menu.slug].join('-'), [dish.id, dish.slug].join('-') + '.html']);
      } else {
        this.router.navigate([[dish.menu.id, dish.menu.slug].join('-'), [dish.category.id, dish.category.slug].join('-'),
          [dish.id, dish.slug].join('-') + '.html']);
      }
      return;
    }

    const positionId = dish['id'];
    const data = {
      'type': 'add',
      'position_id': positionId
    };
    this.mainService.changeBasketPosition(data)
      .subscribe(
        response => {
          this.sharedService.changeData(response);
          this.setEcommerce([dish], 'add');
          console.log('position added, response: ', response);
        },
        error => {
          console.log('error while adding to basket: ', error);
          this.defaultErrorDialog();
        }
      );
  }

// --- to navigate back
  goBack(): void {
    if (this.routingState.getUrlHistory().length === 1) {
      this.router.navigate([this.routingState.getPreviousUrl()]);
    }
    else {
      this.location.back();
    }
  }

  checkLogin(param: {}): boolean {
    if (!this.cookieService.get('access_token')) {
      this.router.navigate(['/вход'], {queryParams: param});
      return false;
    }
    else return true;
  }

// --- for search suggestions
  public search(param) {
    fromEvent(param, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      }),
      debounceTime(100),
      distinctUntilChanged())
      .subscribe((text: string) => {this.requestSuggestions(text); });
  }

  public requestSuggestions(query: string) {
    if (query.length >= 2) {
      this.getSuggestions(query);
      this.sharedService.changeSuggestionsStatus(true);
    }
    else this.sharedService.changeSuggestions([]);
  }

  public getSuggestions(query): void {
    const suggestions = [];
    this.mainService.requestForSuggestions( { 'q': query } )
      .subscribe(
        response => {
          response['dishes'].forEach( (dish) => {
            suggestions.push(
              {
                'display': dish.name.replace(/<[^>]*>/g, ''),
                'dish'   : dish
              }
            );
          });
          this.sharedService.changeSuggestions(suggestions);
        }, error => {
          this.sharedService.changeSuggestionsStatus(false);
          this.defaultErrorDialog();
        }
      );
  }

  public showSelectedDish(dish): void {
    if (dish['category'] != null) {
      this.router.navigate([[dish.menu.id, dish.menu.slug].join('-'), [dish.category.id, dish.category.slug].join('-'),
        [dish.id, dish.slug].join('-') + '.html']);
    }
    else {
      this.router.navigate([[dish.menu.id, dish.menu.slug].join('-'), [dish.id, dish.slug].join('-') + '.html']);
    }
  }

  public setEcommerce(dishes, type, orderNumber = null, checkoutStep = null): void {
    let body: any;
    const products = [];
    const data = {
      'ecommerce': {
        'currencyCode': 'RUB',
      },
      'event': 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-non-interaction': false
    };

    for (let i = 0; i < dishes.length; i++) {
      let category = dishes[i].menu.name;
      if (dishes[i]['category']) {
        category += '/' + dishes[i].category.name;
      }

      products.push({
        'name': dishes[i].name,
        'id': String(dishes[i].id),
        'price': dishes[i].price,
        'category': category
      });
      if (type === 'impressions') {
        products[i]['position'] = i + 1;
      } else if (type === 'purchase') {
        products[i]['quantity'] = dishes[i].quantity;
      }
    }

    body = {'products': products};

    if (type === 'add') {
      data['gtm-ee-event-action'] = 'Adding a Product to a Shopping Cart';
    } else if (type === 'remove') {
      data['gtm-ee-event-action'] = 'Removing a Product from a Shopping Cart';
    } else if (type === 'checkout') {
      body['actionField'] = {'step': checkoutStep};
      data['gtm-ee-event-action'] = 'Checkout Step ' + checkoutStep;
    } else if (type === 'purchase') {
      data['gtm-ee-event-action'] = 'Purchase';
      body['actionField'] = {'id': orderNumber};
    } else if (type === 'impressions') {
      data['gtm-ee-event-action'] = 'Product Impressions';
      data['gtm-ee-event-non-interaction'] = true;
      body = products;
    } else if (type === 'detail') {
      data['gtm-ee-event-action'] = 'Product Details';
      data['gtm-ee-event-non-interaction'] = true;
    }

    data['ecommerce'][type] = body;

    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(data);
  }

  public prepareDishesForEcommerce(bps): any[] {
    const dishesAndQuantity = [];

    for (let i = 0; i < bps.length; i++) {
      const dish = bps[i].position;
      dish['quantity'] = bps[i].quantity;
      dishesAndQuantity.push(dish);
    }

    return dishesAndQuantity;
  }

  public numToTime(num, text_forms) {
    num = Math.abs(num) % 100;
    const num2 = num % 10;
    if (num > 10 && num < 20) return text_forms[2];
    if (num2 > 1 && num2 < 5)  return text_forms[1];
    if (num2 === 1) return text_forms[0];
    return text_forms[2];
  }

  public timeOutView(differenceTime) {
    const m = Math.floor(differenceTime / 60);
    const s = differenceTime % 60;
    const differenceTimeString = (m !== 0) ? m + ' ' + this.numToTime(m, this.dictMinute) + ' ' + s + ' ' + this.numToTime(s, this.dictSeconds) : s + this.numToTime(s, this.dictSeconds);
    this.openDialog('Ошибка!',
      'Вы не можете отправлять код еще ' + differenceTimeString,
      true
    );
  }

  public confirmCodeError(this, code, phone = null) {
    if (code === 129)
      return 'Введите код!';
    else if (code === 128)
      return 'Убедитесь, что вы ввели правильный код.';
    else if (code === 127)
      return 'Нужно принять соглашение.';
    else if (code === 125) {
      return 'Вы ввели код больше трех раз, ваш код больше не действителен!';
    } else
      return 'Что-то пошло не так при подтверждении кода!';
  }

  public setDeafaultDataMap() {
    this.sharedService.changeLastAddress({city: 'Санкт-Петербург', street: '', house: '', corp: '', flat: ''});
    this.sharedService.changeRelatedStreets([]);
    this.sharedService.changeCanWeDeliver(true);
    this.sharedService.changeFinishedCheckingDeliveryPossibility(true);
  }
}

<div class="container">

    <div class="primary">
        <div class="row">
            <div class="col-md-4 col-md-offset-4">
                <div class="user-form">
                    <form id="send_phone" class="auth_form" (ngSubmit)="getPhoneCode()" name="send_phone">
                        <div class="form-group">
                           <label  class="label-md" for="phone_input" *ngIf="showInfoForLogin">
                              Чтобы оформить заказ на нашем сайте, пожалуйста, введите ваш номер телефона и проверочный код из SMS.
                            </label>
                            <label class="label-lg" for="phone_input" *ngIf="!showInfoForLogin">Введите Ваш номер телефона</label>
                            <div class="input-holder">
                                <span class="code-country">+7</span>
                                <input [disabled]="codeSent" type="tel" class="form-control" id="phone_input" [showMaskTyped]="true" [(ngModel)]="phoneInput" appAutoFocus name="phone_input" mask="(000) 000-00-00" maxlength="16">
                            </div>
                        </div>

                        <div class="form-message" [class.clear-margin-bottom]="showInfoForLogin" id="emailHelp">
                            Ваш телефон не станет известен кому-либо ещё
                            <br>
                            <section>
                                <mat-checkbox [(ngModel)]="agree" [ngModelOptions]="{standalone: true}" class="green-style"></mat-checkbox>
                                <a style="padding-left:5px;" routerLink="/policy">Согласен с условиями</a>
                            </section>
                        </div>

                        <div class="form-footer" *ngIf="!codeSent" [class.clear-margin-top]="showInfoForLogin">
                            <button type="submit" [disabled]="!agree" class="btn btn-info btn-block" id="get_code_id">ПОДТВЕРДИТЬ</button>
                        </div>
                    </form>
                    <form (ngSubmit)="confirmCode()" *ngIf="codeSent" #target>
                        <div class="form-group">
                            <label class="label-lg" for="code_input">СМС-код</label>
                            <input type="text" class="form-control" id="code_input" name="code_input" maxlength="4" [(ngModel)]="codeInput">
                            <span *ngIf="showSendAgainButton" class="send-again-link" (click)="getPhoneCode(true)">Отправить еще раз</span>
                        </div>

                        <div class="form-footer" [class.clear-margin-top]="showInfoForLogin">
                            <button type="submit" class="btn btn-info btn-block" style="text-align:center;" id="confirm_code_id">ПОДТВЕРДИТЬ</button>
                        </div>
                    </form>
                  <div class="col-12 text-center" *ngIf="showInfoForLogin">
                    <small class="info-auth">
                    <em>Регистрация по номеру телефона нужна для начисления баллов в личном кабинете.</em>
                  </small>
                  </div>
                </div>
            </div>
        </div>
    </div>

</div>

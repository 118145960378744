/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contacts.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../map/map.component.ngfactory";
import * as i3 from "../map/map.component";
import * as i4 from "ngx-cookie-service/cookie-service/cookie.service";
import * as i5 from "@angular/router";
import * as i6 from "../main.service";
import * as i7 from "../shared-data.service";
import * as i8 from "../main-menu.service";
import * as i9 from "../dataDescription";
import * as i10 from "@angular/common";
import * as i11 from "./contacts.component";
var styles_ContactsComponent = [i0.styles];
var RenderType_ContactsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContactsComponent, data: {} });
export { RenderType_ContactsComponent as RenderType_ContactsComponent };
function View_ContactsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-map", [["style", "width: 100% !important; display: block;"]], null, null, null, i2.View_MapComponent_0, i2.RenderType_MapComponent)), i1.ɵdid(2, 245760, [[1, 4], ["childMapID", 4]], 0, i3.MapComponent, [i4.CookieService, i5.Router, i6.MainService, i7.SharedDataService, i8.MainMenuService, i9.DjangoRequestData], { restaurants: [0, "restaurants"], restaurants_polygons: [1, "restaurants_polygons"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.restaurants; var currVal_1 = _co.restaurants_polygons; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ContactsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "metro-station"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "metro-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, ":svg:svg", [["height", "23"], ["width", "23"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:path", [["d", "M11.528 23.001c-6.354 0-11.529-5.119-11.529-11.444 0-6.383 5.175-11.558 11.529-11.558 6.124 0 11.473 5.175 11.473 11.558 0 6.353-5.119 11.444-11.473 11.444zm5.694-16.36c-.001-.575-.316-1.179-1.265-1.179-.718 0-1.006.317-1.322.949l-3.106 6.469H11.5L8.366 6.411c-.316-.633-.603-.949-1.322-.949-.949 0-1.266.604-1.266 1.178v10.034c0 .548.433.863.949.863.46 0 .977-.315.977-.863V9.2h.03l2.846 5.807c.202.403.461.634.921.634.459 0 .718-.231.919-.634L15.267 9.2h.029v7.474c0 .548.517.863.977.863.518 0 .949-.315.949-.863V6.641z"], ["fill-rule", "evenodd"]], [[1, "fill", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "#", (_v.context.$implicit.line_hex_color || "888"), ""); _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_1); }); }
function View_ContactsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "additional-number"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\u0414\u043E\u0431\u0430\u0432\u043E\u0447\u043D\u044B\u0439 \u043D\u043E\u043C\u0435\u0440: ", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.phone_ext; _ck(_v, 2, 0, currVal_0); }); }
function View_ContactsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "distance"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\u0420\u0435\u0441\u0442\u043E\u0440\u0430\u043D \u0432 ", " \u043A\u043C \u043E\u0442 \u0432\u0430\u0441"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.distance; _ck(_v, 2, 0, currVal_0); }); }
function View_ContactsComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [["class", "tel-link"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "icon-phone"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(3, null, ["+", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "tel:+", _v.context.$implicit, ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_1); }); }
function View_ContactsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "ul", [["class", "text-left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_3)), i1.ɵdid(3, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [["target", "_blank"]], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_4)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_5)), i1.ɵdid(11, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_6)), i1.ɵdid(13, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.metro_stations; _ck(_v, 3, 0, currVal_0); var currVal_3 = _v.context.$implicit.phone_ext; _ck(_v, 9, 0, currVal_3); var currVal_4 = _v.context.$implicit.distance; _ck(_v, 11, 0, currVal_4); var currVal_5 = _v.context.$implicit.phones; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var currVal_1 = (((((("http://maps.apple.com/?q=" + _v.context.$implicit.name) + "&ll=") + _v.context.$implicit.lat) + ",") + _v.context.$implicit.lon) + "z=10&t=s"); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.address; _ck(_v, 7, 0, currVal_2); }); }
function View_ContactsComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "insta-photo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "https://www.instagram.com/p/", _v.context.$implicit.shortcode, "/"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.thumbnail; _ck(_v, 1, 0, currVal_1); }); }
export function View_ContactsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { childMap: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 30, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 27, "div", [["class", "primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 26, "div", [["class", "order"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 25, "div", [["style", "margin-top: 10px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_1)), i1.ɵdid(6, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "carousel-main-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "top-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0415\u0434\u0438\u043D\u044B\u0439 \u043D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430: "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "a", [["class", "tel-number"]], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 0, "div", [["class", "delivery-location-icon history-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getGeo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "col-md-12 restaurant-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_2)), i1.ɵdid(18, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(19, 0, null, null, 10, "div", [["class", "insta-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 9, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 8, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "a", [], [[1, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0421\u041B\u0415\u0414\u0418\u0422\u0415 \u0417\u0410 \u041D\u0410\u041C\u0418 "])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412 INSTAGRAM"])), (_l()(), i1.ɵted(27, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ContactsComponent_7)), i1.ɵdid(29, 278528, null, 0, i10.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(30, 0, null, null, 1, "a", [["class", "back-page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u043D\u0430\u0437\u0430\u0434"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.restaurants_polygons.length != 0) || (_co.restaurants.length != 0)); _ck(_v, 6, 0, currVal_0); var currVal_3 = _co.restaurants; _ck(_v, 18, 0, currVal_3); var currVal_6 = _co.instagram_photos; _ck(_v, 29, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "tel:+", _co.single_phone, ""); _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.formatted_phone; _ck(_v, 13, 0, currVal_2); var currVal_4 = i1.ɵinlineInterpolate(1, "https://www.instagram.com/", (_co.insta_user || "cafe_naparah"), "/"); _ck(_v, 23, 0, currVal_4); var currVal_5 = (_co.insta_user || "cafe_naparah"); _ck(_v, 27, 0, currVal_5); }); }
export function View_ContactsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contacts", [], null, null, null, View_ContactsComponent_0, RenderType_ContactsComponent)), i1.ɵdid(1, 245760, null, 0, i11.ContactsComponent, [i6.MainService, i10.Location, i7.SharedDataService, i5.ActivatedRoute, i8.MainMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContactsComponentNgFactory = i1.ɵccf("app-contacts", i11.ContactsComponent, View_ContactsComponent_Host_0, {}, {}, []);
export { ContactsComponentNgFactory as ContactsComponentNgFactory };

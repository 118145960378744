import {Component, OnInit} from '@angular/core';
import {SharedDataService} from "../shared-data.service";
import {MainService} from "../main.service";
import { MainMenuService } from "../main-menu.service";

import {Location} from "@angular/common";

@Component({
  selector: 'app-terms-of-delivery',
  templateUrl: './terms-of-delivery.component.html',
  styleUrls: ['./terms-of-delivery.component.css']
})
export class TermsOfDeliveryComponent implements OnInit {

  delivery_timetable = {
    monday_start: '',
    monday_end: '',
    tuesday_start: '',
    tuesday_end: '',
    wednesday_start: '',
    wednesday_end: '',
    thursday_start: '',
    thursday_end: '',
    friday_start: '',
    friday_end: '',
    saturday_start: '',
    saturday_end: '',
    sunday_start: '',
    sunday_end: ''
  };

  delivery_min_price: any;
  sharedData: any;

  constructor(
    private menuService: MainMenuService,
    private location: Location,
    private sharedService: SharedDataService,
    private mainService: MainService,
  ) {
  }

  setData() {
    this.mainService.getCompany()
      .subscribe(response => {
        this.sharedData = response;
        this.sharedData.title = 'Условия доставки';
        this.sharedService.changeData(this.sharedData);
      }, error => {
        this.menuService.defaultErrorDialog();
      }
    );
  }

  ngOnInit() {
    this.setData();
    this.mainService.getCompany().subscribe(response => {
        this.delivery_timetable = response['company']['delivery_timetable'];
        this.delivery_min_price = response['company']['delivery_min_price'];
      });
  }

  formatTime(minutes) {
    return [String(Math.floor(minutes / 60)), String(minutes % 60)].map(value => {
      if (value.length === 1) {
        return '0' + value;
      }
      return value;
    }).join(':');
  }

  goBack(): void {
    this.menuService.goBack();
  }

}

export interface Menus {
  name: string;
  image: string;
  slug: string;
  id: number;
  bg_image: string;
}

export class Company {
  pass_template_id: string;
  ymap_zoom: number;
  policy_link: string;
  id: number;
  name: string;
  ymap_center_lat: string;
}

import { Injectable } from "@angular/core";

@Injectable()
export class DjangoRequestData {
  is_logged: boolean;
  is_mobile_app: boolean;
  is_new_design_banner_enabled: boolean;
  company_logo: string;
  company_name: string;
  app_store_id: string;
  google_play_id: string;
}

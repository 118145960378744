import { Injectable } from '@angular/core';
import * as $ from 'jquery';

declare let document: any;

interface Script {
  scr: string;
  loaded: boolean;
}

@Injectable()
export class ScriptLoaderService {
  public scripts: Script[] = [];

  loadScript(tag, src: string, loadOnce?: boolean) {
    loadOnce = loadOnce || true;

    if (!this.scripts[src]) {
      this.scripts[src] = {src: src, loaded: false};
    }

    return new Promise((resolve, reject) => {
      if (this.scripts[src].loaded && loadOnce) {
        resolve({src: src, loaded: true});
      } else {
        const scriptTag = $('<script>').attr('type', 'text/javascript').attr('src', this.scripts[src].src);
        $(tag).append(scriptTag);

        this.scripts[src] = {src: src, loaded: true};
        resolve({src: src, loaded: true});
      }
    });

  }

}

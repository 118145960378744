import {Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {SharedDataService} from "../shared-data.service";
import {Location} from "@angular/common";
import {MainService} from "../main.service";
import {MainMenuService} from "../main-menu.service";
import {environment} from '../../environments/environment';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {MapComponent} from "../map/map.component";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})

export class ContactsComponent implements OnInit, OnDestroy {
  @ViewChild('childMapID') childMap: MapComponent;
  restaurants: any[] = [];
  public company: any;
  public insta_user: any;
  public single_phone: string = '';
  public formatted_phone: string = '';
  public instagram_photos: any;
  restaurants_polygons: any[] = [];
  sharedData: any;

  constructor(
    private mainService: MainService,
    private location: Location,
    private sharedService: SharedDataService,
    private route: ActivatedRoute,
    private menuService: MainMenuService,
  ) {
  }

  phoneFormat(phone) {
    this.formatted_phone = phone.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
  }

  getRestData() {
    this.mainService.getRestaurants()
      .subscribe(
        response => {
          this.restaurants = response['restaurants'];
          this.company = response['company'];
          this.insta_user = response['company']['instagram_username'];
          if (response['company']['instagram_photos'].length > 6) {
            this.instagram_photos = response['company']['instagram_photos'].slice(0, 5);
          } else {
            this.instagram_photos = response['company']['instagram_photos'].slice(0, response['company']['instagram_photos'].length);
          }
          this.single_phone = response['company']['single_phone'];
          this.phoneFormat(this.single_phone);
          this.sharedData = response;
          this.sharedData.title = 'Контакты';
          this.sharedService.changeData(this.sharedData);
          for (let key = 0; this.restaurants.length > key; key++) {
            if (this.restaurants[key]['delivery_area_polygon'] !== null) {
              this.restaurants_polygons.push(this.restaurants[key]['delivery_area_polygon']);
            }
          }
        }, error => {
          this.menuService.defaultErrorDialog();
        }
      );
  }

  goBack(): void {
    this.menuService.goBack();
  }

  getGeo() {
    this.childMap.getClientGeo(true);
    this.restaurants = this.childMap.restaurants;
  }

  ngOnInit() {
    this.getRestData();
  }

  ngOnDestroy() {
    this.menuService.setDeafaultDataMap();
  }
}

// service for making http queries
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class MainService {
  proxy = environment.proxy; // 'https://cors-anywhere.herokuapp.com/';
  baseUrl = '/api'; // 'https://xn--80aatcn.xn--80aaa3clh0b.xn--p1ai/api'; //'/api';

  constructor(
    private http: HttpClient
  ) {
  }

  getData() {
    const url = this.proxy + this.baseUrl + '/app/menus/';
    return this.http.get(url);
  }

  getMenuFromId(id) {
    const url = this.proxy + this.baseUrl + '/app/menu/' + id + '/';
    return this.http.get(url);
  }

  getCategoryFromId(id) {
    const url = this.proxy + this.baseUrl + '/app/categories/' + id + '/';
    return this.http.get(url);
  }

  getPositionFromId(id) {
    const url = this.proxy + this.baseUrl + '/app/dish/' + id + '/';
    return this.http.get(url);
  }

  sendPhoneCodeForLogin(phone: string, sendAgain: boolean) {
    const url = this.proxy + this.baseUrl + '/auth/code/';
    const data = {'phone': phone, 'again': sendAgain};
    return this.http.post(url, data, {
      params: new HttpParams().set('unique', 'true').set('requestId', 'get-phone-code')
    });
  }

  sendToConfirmCodeForLogin(data: any) {
    const url = this.proxy + this.baseUrl + '/auth/token/';
    return this.http.post(url, data);
  }


  sendPhoneCodeForPayPoints(data) {
    const url = this.proxy + this.baseUrl + '/app/pay_points_code_send/';
    return this.http.post(url, data);
  }

  verifyPhoneCodeForPayPoints(data) {
    const url = this.proxy + this.baseUrl + '/app/pay_points_code_verify/';
    return this.http.post(url, data);
  }

  callToLogOut() {
    const url = this.proxy + this.baseUrl + '/logout/';
    return this.http.get(url);
  }

  dataForProfile(data: any) {
    const url = this.proxy + this.baseUrl + '/client/data/';
    return this.http.post(url, data);
  }

  getBasketData() {
    const url = this.proxy + this.baseUrl + '/app/basket/';
    return this.http.get(url);
  }

  changeBasketPosition(data) {
    const url = this.proxy + this.baseUrl + '/app/basket/';
    return this.http.post(url, data);
  }

  sendOrder(data) {
    const url = this.proxy + this.baseUrl + '/app/order/';
    return this.http.post(url, data);
  }

  getOrPostUserOrders(data) {
    const url = this.proxy + this.baseUrl + '/app/orders/';
    return this.http.post(url, data);
  }

  requestForSearch(data) {
    const url = this.proxy + this.baseUrl + '/app/search/';
    return this.http.post(url, data);
  }

  requestForSuggestions(data) {
    const url = this.proxy + this.baseUrl + '/app/suggestions/';
    return this.http.post(url, data);
  }

  toChangeImg(data, positionId) {
    const url = this.proxy + this.baseUrl + '/admin/dish/' + positionId + '/';
    return this.http.post(url, data);
  }

  requestForExtId(data, toDo) {
    const url = this.proxy + this.baseUrl + '/app/' + toDo + '/';
    return this.http.post(url, data);
  }

  getDeliveryStatus(data) {
    const url = this.proxy + this.baseUrl + '/app/can_deliver_to/';
    return this.http.post(url, data);
  }

  getRestaurants() {
    const url = this.proxy + this.baseUrl + '/app/basket_restaurants/';
    return this.http.get(url);
  }

  getRestaurant(id: string) {
    const url = this.proxy + this.baseUrl + '/app/restaurant/' + id + '/';
    return this.http.get(url);
  }

  getCompany() {
    const url = this.proxy + this.baseUrl + '/app/company/';
    return this.http.get(url);
  }

  getClientAdress(lat, lon) {
    const url = this.proxy + this.baseUrl + '/app/get_address/?lat=' + lat + '&lon=' + lon;
    return this.http.get(url);
  }

  getSharedBasket(pretty_link) {
    const url = this.proxy + this.baseUrl + '/app/shared_basket/?pretty_link=' + pretty_link;
    return this.http.get(url);
  }

  getSharedLink() {
    const url = this.proxy + this.baseUrl + '/app/shared_basket/';
    return this.http.post(url, null);
  }

  getStreets(data) {
    const url = this.proxy + this.baseUrl + '/app/get_streets/?query=' + data.query + '&count=' + 5;
    return this.http.get(url);
  }

  createYandexPayment(data) {
    const url = this.proxy + this.baseUrl + '/app/create_payment/';
    return this.http.post(url, data);
  }

  getSurveyByOrderNumber(orderNumber: string) {
    const url = this.proxy + this.baseUrl + '/app/survey_result/?order_number=' + orderNumber;
    return this.http.get(url);
  }

  getSurvey() {
    const url = this.proxy + this.baseUrl + '/app/survey_result/';
    return this.http.get(url);
  }

  sendSurvey(data) {
    const url = this.proxy + this.baseUrl + '/app/survey_result/';
    return this.http.post<any>(url, data);
  }

  getSurveyQuestions(){
    const url = this.proxy + this.baseUrl + '/app/survey_questions/';
    return this.http.get(url);
  }

  checkCoupon(data) {
    const url = this.proxy + this.baseUrl + '/app/check_coupon/';
    return this.http.post(url, data);
  }
}

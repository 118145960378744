import { CdkTableModule } from "@angular/cdk/table";
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import {MatOptionModule, MatProgressSpinnerModule} from "@angular/material";
import { MatDialogModule } from "@angular/material";
import { MatIconModule, MatButtonModule } from '@angular/material';
import { MatAutocompleteModule } from "@angular/material";
import { MatProgressBarModule } from '@angular/material';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { MainService } from './main.service';
import { AppRoutingModule } from './app-routing.module';
import { CookieService } from 'ngx-cookie-service';
import { AppHttpInterceptor, LoadingInterceptor, SentryErrorHandler } from "./app-http-interceptor";
import { OwlModule } from 'ngx-owl-carousel';

import { TopMenuMobiComponent } from './top-menu-mobi/top-menu-mobi.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { DjangoRequestData } from "./dataDescription";
import { SharedDataService } from './shared-data.service';
import { MenuComponent } from './menu/menu.component';
import { CategoryComponent } from './category/category.component';
import { ContactsComponent } from './contacts/contacts.component';
import { TermsOfDeliveryComponent } from './terms-of-delivery/terms-of-delivery.component';
import { DishesListComponent } from './dishes-list/dishes-list.component';
import { DishComponent } from './dish/dish.component';
import { PositionComponent } from './position/position.component';
import { LoginComponent } from './login/login.component';
import { OkDialogComponent } from './login/ok-dialog.component';
import { MainMenuService } from './main-menu.service';
import { ProfileComponent } from './profile/profile.component';
import { BasketComponent } from './basket/basket.component';
import { YesNoDialogComponent } from './basket/yes-no-dialog.component';
import { BackToMenuDialogComponent } from './dialog-hidden/back-to-menu-dialog.component';
import { OrdersComponent } from './orders/orders.component';
import { RoutingStateService } from './routing-state.service';
import { SearchComponent } from './search/search.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { NgxMaskModule } from 'ngx-mask';
import { PolicyComponent } from './policy/policy.component';
import { RestaurantDetailsComponent } from "./restaurantDetails/restaurantDetails.component";
import { SafePipe } from "./_pipes/safe.pipe";
import { ReplacePipe } from './_pipes/replace.pipe';

import { NumbersOnlyDirective } from "./_directives/numbers-only.directive";
import { LettersOnlyDirective } from "./_directives/letters-only.directive";
import { AutofocusDirective } from "./_directives/autofocus.directive";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MetrikaModule } from 'ng-yandex-metrika';
import { AuthGuardService } from "./auth-guard.service";
import { SharedProductsListComponent } from "./shared-products-list/shared-products-list.component";
import { ProductsCartsComponent } from './products-carts/products-carts.component';
import { SurveyComponent } from './survey/survey.component';
import { DeliveryComponent } from "./delivery/delivery.component";
import { MapComponent } from "./map/map.component";
import * as Sentry from "@sentry/browser";

if (environment.dsn) {
  Sentry.init({dsn: environment.dsn, environment: environment.dsn_environment});
}
// export class RavenErrorHandler implements ErrorHandler {
//   handleError(err:any) : void {
//     Raven.captureException(err);
//   }
// }

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    TopMenuMobiComponent,
    TopMenuComponent,
    MenuComponent,
    CategoryComponent,
    ContactsComponent,
    TermsOfDeliveryComponent,
    DishesListComponent,
    DishComponent,
    PositionComponent,
    LoginComponent,
    OkDialogComponent,
    ProfileComponent,
    BasketComponent,
    YesNoDialogComponent,
    BackToMenuDialogComponent,
    OrdersComponent,
    SearchComponent,
    PolicyComponent,
    NumbersOnlyDirective,
    LettersOnlyDirective,
    AutofocusDirective,
    RestaurantDetailsComponent,
    SafePipe,
    ReplacePipe,
    SharedProductsListComponent,
    ProductsCartsComponent,
    SurveyComponent,
    DeliveryComponent,
    MapComponent
  ],
  imports: [
    BrowserModule,
    OwlModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDialogModule,
    CdkTableModule,
    MatSelectModule,
    MatInputModule,
    MatOptionModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
    MatIconModule,
    MatButtonModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MetrikaModule.forRoot({id: environment.metrika, webvisor: true, ecommerce: "dataLayer"}),
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }) // TODO
  ],
  providers: [
    AuthGuardService,
    MainService,
    SharedDataService,
    DjangoRequestData,
    Title,
    CookieService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    MainMenuService,
    RoutingStateService,
    ScriptLoaderService,
    // { provide: ErrorHandler, useClass: RavenErrorHandler }
  ],
  bootstrap: [AppComponent],
  entryComponents: [OkDialogComponent, YesNoDialogComponent, BackToMenuDialogComponent]
})
export class AppModule { }

import { throwError as observableThrowError, Observable } from '../../node_modules/rxjs';
import { ErrorHandler, Injectable, Injector } from "@angular/core";
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType} from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import {catchError, filter, finalize} from "rxjs/operators";
import { SharedDataService } from "./shared-data.service";
import * as Sentry from "@sentry/browser";
import { environment } from "../environments/environment";


@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  constructor( private cookieService: CookieService ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = this.cookieService.get('csrftoken'); // 'access_token=' + this.cookieService.get('access_token');

    const authReq = req.clone({withCredentials: true, headers: req.headers.set('X-CSRFToken', token)});

    return next.handle(authReq).pipe(catchError((error, caugth) => {
      console.log('Error occurred: ', error);
      return observableThrowError(error);
    })) as any;
  }
}

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  constructor ( private sharedDataService: SharedDataService ){}

  private loadingStarted() {
    setTimeout(() => {this.sharedDataService.isButtonDisabled.next(this.sharedDataService.isButtonDisabled.value + 1); });
  }

  private loadingFinished() {
    setTimeout(() => {this.sharedDataService.isButtonDisabled.next(this.sharedDataService.isButtonDisabled.value - 1); });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingStarted();
    return next.handle(request).pipe(
      filter(event => event.type === HttpEventType.Response),
      finalize(() => this.loadingFinished())
    );
  }
}

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor(
    private sharedDataService: SharedDataService
  ) {}

  setUserInSentryScope() {
    this.sharedDataService.currentData.subscribe(data => {
      if (typeof data['client'] !== 'undefined') {
        Sentry.configureScope(scope => {
          scope.setUser({
            id: data['client']['id'],
            username: `${data['client']['first_name']} ${data['client']['last_name']}`,
            email: data['client']['email']
          });
        });
      } else {
        Sentry.configureScope(scope => {
          scope.clear();
        });
      }
    });
  }

  handleError(error) {
    this.setUserInSentryScope();
    const eventId = Sentry.captureException(error.originalError || error);
    if (!environment.production)
      Sentry.showReportDialog({ eventId });
  }
}

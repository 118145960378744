import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

import { MainService } from '../main.service';
import { DjangoRequestData, Menus } from '../dataDescription';
import { SharedDataService } from "../shared-data.service";
import { Router } from "@angular/router";
import { MainMenuService } from "../main-menu.service";


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  menus: Menus[];
  sharedData: any;
  suggestions = [];
  searchTerm: string;
  isLoading: boolean;
  @ViewChild('searchDish') searchDish: ElementRef;
  constructor(
    private mainService: MainService,
    private sharedService: SharedDataService,
    private router: Router,
    private menuService: MainMenuService,
    private rootData: DjangoRequestData
  ) {
  }

  private getMainData(): void {
    this.mainService.getData()
      .subscribe(
        response => {
          this.menus = response['menus'];
          this.sharedData = response;
          this.sharedData.title = "Меню";
          this.sharedData.is_mobile_app = !!window.navigator.userAgent.match(/naparah/i);
          this.sharedData.app_store_id = response['company']['app_store_id'];
          this.sharedData.google_play_id = response['company']['google_play_id'];
          this.sharedData.company_logo = response['company']['logo'];
          this.sharedData.company_name = response['company']['name'];
          this.sharedData.is_logged = !!response['client'];
          this.sharedService.changeData(this.sharedData);
        },
        error => {
          console.log('Menu was not loaded!!! error: ', error);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  trackMenus(index, menu) {
    return menu ? menu.id : undefined;
  }

  makeSearch() {
    this.router.navigate(['/поиск', this.searchTerm]);
  }

  public onSelect(dish) {
    this.searchTerm = '';
    this.sharedService.changeSearchTerm('');
    this.sharedService.changeSuggestions([]);
    this.menuService.showSelectedDish(dish);
  }

  ngOnInit() {
    this.getMainData();
    this.menuService.search(this.searchDish.nativeElement);
    this.sharedService.currentSuggestions.subscribe(data => this.suggestions = data);
    this.sharedService.currentSearchTerm.subscribe(term => this.searchTerm = term);
    this.sharedService.currentSuggestionsStatus.subscribe(isLoading => this.isLoading = isLoading);
  }
}

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Injectable} from "@angular/core";
import {SharedDataService} from "./shared-data.service";
import {Observable} from "rxjs";
import {MainMenuService} from "./main-menu.service";
import {map} from "rxjs/operators";

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(private sharedData: SharedDataService,
              private menuService: MainMenuService,
              private router: Router
              ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.sharedData.currentLogInStatus.pipe(map(isLoginStatus => {
      if (isLoginStatus) {
        return true;
      } else {
        this.menuService.openDialog(
          'Ошибка!',
          'Сначала необходимо авторизоваться',
          false
        );
        this.router.navigate(['/вход']);
        return false;
      }
    }))
    }
}

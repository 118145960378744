import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { SharedDataService } from './shared-data.service';
import { MainMenuService } from './main-menu.service';
import { RoutingStateService } from './routing-state.service';
import { filter } from 'rxjs/operators';
import { MainService } from './main.service';
import { ScriptLoaderService } from './_services/script-loader.service';
import { DjangoRequestData } from "./dataDescription";
import { Observable } from "../../node_modules/rxjs";
import { CookieService } from "ngx-cookie-service";

declare const SmartBanner: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  isButtonDisabled: boolean = false;
  companyLogo: string;
  companyName: string;
  mobileApp: boolean;
  mobileAppType: string = '';
  mobileAppLink: string;
  app_store_id: string;
  google_play_id: string;

  title_tag: string;
  /* <title>*/
  mainData: any;
  menu: any;
  company: any;
  title: string;
  /* для showBgBar */
  basketCounter: number;
  justAddedToBasket = false;
  searchIsVisible = true;
  searchTerm: string;

  showBgBar = false;
  showFlyBasket = false;
  showAlertBanner = true;
  showNewDesignBanner = false;

  mobiMenuVisible = false;
  public bgImage: Observable<string>;

  public constructor(
    private sharedData: SharedDataService,
    private titleService: Title,
    private router: Router,
    private menuService: MainMenuService,
    private routingState: RoutingStateService,
    private mainService: MainService,
    private scriptService: ScriptLoaderService,
    private cdRef: ChangeDetectorRef,
    private rootData: DjangoRequestData,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
    this.routingState.loadRouting();
    this.sharedData.isDisab.subscribe(data => this.isButtonDisabled = data);
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  burgerClick() {
    this.mobiMenuVisible = !this.mobiMenuVisible;
    this.sharedData.changeMenuMobiStatus(this.mobiMenuVisible);
  }

  saveUtmToCookie() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        let utm = {};
        const utm_source = params['utm_source'];
        if (typeof utm_source !== "undefined") {
          utm['utm_source'] = utm_source;
        }
        const utm_medium = params['utm_medium'];
        if (typeof utm_medium !== "undefined") {
          utm['utm_medium'] = utm_medium;
        }
        const utm_campaign = params['utm_campaign'];
        if (typeof utm_campaign !== "undefined") {
          utm['utm_campaign'] = utm_campaign;
        }
        const utm_term = params['utm_term'];
        if (typeof utm_term !== "undefined") {
          utm['utm_term'] = utm_term;
        }
        if (Object.keys(utm).length !== 0){
          this.cookieService.set('utm', JSON.stringify(utm), 30, '/',  '.' + location.hostname.replace(/^.+\.([^\.]+\.[^\.]+)$/, '$1'));
        }
      });
  }

  ngOnInit() {
    this.title_tag = 'На Парах';

    this.sharedData.currentMenuMobiStatus.subscribe(status => this.mobiMenuVisible = status);
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.rootData.is_mobile_app = !!userAgent.match(/naparah/i);
    this.mobileApp = this.rootData.is_mobile_app;
    if (this.mobileApp && (userAgent.indexOf('iphone10') > -1 || userAgent.indexOf('iphone11') > -1 || userAgent.indexOf('iphone12') > -1)) {
      document.body.className = 'mobile-style';
    }

    this.bgImage = this.sharedData.currentBgImage;

    this.mainService.getData().subscribe(data => {
      this.mainData = data;
      if (this.mainData['menu']) this.menu = this.mainData['menu'];
      if (this.mainData['title']) this.title = this.mainData['title'];
      this.setTitle(this.title_tag + ' | ' + this.title);
      this.company = data['company'];
      this.rootData = {
        is_mobile_app: this.mobileApp,
        app_store_id: data['company']['app_store_id'],
        google_play_id: data['company']['google_play_id'],
        company_logo: data['company']['logo'],
        company_name: data['company']['name'],
        is_new_design_banner_enabled: data['company']['is_new_design_banner_enabled'],
        is_logged: !!data['client'],
      };
      this.app_store_id = this.rootData.app_store_id;
      this.google_play_id = this.rootData.google_play_id;
      this.companyLogo = this.rootData.company_logo;
      this.companyName = this.rootData.company_name;
      this.showNewDesignBanner = this.rootData.is_new_design_banner_enabled;
      if (!this.mobileApp && this.mobileApp !== undefined) {
        this.smartAppBanner();
      }
      this.mobileAppType = this.getMobileAppType(userAgent);
      this.mobileAppLink = this.mobileApp && this.getMobileAppLink() || this.company.visible_root_domain_link_website2;
      this.sharedData.changeData(this.mainData);
    }, error => {
        this.menuService.defaultErrorDialog();
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const url = this.router.url;
        let show = true;

        ['корзина', 'position', 'выход'].forEach((value) => {
          if (url.indexOf(value) > -1) show = false;
        });
        if (url === '/') show = false;
        this.showBgBar = show;

        show = true;
        ['заказы', 'корзина'].forEach((value) => {
          if (url.indexOf(value) > -1) show = false;
        });
        this.showFlyBasket = show;

        if (url.indexOf('search') > -1) {
          this.searchTerm = '';
          this.sharedData.changeSearchTerm('');
          this.searchIsVisible = false;
        }
        else this.searchIsVisible = true;
      });

    this.scriptService.loadScript('app-root', 'assets/js/scroll-to-top.js');
    this.sharedData.currentData.subscribe(data => {
        this.mainData = data;
        if (this.mainData['menu']) this.menu = this.mainData['menu'];
        if (this.mainData['title']) this.title = this.mainData['title'];
        this.setTitle(this.title_tag + ' | ' + this.title);

        if (data['basket_counter'] !== undefined) {
          console.log('basket counter in app.component: ', data['basket_counter']);
          if (this.basketCounter !== undefined && this.basketCounter < data['basket_counter']) {
            this.justAddedToBasket = true;
            console.log('position just added!!!');
            setTimeout(() => {
              this.justAddedToBasket = false;
            }, 3000);
          }
          this.basketCounter = data['basket_counter'];
        }
      }, error => {
        this.menuService.defaultErrorDialog();
      }
    );

    if (this.title === 'Авторизация') {
      this.cdRef.detectChanges();
    }
    this.saveUtmToCookie();
    if (this.mobileApp) {
      document.body.style.position = 'fixed';
    }
  }

  smartAppBanner() {
    const androidMeta = document.createElement('meta');
    androidMeta.setAttribute('name', 'google-play-app');
    androidMeta.setAttribute('content', 'app-id=' + this.google_play_id);
    document.getElementsByTagName('head')[0].appendChild(androidMeta);
    const appleMeta = document.createElement('meta');
    appleMeta.setAttribute('name', 'apple-itunes-app');
    appleMeta.setAttribute('content', 'app-id=' + this.app_store_id.toString());
    document.getElementsByTagName('head')[0].appendChild(appleMeta);

    const smartAppBanner = new SmartBanner({
      daysHidden: 15,
      daysReminder: 90,
      appStoreLanguage: 'ru',
      title: 'На Парах',
      author: 'ООО На Парах',
      button: 'VIEW',
      store: {
        ios: 'В App Store',
        android: 'В Google Play'
      },
      price: {
        ios: 'FREE',
        android: 'FREE'
      }
    });
  }
  getMobileAppType(userAgent: string) {
    if (!!userAgent.match(/ios/i)) {
      return 'ios';
    }
    if (!!userAgent.match(/android/i)) {
      return 'android';
    }
  }
  getMobileAppLink() {
    if (this.mobileAppType === 'ios') {
      return 'https://apps.apple.com/ru/app/id' + this.app_store_id;
    }
    if (this.mobileAppType === 'android') {
      return 'https://play.google.com/store/apps/details?id=' + this.google_play_id;
    }
  }
  closeAlertBanner() {
    document.body.style.position = 'inherit';
    this.showAlertBanner = false;
  }
}

<h2 mat-dialog-title>{{ data.title }}</h2>

<mat-dialog-content>
  {{ data.body }}
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button id="no-button" (click)="noClose()">{{ data.noButton }}</button>
  <button mat-raised-button id="yes-button" (click)="yesClose()" tabindex="-1" cdkFocusInitial>{{ data.yesButton }}</button>
</mat-dialog-actions>

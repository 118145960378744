import {Component, OnDestroy, OnInit} from '@angular/core';
import {MainService} from "../main.service";
import {Location} from "@angular/common";
import {SharedDataService} from "../shared-data.service";
import {Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {MainMenuService} from "../main-menu.service";
import {Subscription} from "rxjs";


@Component({
  selector: 'app-restaurant-details',
  templateUrl: './restaurantDetails.component.html',
  styleUrls: ['./restaurantDetails.component.css']
})
export class RestaurantDetailsComponent implements OnInit, OnDestroy {
  mySlideImages;
  mySlideOptions = {items: 1, dots: true, nav: true,   navText: ['<image style="position: absolute; left: 0; bottom: 50%;" width="50" height="50" class="left-arrow" src="assets/images/left-arrow.png">', '<image style="position: absolute; right: 0; bottom: 50%;" width="50" height="50" src="assets/images/right-arrow.png">']};
  restaurant: any[] = [];
  restaurants_polygons: any[] = [];
  sharedData;
  restaurantId = this.location.path().split('/')[2];

  constructor(
    private mainService: MainService,
    private location: Location,
    private sharedService: SharedDataService,
    private route: Router,
    public sanitizer: DomSanitizer,
    private menuService: MainMenuService
  ) {
  }

    getRestData() {
    this.mainService.getRestaurant(this.restaurantId)
      .subscribe(
        response => {
          this.restaurant.push(response['restaurant']);
          this.sharedData = response;
          this.sharedData.title = this.restaurant[0].name;
          this.sharedService.changeData(this.sharedData);
          this.mySlideImages = this.restaurant[0].images.map(imageUrl => imageUrl);
          for (const key in this.restaurant) {
            if (this.restaurant[key]['delivery_area_polygon'] != null) {
              this.restaurants_polygons.push(this.restaurant[key]['delivery_area_polygon']);
            }
          }
        }, error => {
          this.menuService.defaultErrorDialog();
        }
      );
  }

  ngOnInit() {
    this.getRestData();
  }

  ngOnDestroy() {
    this.menuService.setDeafaultDataMap();
  }

  goBack(): void {
    this.menuService.goBack();
  }

  restaurants(): void {
    this.route.navigate(['/контакты']);
  }

}

import { Component, OnInit,  ViewChild } from '@angular/core';
import { Location } from "@angular/common";
import { CookieService } from 'ngx-cookie-service';

import { SharedDataService } from "../shared-data.service";
import { MainService } from "../main.service";
import { MainMenuService } from "../main-menu.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  inProgress = false;
  codeSent = false;
  agree = true;
  phoneInput: string;
  codeInput: string;
  showSendAgainButton = true;

  isHandled = false;

  sharedData: any;

  countAttempts = 3;

  showInfoForLogin = false;

  @ViewChild('target') code_input;

  constructor(
    private sharedService: SharedDataService,
    private mainService: MainService,
    private location: Location,
    private cookieService: CookieService,
    private menuService: MainMenuService,
    private router: Router,
    private activateRoutes: ActivatedRoute
  ) {
  }

  getPhoneCode(again = false) {
    if (this.inProgress) {
      console.log('Trying to invoke getPhoneCode 2nd time!');
      return;
    }

    if (again) {
      this.showSendAgainButton = false;
    }

    this.inProgress = true;
    const phone = this.phoneInput;

    if (!/^[19]\d{9}$/.test(phone) || phone === '9211231212') {
      /*Show dialog with message  */
      this.openDialog(
        'Ошибка!',
        'Пример правильного номера - 9211231212',
        true
      );
      this.inProgress = false;
      return false;
    }

    this.mainService.sendPhoneCodeForLogin(phone, again)
      .subscribe(
        (response) => {
          console.log('code sent successefully! response: ', response);
          this.inProgress = false;
          this.codeSent = true;
          let that = this;
          if (!again)
            this.codeInput = null;
          setTimeout(function () {
              that.code_input.nativeElement.getElementsByTagName("input")[0].focus();
          }, 10);
        },
        (err) => {
          console.log('code sent with error!!! error: ', err);
          if (err['status'] === 423) {
            this.openDialog(
              'Ошибка!',
              'Вы пытаетесь получить смс-код слишком часто! Подождите немного.',
              true
            );
          }
          else if (err['error']['error']['code'] === 13) {
            this.countAttempts = 0;
            this.codeSent = false;
            this.menuService.timeOutView(err['error']['error']['description']);
          }
          else this.openDialog(
            'Ошибка!',
            'Что-то пошло не так, попробуйте ещё раз!',
            true
          );
          this.inProgress = false;
        }
      );

  }

  confirmCode() {

    if (this.inProgress) {
      console.log('Trying to invoke confirmCode() 2nd time!');
      return;
    }
    if (this.codeInput === '' || typeof(this.codeInput) === "undefined") {
       this.openDialog('Ошибка!',
            'Введите код!',
            true
      );
      return;
    }
    const data = {
      'code': this.codeInput,
      'phone': this.phoneInput,
      'type': 'client',
      'policy': this.agree
    };

    this.inProgress = true;

    this.mainService.sendToConfirmCodeForLogin(data)
      .subscribe(
        (response) => {
          if (this.isHandled) {
            console.log('got code confirmation success 2nd time!!!');
            return;
          }

          this.isHandled = true;
          console.log('code confirmed, response is: ', response);

          if (response['need_user_data']) {
            this.menuService.openDialog(
              'Отлично!',
              'Введите ваши данные',
              false
            );
            this.router.navigate(['/профиль']);
            this.sharedService.changeLogInStatus(true);
          }
          else {
            /*if (!this.cookieService.get('access_token'))
              this.cookieService.set('access_token', response['access_token']);*/

            this.sharedService.changeLogInStatus(true);

            this.openDialog(
              'Отлично!',
              'Вы авторизовались.',
              false
            );
            this.inProgress = false;
            /*      Доделать!!!!
                  if ($window.isMobileApp) {
                    $window.postMessage($cookies.get('access_token'), $window.location.hostname);
                  }
             */
            this.menuService.goBack();
          }
        },
        (error) => {
          this.inProgress = false;
          if (this.isHandled) {
            return;
          }
          if (error && error['error']) {
            this.openDialog('Ошибка!',
              this.menuService.confirmCodeError(error['error']['error']['code'], '+7' + this.phoneInput),
              true
            );
            if (error['error']['error']['code'] === 128) {
              this.countAttempts--;
            }
            else if (error['error']['error']['code'] === 125) {
              this.countAttempts--;
              this.codeSent = false;
            }
          }
        }
      );

  }

  changeTitle() {
    this.sharedService.currentData.subscribe(data => {
      this.sharedData = data;
      this.sharedData.title = 'Авторизация';
    });

  }

  openDialog(title: string, body: string, closeOnNextPage: boolean): void {
    this.menuService.openDialog(title, body, closeOnNextPage);
  }

  checkLoginAndRedirect(): void {
    if (this.cookieService.get('access_token')) {
      this.openDialog(
        'Отлично!',
        'Вы уже вошли в систему ранее.',
        false
      );
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {
    this.checkLoginAndRedirect();
    this.changeTitle();
    if (this.sharedData && this.sharedData.title === 'Авторизация') {
      this.sharedService.changeData(this.sharedData);
    }
    if (typeof this.activateRoutes.snapshot.queryParams['show_info'] !== 'undefined' && this.activateRoutes.snapshot.queryParams['show_info'] === 'true') {
      this.showInfoForLogin = true;
    }
  }

}

<div class="container">

  <form class="form-search search-page" (ngSubmit)="makeSearch()">
    <mat-form-field floatLabel="never">
      <input #searchDish matInput type="search" [(ngModel)]="searchTerm" name="search" placeholder="ПОИСК"
             [matAutocomplete]="auto" class="search-label">
      <i class="search-clear" mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear"
              (click)="searchTerm=''">
        <mat-icon>close</mat-icon>
      </i>
      <mat-autocomplete #auto="matAutocomplete" minlength="2">
        <mat-option *ngFor="let option of suggestions" (click)="onSelect(option.dish)" (onSelectionChange)="onSelect(option.dish)" [value]="option.display">
          <span>{{ option.display }}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
  </form>

  <div *ngIf="dishes.length == 0">
    <div class="empty-box">
      <div>Ничего не нашлось</div>
    </div>
  </div>

  <div class="primary" *ngIf="dishes.length > 0">
    <app-dishes-list></app-dishes-list>
  </div>
</div>

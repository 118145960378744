<div class="container">
  <ol class="breadcrumb">
    <li class="pointer"><a (click)="restaurants()">Контакты</a></li>
  </ol>
  <div class="primary">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="order">
          <div style="margin-top: 10px">
            <div class=" restaurant-section">
              <div class="row" *ngIf="restaurant[0] !== undefined">

                <div class="col-xs-12">
                  <h1 class="fn org">{{restaurant[0].name}}</h1>
                </div>
                <div class="col-xs-12 restaurant-holder">
                  <ul class="restaurant-list">
                    <li *ngFor="let metro of restaurant[0].metros">
                      <i class="icon-location"></i>
                      {{metro}}
                    </li>
                    <li *ngFor="let phone of restaurant[0].phones">
                      <i class="icon-phone"></i>
                      <a href="tel:+{{phone}}">
                        <div class="tel">+{{phone}}</div>
                      </a>
                    </li>
                    <li>
                      <i class="icon-location"></i>
                      <address class="adr">
                        <a href="http://maps.apple.com/?q={{restaurant[0].name}}&sll={{ restaurant[0].lat }},{{ restaurant[0].lon }}z=10&t=s" target="_blank">
                          <div class="street-address">{{restaurant[0].address}}</div>
                        </a>
                      </address>
                    </li>
                  </ul>
                </div>
                <div class="col-md-12 col-xs-12">
                  <app-map *ngIf="restaurants_polygons.length!=0 || restaurant.length!=0" [restaurants]="restaurant" [restaurants_polygons]="restaurants_polygons" #childMap></app-map>
                </div>
                <div style="padding-top: 40px;" class="col-md-12 col-xs-12" *ngIf="mySlideImages.length !== 0">
                  <div class="order-title">Фотографии ресторана</div>
                  <owl-carousel [options]="mySlideOptions" [items]="images" [carouselClasses]="['owl-theme', 'sliding']">
                    <div class="item" *ngFor="let image of mySlideImages;let i = index">
                      <div class="thumbnail-image" [ngStyle]="{'background': 'url(' + image + ')no-repeat scroll center center / cover'}"></div>
                    </div>
                  </owl-carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a (click)="goBack()" class="back-page"> назад</a>

</div>

import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MainService } from '../main.service';
import { Component, OnInit } from '@angular/core';
import { MainMenuService } from '../main-menu.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedDataService } from '../shared-data.service';
import { ImageCompressService, ResizeOptions, IImage } from 'ng2-image-compress';


@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent implements OnInit {
  sharedData: any;
  questions: any = {};

  rawQuestions: [] = [];

  client = null;

  orderIdentifierWrite = null;

  restaurants = null;
  selectedRest = null;

  selectedTime = null;
  selectedDate = null;
  dateNow: Date;
  minDateSurvey: Date;

  firstName = null;
  lastName = null;
  phoneInput = null;

  questionNumberWithFile;
  uploadFile: any = [];
  maxDataSizeToUpload: number;
  imagesFullSize = 0;

  orderIdentifier: any;

  isErrorValidForm: boolean;
  isShowAdditionalInfo = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mainService: MainService,
    private sharedService: SharedDataService,
    private menuService: MainMenuService
  ) {  }

  setData() {
    let result;
    if (this.orderIdentifier === null) {
      result = this.mainService.getSurvey();
      this.isShowAdditionalInfo = true;
    } else {
      result = this.mainService.getSurveyByOrderNumber(this.orderIdentifier);
    }

    result
      .subscribe(response => {
        this.sharedData = response;
        this.maxDataSizeToUpload = response['data_upload_max_memory_size'];
        this.restaurants = response['restaurants'] || null;
        this.client = response['client'] || null;

        if (this.selectedRest !== null && !this.checkValidRest()) {
            this.selectedRest = null;
            this.showErrorWithoutScroll('Ресторан не найден');
          }

        if (this.client !== null) {
            this.phoneInput = this.client.phone.slice(1, );
            this.lastName = this.client.last_name;
            this.firstName = this.client.first_name;
          }
        if (response['date_now']) {
            this.dateNow = new Date (response['date_now']);
            this.minDateSurvey = new Date (response['date_now']);
            this.minDateSurvey.setFullYear(this.minDateSurvey.getFullYear() - 1);
          }

        this.sharedData.title = 'Отзыв по заказу';
        this.sharedService.changeData(this.sharedData);
        }, error => {
            if (error['status'] === 400 || error['status'] === 404) {
              this.menuService.openDialog(
                'Ошибка!',
                error['error']['error']['description'],
                true
              );
              this.router.navigate(['/заказы.html']);
          } else {
            this.menuService.defaultErrorDialog();
            this.router.navigate(['/заказы.html']);
          }
        }
      );
  }

  getSurveyQuestions() {
    this.mainService.getSurveyQuestions()
      .pipe(
        catchError( (error: HttpErrorResponse) => {
          return throwError(error.error);
        })
      )
      .subscribe(
        response => {
          this.rawQuestions = response['result'];
          this.rawQuestions.map(question => {
            if (question['question_type'] === 'open_ended') {
              this.questions[question['sort_order']] = [question['question'], ''];
            } else {
              if (question['with_option']) {
                this.questions[question['sort_order']] = [question['question'], '', ''];
              } else {
                this.questions[question['sort_order']] = [question['question'], ''];
              }
            }
          });
          this.questionNumberWithFile  = this.rawQuestions[Object.keys(this.rawQuestions).length - 1]['sort_order'] + 1;
        });
  }

  deleteFile(index) {
    const img = this.uploadFile.splice(index, 1)[0];
    if (img && img.image_base64) { this.imagesFullSize -= img.image_base64.length; }
  }

  uploadImg(files) {
    let err = false;
    const option: ResizeOptions = new ResizeOptions();
    option.Resize_Quality = 800;

    if (files && files.length > 0 && files.length <= 3) {
      const images: Array<IImage> = [];
      ImageCompressService.filesToCompressedImageSourceEx(files, option).then(observableImages => {
        observableImages.subscribe(
          (image) => {
            images.push(image);
          }, (error) => {
            this.menuService.openDialog(
                  'Ошибка!',
                  'Не удалось сжать изображение.',
                  true
            );
          }, () => {
            for (let i = 0; images && i < images.length; i++) {
              const image = images[i];
              if (image['type'] !== 'image/jpeg' && image['type'] !== 'image/png') {
                err = true;
                continue;
              }
              let image_base64 = image.imageDataUrl;
              if (image_base64 && image_base64.length > (this.maxDataSizeToUpload / 3 - 1000)) {
                image_base64 = image.compressedImage.imageDataUrl;
              }
              const data = {
                image_base64,
                image_name: image.fileName
              };

              if (image_base64) { this.imagesFullSize += image_base64.length; }
              this.uploadFile.push(data);
              if (this.uploadFile && this.uploadFile.length > 3) {
                const img = this.uploadFile.shift();
                if (img && img.image_base64) { this.imagesFullSize -= img.image_base64.length; }
              }

              if (this.imagesFullSize > this.maxDataSizeToUpload - 3000) {
                this.menuService.openDialog(
                  'Ошибка!',
                  'Не удалось загрузить все изображения: слишком большой размер файлов.',
                  true
                );
                const img = this.uploadFile.pop();
                if (img && img.image_base64) { this.imagesFullSize -= img.image_base64.length; }
                return;
              }
            }
            if (err) {
              this.menuService.openDialog(
                'Ошибка!',
                'Можно прикреплять только фотографии.',
                true
              );
            }
          }
        );
      });

    } else {
      this.menuService.openDialog(
        'Ошибка!',
        'Максимальное количество фото для загрузки — 3.',
        true
      );
      return;
    }
  }

  scrollTo(el: Element): void {
   if (el) { el.parentNode.parentElement.scrollIntoView(); }
  }

  scrollToInput(input_id): void {
    const input = document.querySelector('#' + input_id);
    this.scrollTo(input);
  }

  scrollToError(): void {
       const firstElementWithError = document.querySelectorAll('.ng-invalid')[1];
       this.scrollTo(firstElementWithError);
  }

  isLastAnswer(question, index) {
    return question.length === index;
  }

  isInputHasError(idInput) {
    const input = document.getElementById(idInput);
    if (input) {
      return input.classList.contains('ng-invalid') && this.isErrorValidForm;
    }
    return false;
  }

  sendSurvey(isValid: boolean) {

    let errorText = '';
    if (!isValid) {
      errorText = `Пожалуйста, дайте ответ на вопросы с пометкой *.`;

      if (this.orderIdentifier === null) {
        if (this.selectedRest === null) { errorText = 'Пожалуйста, выберите посещённый ресторан.'; }
        if (this.selectedTime === null || this.selectedTime === '') { errorText = 'Пожалуйста, выберите время посещения ресторана.'; }
        if (this.selectedDate === null) { errorText = 'Пожалуйста, выберите дату посещения ресторана.'; }
        if (this.phoneInput === null || this.phoneInput === '') { errorText = 'Пожалуйста, заполните поле номера телефона.'; }
        if (this.lastName === null) { errorText = 'Пожалуйста, заполните вашу фамилию.'; }
        if (this.firstName === null) { errorText = 'Пожалуйста, заполните ваше имя.'; }
      }

      this.showSurveyErrorMessage(errorText);
      return;
    }

    if (!this.checkValidDate() && this.orderIdentifier === null) {
      const dateNow = new Date(this.selectedDate);
      if (this.dateNow < dateNow) {  errorText = 'Введите корректную дату.'; }
      if (this.minDateSurvey > dateNow) {  errorText = 'Оставить отзыв можно только по заказу, сделанному в течение года.'; }
      this.showSurveyErrorMessage(errorText, 'date_input');
      return;
    }

    if (this.checkValidPhone() && this.orderIdentifier === null) {
      errorText = 'Пример правильного номера - 9211231212.';
      this.showSurveyErrorMessage(errorText, 'phone_input');
      return;
    }

    this.isErrorValidForm = false;
    const formattedQuestions = Object.values(this.questions).map((question: Array<string>) => {
      if (question.length !== 2) {
        question = question.filter(el => el !== 'comments');
      }
      if (question.length >= 3) {
        question = question.slice(0, 2);
      }
      return question;
    });

    let dateTime = null;
    if (this.selectedDate !== null && this.selectedTime !== null) {
       dateTime = Date.parse(this.selectedDate + ' ' + this.selectedTime) / 1000;
    }

    let data: any = {
      type: 'by_order',
      order: this.orderIdentifier,
      result: formattedQuestions,
      images: this.uploadFile,
    };

    if (this.client === null && data.order === null) {
      data = {
        ...data,
        phone: '7' + this.phoneInput,
        first_name: this.firstName,
        last_name: this.lastName,
      };
    }

    if (data.order === null) {
      data = {
        ...data,
        type: 'not_by_order',
        dtm: dateTime,
        restaurant: this.selectedRest,
        order_number: this.orderIdentifierWrite || null
      };
    }
    this.mainService.sendSurvey(data)
      .subscribe(
        response => {
          this.menuService.openDialog(
            'Отлично!',
            'Спасибо за отзыв!',
            true
          );
          if (response.type_survey === 'by_order') {
            this.router.navigate(['/заказы.html']);
          } else {
            this.router.navigate(['/']);
          }
        },
        error => {
          if (error['status'] === 400 || error['status'] === 404 || error['status'] === 500) {
            this.menuService.openDialog(
              'Ошибка!',
              error['error']['error']['description'],
              true
            );
            if (error['status'] !== 500) {
              this.router.navigate(['/заказы.html']);
            }
          } else {
            this.menuService.defaultErrorDialog();
            this.router.navigate(['/']);
          }
        }
      );
  }

  dateToString(data: Date) {
    if (data === undefined) {
      return null;
    }
    const year = data.getFullYear();
    let month = (data.getMonth() + 1).toString();
    if (Number(month) < 10) { month = '0' + month; }
    const day = data.getDate();
    return year + '-' + month + '-' + day;
  }

  checkValidDate() {
    const dateNow: Date = new Date(this.selectedDate);
    return dateNow <= this.dateNow && dateNow >= this.minDateSurvey;
  }

  checkValidPhone() {
   return !/^[19]\d{9}$/.test(this.phoneInput);
  }

  checkValidRest() {
    for (let i = 0; i < this.restaurants.length; i++) {
      if (this.selectedRest === this.restaurants[i].id) {
        return true;
      }
    }
    return false;
  }

  showSurveyErrorMessage(errorText: string, id_error: string = null) {
    this.isErrorValidForm = true;
    if (id_error === null) {
      this.scrollToError();
    } else {
      this.scrollToInput(id_error);
    }

    this.menuService.openDialog(
      'Ошибка!',
      errorText,
      true
    );
  }

  showErrorWithoutScroll(errorText) {
    this.menuService.openDialog(
      'Ошибка!',
      errorText,
      true
    );
  }

  goBack() {
    this.menuService.goBack();
  }

  test() {
    console.log(1);
    this.selectedRest = 11;
  }

  ngOnInit() {
    if (this.route.snapshot.paramMap.get('orderIdentifier') !== null) {
       if (!this.menuService.checkLogin({show_info: true})) { return; }
    }
    if (this.route.snapshot.paramMap.get('restaurant')) {
      console.log(this.route.snapshot.paramMap.get('restaurant'));
      this.selectedRest = this.route.snapshot.paramMap.get('restaurant');
    }
    this.orderIdentifier = this.route.snapshot.paramMap.get('orderIdentifier');
    this.route.queryParams.subscribe(queryParam => {
      if (queryParam['restaurant']) {
        this.selectedRest = Number(queryParam['restaurant']);
      }
    });

    this.setData();
    this.getSurveyQuestions();
  }
}

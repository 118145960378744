<div class="catalog" *ngIf="dishes.length > 0">

    <div class="catalog-header">
        <!--<div class="catalog-icon">
            <i class="icon-salad"></i>
        </div>
        -->
        <div class="catalog-title" *ngIf="category">
            {{ category.name }}
            <!-- <% counter %> -->
        </div>
    </div>

    <ul class="catalog-list">
        <li *ngFor="let dish of dishes">

            <div class="product" id="{{ dish.id }}">
                <div class="product-wrap">
                    <a routerLink="{{ dish.id }}-{{ dish.slug }}.html" class="product-box" *ngIf="dish.category == null">
                        <app-dish [dish]="dish"></app-dish>
                    </a>
                    <a routerLink="{{ dish.id }}-{{ dish.slug }}.html" class="product-box" *ngIf="dish.category != null">
                        <app-dish [dish]="dish"></app-dish>
                    </a>
                    <div class="product-action" [class.product-action-without-price]="!(dish['price'] || dish.loyalty_points_price)">
                        <!--
                        <a class="product-btn remove-from-cart" href="#">
                            <i class="icon-minus"></i>
                        </a>-->

                        <a class="product-btn add-to-cart order-button"
                            id="link_{{ dish.id }}" (click)="toBasket(dish)">
                            <i class="icon-plus"></i>
                        </a>
                    </div>
                </div>
                <div *ngIf="showDescription">{{ dish.description }}</div> <!-- разобраться с showDescription -->
            </div>

        </li>
    </ul>




</div><!--.catalog-->


import { Component, OnInit } from '@angular/core';
import { SharedDataService } from "../shared-data.service";
import { DjangoRequestData } from "../dataDescription";
import {MainMenuService} from "../main-menu.service";

@Component({
  selector: 'app-top-menu-mobi',
  templateUrl: './top-menu-mobi.component.html',
  styleUrls: ['./top-menu-mobi.component.css']
})
export class TopMenuMobiComponent implements OnInit {

  mobiMenuVisible: boolean;
  isLogged: boolean;

  constructor(
    private sharedData: SharedDataService,
    private requestData: DjangoRequestData,
    private sharedService: SharedDataService,
    private menuService: MainMenuService
  ) { }

  mobiMenuOverlayClick() {
    this.mobiMenuVisible = !this.mobiMenuVisible;
    this.sharedData.changeMenuMobiStatus(this.mobiMenuVisible);
  }

 logOut() {
    this.menuService.openYesNoDialog('Вы действительно хотите выйти?', 'Подтверждение выхода', 'Да', 'Отмена');
    const subscription = this.menuService.yesNoDialogResult.subscribe(
      data => {
        if (data['clicked']) {
          if (data['answer']) {
            this.menuService.logOutNow();
          }
          this.menuService.changeYesNoDialogResult({clicked: false, answer: false});
          subscription.unsubscribe();
        }
      }
    );
  }


  ngOnInit() {
    this.sharedData.currentMenuMobiStatus.subscribe(status => this.mobiMenuVisible = status);
    this.sharedService.currentLogInStatus.subscribe( status => this.isLogged = status );
    }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { MainService } from "../main.service";
import { Location } from "@angular/common";
import { SharedDataService } from "../shared-data.service";
import { MainMenuService } from "../main-menu.service";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  company: any;
  dishes = [];
  categories = [];
  menu: any;
  sharedData: any;

  constructor(
    private mainService: MainService,
    private route: ActivatedRoute,
    private location: Location,
    private sharedService: SharedDataService,
    private menuService: MainMenuService
  ) { }

  getMenu (): void {
    const id = this.route.snapshot.paramMap.get('menuId-:slug').split('-')[0];
    this.mainService.getMenuFromId(id)
      .subscribe((response) => {

          this.menu = response['menu'];
          this.company = response['company'];
          this.categories = response['categories'];
          this.dishes = response['dishes'];

          this.sharedData = response;
          this.sharedData.title = response['menu']['name'];
          this.sharedService.changeData(this.sharedData);
        }, error => {
          if (error.status === 423) {
            if (error.error.error.code === 221 || error.error.error.code === 222) {
              this.menuService.openBackToMenuDialog(
                  'Ошибка!',
                  'Выбранное меню недоступно, вы можете заказать блюда из другого меню',
                  'Перейти к списку меню');
            }
          }
          else
            this.menuService.defaultErrorDialog();
        }
      );
  }

  goBack(): void {
    this.menuService.goBack();
  }

  ngOnInit() {
    this.getMenu();
  }

}

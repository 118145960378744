import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharedDataService } from "../shared-data.service";
import {MainMenuService} from "../main-menu.service";

@Component({
  selector: 'app-dishes-list',
  templateUrl: './dishes-list.component.html',
  styleUrls: ['./dishes-list.component.css']
})
export class DishesListComponent implements OnInit, OnDestroy {

  dishes = [];
  category: any;
  subscription: any;

  constructor(
    private sharedData: SharedDataService,
    private menuService: MainMenuService
  ) { }

  toBasket(dish) {
    this.menuService.addToBasket(dish);
  }

  ngOnInit() {
    this.subscription = this.sharedData.currentData.subscribe( data => {
      if (data['dishes']) this.dishes = data['dishes'];
      if (data['category']) this.category = data['category'];
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainComponent } from "./main/main.component";
import { MenuComponent } from "./menu/menu.component";
import { CategoryComponent } from "./category/category.component";
import { ContactsComponent } from "./contacts/contacts.component";
import { TermsOfDeliveryComponent } from "./terms-of-delivery/terms-of-delivery.component";
import { PositionComponent } from "./position/position.component";
import { LoginComponent } from "./login/login.component";
import { ProfileComponent } from "./profile/profile.component";
import { BasketComponent } from "./basket/basket.component";
import { OrdersComponent } from "./orders/orders.component";
import { SearchComponent } from "./search/search.component";
import { PolicyComponent } from './policy/policy.component';
import { RestaurantDetailsComponent } from "./restaurantDetails/restaurantDetails.component";
import { UrlSegment } from "@angular/router";
import { AuthGuardService } from "./auth-guard.service";
import { SharedProductsListComponent } from "./shared-products-list/shared-products-list.component";
import { SurveyComponent } from "./survey/survey.component";
import { DeliveryComponent } from "./delivery/delivery.component";

export function position(url: UrlSegment[]) {
  return url[1].path.endsWith('.html') ? ({consumed: url}) : null;
}

const routes: Routes = [
  {path: '', pathMatch: 'full', component: MainComponent},
  {path: 'рестораны/:id', component: RestaurantDetailsComponent},
  {path: 'вход', component: LoginComponent},
  {path: 'профиль', component: ProfileComponent, canActivate: [AuthGuardService]},
  {path: 'корзина', component: BasketComponent, canActivate: [AuthGuardService]},
  {path: 'контакты', component: ContactsComponent},
  {path: 'условия_доставки', component: TermsOfDeliveryComponent},
  {path: 'проверка-доставки', component: DeliveryComponent},
  {path: 'заказы.html', component: OrdersComponent, canActivate: [AuthGuardService]},
  {path: 'поиск/:q', component: SearchComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'отзыв/:orderIdentifier', component: SurveyComponent},
  {path: 'отзыв', component: SurveyComponent},
  {path: 's/:pretty_link', component: SharedProductsListComponent},
  {path: ':menuId-:slug', component: MenuComponent},
  {matcher: position, component: PositionComponent},
  {path: ':menuId-:menuSlug/:categoryId-:categorySlug', component: CategoryComponent},
  {path: ':menuId-:menuSlug/:categoryId-:categorySlug/:positionId-:positionSlug', component: PositionComponent},
  {path: '**', component: MainComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}

<div class="container">
  <div class="row div-questions">
    <div class="col-md-8 col-md-offset-2">
      <div class="back">
        <form #surveyForm="ngForm" class="form-question" name="send_survey" (ngSubmit)="sendSurvey(surveyForm.valid)">
          <div *ngIf="isShowAdditionalInfo">
            <div class="div-question">
              <div class="title-question">Номер заказа</div>
              <div class="form-group">
                <input placeholder="Номер заказа" type="text" class="form-control" [(ngModel)]="orderIdentifierWrite" name="orderIdentifierWrite">
              </div>
            </div>
            <div class="underline-div-question"></div>
            <div class="div-question">
              <div class="title-question">Имя и фамилия</div>
              <div class="form-group">
                <input [class.error-input]="firstNameInput.errors && isErrorValidForm" #firstNameInput="ngModel" placeholder="Имя" type="text" class="data-time fio-input" name="firstName" [(ngModel)]="firstName" [disabled]="client !== null" required>
                <input [class.error-input]="lastNameInput.errors && isErrorValidForm" #lastNameInput="ngModel" placeholder="Фамилия" type="text" class="data-time fio-input" name="lastName" [(ngModel)]="lastName" [disabled]="client !== null" required>
              </div>
            </div>
            <div class="underline-div-question"></div>
            <div class="div-question">
              <div class="title-question">Телефон</div>
              <div class="form-group">
                <span class="code-country">+7</span>
                <input [class.error-input]="checkValidPhone() && isErrorValidForm" type="tel" class="data-time" id="phone_input" [(ngModel)]="phoneInput" [disabled]="client !== null" [showMaskTyped]="true" required name="phoneInput" mask="(000) 000-00-00" maxlength="16">
              </div>
            </div>
            <div class="underline-div-question"></div>
            <div class="div-question">
              <div class="title-question">Дата и время посещения</div>
              <div class="form-group">
                <input [class.error-input]="!checkValidDate() && isErrorValidForm" class="data-time" id="date_input" name="selectedDate" [(ngModel)]="selectedDate" type="date" required [max]="dateToString(dateNow)" [min]="dateToString(minDateSurvey)">
                <input [class.error-input]="(selectedTime === null || selectedTime === '') && isErrorValidForm" class="data-time" name="selectedTime" [(ngModel)]="selectedTime" type="time" required>
              </div>
            </div>
            <div class="underline-div-question"></div>
             <div class="div-question" *ngIf="restaurants !== null">
              <div class="title-question">Ресторан</div>
              <div class="form-group">
                <mat-radio-group class="text-choose" name="selectedRest" [(ngModel)]="selectedRest" required>
                  <mat-radio-button *ngFor="let restaurant of restaurants" [checked]="selectedRest === restaurant.id" [value]="restaurant.id">{{ restaurant.name }}</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>

            <div class="underline-div-question" *ngIf="restaurants !== null"></div>
          </div>
          <!-- Начало блока с  rawQuestions -->
          <ng-container *ngIf="rawQuestions.length > 1 && questions">
            <ng-container *ngFor="let question of rawQuestions;">
              <div class="div-question">
                <div class="title-question"><span *ngIf="question['is_mandatory']">*</span>{{ question['sort_order'] }}. {{ question['question'] }}</div>
                <div class="div-choose">
                  <!-- Если вопрос с смайликами -->
                  <mat-radio-group [required]="question['is_mandatory']" class="md-no-underline" *ngIf="question['question_type'] === 'rating'" [name]="question['sort_order']" [(ngModel)]="questions[question['sort_order']][1]">
                    <div class="form-group form-inline smile-choose">
                      <mat-radio-button value="😞"><img width="27" height="27" src="assets/images/bad.png" alt="bad"></mat-radio-button>
                      <mat-radio-button value="😐"><img width="27" height="27" src="assets/images/normal.png" alt="normal"></mat-radio-button>
                      <mat-radio-button value="😊"><img width="27" height="27" src="assets/images/good.png" alt="good"></mat-radio-button>
                    </div>
                    <div class="form-group form-inline" *ngIf="question['with_option']">
                      <mat-radio-button value="comments">Другое (укажите)</mat-radio-button>
                    </div>
                  </mat-radio-group>

                  <!-- Если вопрос с ответами -->
                  <mat-radio-group [required]="question['is_mandatory']" class="md-no-underline" *ngIf="question['question_type'] === 'multiple'" [name]="question['sort_order']" [(ngModel)]="questions[question['sort_order']][1]">
                    <div class="form-group text-choose">
                      <ng-container *ngFor="let answer of question['answers']; let indexQuestion = index;">
                        <mat-radio-button [value]="answer" *ngIf="question['with_option'] && !isLastAnswer(question['answers'], indexQuestion+1)">{{ answer }}</mat-radio-button>
                      </ng-container>
                      <mat-radio-button value="comments" *ngIf="question['with_option']">Другое (укажите)</mat-radio-button>
                    </div>
                  </mat-radio-group>

                  <!-- Если вопрос с открытым ответом -->
                  <div class="form-group" *ngIf="question['question_type'] === 'open_ended'">
                    <input [required]="question['is_mandatory']" placeholder="Комментарий" type="text" class="form-control" [name]="question['sort_order']" [(ngModel)]="questions[question['sort_order']][1]">
                  </div>
                </div>

                <!-- Альтернативный ответ для вопроса с выбором ответа -->
                <div class="form-group" *ngIf="question['question_type'] !== 'open_ended' && question['with_option']">
                  <input [id]="question['sort_order'] + 'comments'"
                         [name]="question['sort_order'] + 'comments'"
                         [class.error-input]="isInputHasError(question['sort_order'] + 'comments')"
                         type="text" class="form-control"
                         [placeholder]="questions[question['sort_order']][1] === 'comments' && question['is_mandatory']
                         ? 'Комментарий *' : 'Комментарий'"
                         [disabled]="questions[question['sort_order']][1] !== 'comments'"
                         [required]="questions[question['sort_order']][1] === 'comments' && question['is_mandatory']"
                         [(ngModel)]="questions[question['sort_order']][2]">
                </div>
              </div>
              <div class="underline-div-question"></div>
            </ng-container>
          </ng-container>
          <!-- Конец блока с  rawQuestions -->
          <div class="div-question">
            <div class="title-question">{{questionNumberWithFile}}. Фотографии, если необходимо (до трех штук)</div>
            <div class="form-group btn-wrap row">
              <div class="col-sm-6 file-btn-div" >
              <input accept="image/jpeg,image/png" multiple="multiple" name="addImage" id="addImage" type="file" style="display:none;"
                     (change)="uploadImg($event.target.files)" #imageInput/>
              <button class="btn btn-file" type="button"
                      (click)="imageInput.click()">
                ВЫБЕРИТЕ ФАЙЛ
              </button>
              </div>
              <div class="file-name col-sm-6" >
                <span class="file-name-text" *ngFor="let file of uploadFile; let i = index;"><div class="check-file"></div><div title="отменить загрузку файла" class="delete-file" id="{{ i }}" (click)="deleteFile(i);"></div><span class="image-name">{{ file['image_name'] }}</span></span>
              </div>
            </div>
          </div>
          <div class="underline-div-question"></div>
          <div class="div-question">
            <div class="form-group btn-wrap send-survey">
              <button type="submit" id="sendSurveyResults" class="btn btn-info">ГОТОВО</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <a (click)="goBack()" class="back-page"> назад</a>
</div>

<div class="mobi-menu" [class.active]="mobiMenuVisible" (click)="mobiMenuOverlayClick()">
    <ul>
        <li>
            <a routerLink="/">
                <span class="menu-icon"><i class="icon-menu"></i></span>
                <span>МЕНЮ</span>
            </a>
        </li>
        <li style="display: none;">
            <a routerLink="/">
                <span class="menu-icon"><i class="icon-cutlery"></i></span>
                <span>РЕСТОРАНЫ</span>
            </a>
        </li>
        <div *ngIf="isLogged">
            <li>
                <a routerLink="/корзина">
                    <span class="menu-icon"><i class="icon-busket"></i></span>
                    <span>КОРЗИНА</span>
                </a>
            </li>
            <li id="ordersLink-mobi">
                <a routerLink="/заказы.html">
                    <span class="menu-icon"><i class="icon-order"></i></span>
                    <span>ЗАКАЗЫ</span>
                </a>
            </li>
            <li id="profileLink-mobi">
                <a routerLink="/профиль">
                <span class="menu-icon">
                  <i class="icon-icon"></i></span>
                    <span>ПРОФИЛЬ</span></a>
            </li>

            <li id="logoutLink-mobi" *ngIf="isLogged" class="ng-hide" (click)="logOut()"><a aria-expanded="true" aria-haspopup="true"  data-toggle="dropdown" href="#" role="button" > <span class="menu-icon"><i class="icon-logout"></i></span>
                    <span>ВЫХОД</span> </a>
            </li>
        </div>

        <li id="loginLink-mobi" *ngIf="!isLogged">
            <a routerLink="/вход"> <span class="menu-icon"><i class="icon-logout"></i></span>
                <span>ВХОД</span></a>
        </li>

    </ul>
</div>

<div class="mobi-menu-overlay" [class.active]="mobiMenuVisible" (click)="mobiMenuOverlayClick()"></div>

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { SharedDataService } from "../shared-data.service";

@Component({
  selector: 'app-products-carts',
  templateUrl: './products-carts.component.html',
  styleUrls: ['./products-carts.component.css']
})
export class ProductsCartsComponent implements OnInit {

  @Output() bpOperationOutput = new EventEmitter();
  @Output() deleteOutput = new EventEmitter();

  basket_positions = [];
  subscription: any;
  showControls = false;
  price_total: any;
  calories_total: any;
  client: any;
  loyalty_points_price_total: any;
  currentUrl = location.href;

  constructor(
    private sharedData: SharedDataService
  ) { }

  bpOperation(bp, operation) {
    this.bpOperationOutput.emit([bp, operation]);
  }

  delete(bp) {
    this.deleteOutput.emit(bp);
  }

  ngOnInit() {
    this.sharedData.currentData.subscribe(
      gotData => {
        this.basket_positions = gotData['basket_positions'];
        if (gotData['showControls']) this.showControls = gotData['showControls'];
      }
    );
  }
}

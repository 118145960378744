/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dishes-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../dish/dish.component.ngfactory";
import * as i5 from "../dish/dish.component";
import * as i6 from "./dishes-list.component";
import * as i7 from "../shared-data.service";
import * as i8 from "../main-menu.service";
var styles_DishesListComponent = [i0.styles];
var RenderType_DishesListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DishesListComponent, data: {} });
export { RenderType_DishesListComponent as RenderType_DishesListComponent };
function View_DishesListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "catalog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.category.name; _ck(_v, 1, 0, currVal_0); }); }
function View_DishesListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "product-box"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dish", [], null, null, null, i4.View_DishComponent_0, i4.RenderType_DishComponent)), i1.ɵdid(3, 114688, null, 0, i5.DishComponent, [], { dish: [0, "dish"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(2, "", _v.parent.context.$implicit.id, "-", _v.parent.context.$implicit.slug, ".html"); _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DishesListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "product-box"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dish", [], null, null, null, i4.View_DishComponent_0, i4.RenderType_DishComponent)), i1.ɵdid(3, 114688, null, 0, i5.DishComponent, [], { dish: [0, "dish"] }, null)], function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(2, "", _v.parent.context.$implicit.id, "-", _v.parent.context.$implicit.slug, ".html"); _ck(_v, 1, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DishesListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_DishesListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "product"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "product-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DishesListComponent_4)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DishesListComponent_5)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "product-action"]], [[2, "product-action-without-price", null]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["class", "product-btn add-to-cart order-button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toBasket(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "icon-plus"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DishesListComponent_6)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_v.context.$implicit.category == null); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.category != null); _ck(_v, 6, 0, currVal_2); var currVal_5 = _co.showDescription; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.id, ""); _ck(_v, 1, 0, currVal_0); var currVal_3 = !(_v.context.$implicit["price"] || _v.context.$implicit.loyalty_points_price); _ck(_v, 7, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "link_", _v.context.$implicit.id, ""); _ck(_v, 8, 0, currVal_4); }); }
function View_DishesListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "catalog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "catalog-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DishesListComponent_2)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [["class", "catalog-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DishesListComponent_3)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.category; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.dishes; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_DishesListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DishesListComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.dishes.length > 0); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DishesListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dishes-list", [], null, null, null, View_DishesListComponent_0, RenderType_DishesListComponent)), i1.ɵdid(1, 245760, null, 0, i6.DishesListComponent, [i7.SharedDataService, i8.MainMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DishesListComponentNgFactory = i1.ɵccf("app-dishes-list", i6.DishesListComponent, View_DishesListComponent_Host_0, {}, {}, []);
export { DishesListComponentNgFactory as DishesListComponentNgFactory };

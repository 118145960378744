/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yes-no-dialog.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./yes-no-dialog.component";
var styles_YesNoDialogComponent = [i0.styles];
var RenderType_YesNoDialogComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_YesNoDialogComponent, data: {} });
export { RenderType_YesNoDialogComponent as RenderType_YesNoDialogComponent };
export function View_YesNoDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(5, null, [" ", "\n"])), (_l()(), i1.ɵeld(6, 0, null, null, 7, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["id", "no-button"], ["mat-raised-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.noClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(10, 0, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["id", "yes-button"], ["mat-raised-button", ""], ["tabindex", "-1"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.yesClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(12, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(13, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.data.body; _ck(_v, 5, 0, currVal_2); var currVal_3 = (i1.ɵnov(_v, 9).disabled || null); var currVal_4 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = _co.data.noButton; _ck(_v, 10, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 12).disabled || null); var currVal_7 = (i1.ɵnov(_v, 12)._animationMode === "NoopAnimations"); _ck(_v, 11, 0, currVal_6, currVal_7); var currVal_8 = _co.data.yesButton; _ck(_v, 13, 0, currVal_8); }); }
export function View_YesNoDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yes-no-dialog", [], null, null, null, View_YesNoDialogComponent_0, RenderType_YesNoDialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.YesNoDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YesNoDialogComponentNgFactory = i1.ɵccf("app-yes-no-dialog", i8.YesNoDialogComponent, View_YesNoDialogComponent_Host_0, {}, {}, []);
export { YesNoDialogComponentNgFactory as YesNoDialogComponentNgFactory };

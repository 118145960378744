import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { map } from "rxjs/operators";

@Injectable()
export class SharedDataService {

  isButtonDisabled = new BehaviorSubject(0)
  isDisab = this.isButtonDisabled.asObservable().pipe(map(it => !!it));

  private menuMobiStatus = new BehaviorSubject<boolean>(false);
  currentMenuMobiStatus = this.menuMobiStatus.asObservable();

  private logInStatus = new BehaviorSubject<boolean>(!!this.cookieService.get('access_token'));
  currentLogInStatus = this.logInStatus.asObservable();

  private sharedData = new BehaviorSubject({title: 'Меню'});
  currentData = this.sharedData.asObservable();

  private suggestionsStatus = new BehaviorSubject<any[]>([]);
  currentSuggestions = this.suggestionsStatus.asObservable();

  private searchTearmStatus = new BehaviorSubject<string>('');
  currentSearchTerm = this.searchTearmStatus.asObservable();

  private loadingSuggestionsStatus = new BehaviorSubject<boolean>(false);
  currentSuggestionsStatus = this.loadingSuggestionsStatus.asObservable();

  private bgImage = new BehaviorSubject<string>('');
  currentBgImage = this.bgImage.asObservable();

  private last_address = new BehaviorSubject<any>({city: 'Санкт-Петербург', street: '', house: '', corp: '', flat: ''});
  currentLastAddress = this.last_address.asObservable();

  private canWeDeliver = new BehaviorSubject<boolean>(true);
  currentCanWeDeliver = this.canWeDeliver.asObservable();

  private finishedCheckingDeliveryPossibility = new BehaviorSubject<boolean>(true);
  currentFinishedCheckingDeliveryPossibility = this.finishedCheckingDeliveryPossibility.asObservable();

  private relatedStreets = new BehaviorSubject<[]>([]);
  currentRelatedStreets = this.relatedStreets.asObservable();

  constructor(
    private cookieService: CookieService
  ) {
  }

  changeMenuMobiStatus(status: boolean) {
    this.menuMobiStatus.next(status);
  }

  changeData(data) {
    this.changeBgImage(data);
    this.sharedData.next(data);
  }

  changeLogInStatus(status: boolean) {
    this.logInStatus.next(status);
  }

  changeSuggestions(suggestions: any[]) {
    this.suggestionsStatus.next(suggestions);
    this.changeSuggestionsStatus(false);
  }

  changeSearchTerm(term) {
    this.searchTearmStatus.next(term);
  }

  changeSuggestionsStatus(status: boolean) {
    this.loadingSuggestionsStatus.next(status);
  }

  changeBgImage(data) {
    if ((data.company || data.menu) && data.title) {
      const bgImage = (
        (data.title !== 'Авторизация' && data.title !== 'Заказы') &&
        data.menu !== undefined && data.menu.bg_image) ?
        data.menu.bg_image : (data.company && data.company.default_bg_image ? data.company.default_bg_image : null);
      this.bgImage.next('url(' + bgImage + ')');
    }
  }

  changeLastAddress(last_address: any) {
    this.last_address.next(last_address);
  }

  changeCanWeDeliver(canWeDeliver: boolean) {
    this.canWeDeliver.next(canWeDeliver);
  }

  changeFinishedCheckingDeliveryPossibility(finishedCheckingDeliveryPossibility: boolean) {
    this.finishedCheckingDeliveryPossibility.next(finishedCheckingDeliveryPossibility);
  }

  changeRelatedStreets(relatedStreets: []) {
    this.relatedStreets.next(relatedStreets);
  }
}

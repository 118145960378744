import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {CookieService} from "ngx-cookie-service";
import {Router} from "@angular/router";
import {MainService} from "../main.service";
import {SharedDataService} from "../shared-data.service";
import {MainMenuService} from "../main-menu.service";
import {DjangoRequestData} from "../dataDescription";
import {fromEvent, Subscription} from "rxjs";
import {keyframes} from "@angular/animations";
import * as cloneDeep from 'lodash/cloneDeep';
import {debounceTime, map} from "rxjs/operators";

declare var ymaps: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnDestroy {

  company: any;
  address_points = [];
  deliveryImpossibilityReason: string;
  markerCollection: ymaps.GeoObjectCollection;
  polygonCollection: ymaps.GeoObjectCollection;
  restaurantCollection = ymaps.GeoObjectCollection;

  canWeDeliver;
  finishedCheckingDeliveryPossibility;
  isMobileApp: boolean;

  isAddressChanged = false;
  last_address;
  related;
  isGeoStart = false;
  map: ymaps.Map;
  triggerEvent;
  subscriptions: Subscription[] = [];

  @Input() map_title: string;
  @Input() restaurants: any[] = [];
  @Input() restaurants_polygons: any[] = [];
  constructor(
    private cookies: CookieService,
    private router: Router,
    private mainService: MainService,
    private sharedData: SharedDataService,
    private menuService: MainMenuService,
    private rootData: DjangoRequestData,
  ) {
  }

  getMapData() {
    this.mainService.getRestaurants().subscribe(
      response => {
        this.company = response['company'];
        this.ymapsInit();
      }
    );
  }

  ymapsInit() {
    ymaps.ready(() => {
      this.polygonCollection = new ymaps.GeoObjectCollection({});
      this.restaurantCollection = new ymaps.GeoObjectCollection({});
      this.markerCollection = new ymaps.GeoObjectCollection({});
      this.initMap();
    });
  }

  onFocusInputAddress() {
    this.sharedData.changeCanWeDeliver(true);
  }

  renderLastAddress() {
    if (this.isMobileApp) {
      return;
    }
    const last_address = this.last_address.city + ", " + this.last_address.street + ", " +
      this.last_address.house + "к" + this.last_address.corp + ", " + this.last_address.flat;
    ymaps.geocode(last_address, {results: 1})
      .then(
        (res) => {
          const firstGeoObject = res.geoObjects.get(0);
          this.address_points = [{
            geometry: {
              type: 'Point',
              coordinates: firstGeoObject.geometry.getCoordinates()
            },
            properties: {
              hintContent: 'Вы'
            }
          }];
          if (this.map && this.address_points.length > 0) {
            this.markerCollection.removeAll();
            const myLocation = new ymaps.GeoObject(this.address_points[0]);
            this.markerCollection.add(myLocation);
            this.map.geoObjects.add(this.markerCollection);
          }
          console.log('address points: ', this.address_points);
        },
        (err) => {
          console.log('error while geocode request: ', err.message);
        }
      );
  }

  searchStreet(param) {
    this.sharedData.changeCanWeDeliver(true);
    if (this.triggerEvent) {
      return;
    }
    this.triggerEvent = fromEvent(param, 'input').pipe(
      map((event: any) => {
        if (!this.isGeoStart) {
          return event;
        }
      }),
      debounceTime(300))
      .subscribe((event) => { this.requestStreetsSuggestions(event.target.value, event.inputType); });
  }

  public requestStreetsSuggestions(query, typeAct = 'textInput') {
    this.isAddressChanged = true;
    const data = {query: query, count: 5};
    if (query.length > 3) {
      this.sharedData.changeRelatedStreets([]);
      this.mainService.getStreets(data).subscribe(
        (response: any) => {
          this.sharedData.changeRelatedStreets(response.streets);
          if (this.related.length === 1 && typeAct !== 'deleteContentBackward' && typeAct !== 'deleteContentForward') {
            this.onSelect(this.related[0]);
          } else {
            this.deliveryImpossibilityReason = 'Убедитесь, что ввели правильный адрес';
            this.sharedData.changeCanWeDeliver(false);
          }
        }
      );
    } else {
       this.deliveryImpossibilityReason = 'Убедитесь, что ввели правильный адрес';
       this.sharedData.changeCanWeDeliver(false);
       this.sharedData.changeRelatedStreets([]);
    }
  }

  checkIfWeCanDeliverOrder() {
    this.sharedData.changeFinishedCheckingDeliveryPossibility(false);
    if ((!this.last_address.street || !this.last_address.house) && this.isAddressChanged) {
      if (!this.last_address.house && this.last_address.street)
        this.deliveryImpossibilityReason = 'Введите номер дома';
      else this.deliveryImpossibilityReason = 'Убедитесь, что ввели правильный адрес';
      this.sharedData.changeCanWeDeliver(false);
      this.sharedData.changeFinishedCheckingDeliveryPossibility(true);
      return;
    }
    const data = {
      'address': {
        city: this.last_address.city,
        street: this.last_address.street,
        house: this.last_address.house,
        corp: this.last_address.corp
      }
    };
    this.mainService.getDeliveryStatus(data)
      .subscribe(
        response => {
          console.log('delivery status response: ', response);
          this.sharedData.changeCanWeDeliver(!!response['result']);
          this.sharedData.changeFinishedCheckingDeliveryPossibility(true);
          this.deliveryImpossibilityReason = this.canWeDeliver ? '' : response['reason'];
          this.renderLastAddress();
        },
        error => {
          console.log('error while checking delivery status: ', error);
          this.deliveryImpossibilityReason = error['reason'];
          this.sharedData.changeCanWeDeliver(false);
          this.sharedData.changeFinishedCheckingDeliveryPossibility(true);
          this.menuService.defaultErrorDialog();
        }
      );
  }

  initMap() {
    if (this.isMobileApp) {
      return;
    }
    this.map = new ymaps.Map('mapEl', {
      center: [this.company['ymap_center_lat'], this.company['ymap_center_lon']],
      zoom: 10
    });
    const tmpRestaurantsPolygons = cloneDeep(this.restaurants_polygons);
    if (tmpRestaurantsPolygons.length > 0) {
      for (let i = 0; i < tmpRestaurantsPolygons.length; i++) {
        for (let c = 0; c < tmpRestaurantsPolygons[i].geometry.coordinates.length; c++) {
          for (let row = 0; row < tmpRestaurantsPolygons[i].geometry.coordinates[c].length; row++) {
            tmpRestaurantsPolygons[i].geometry.coordinates[c][row].reverse();
          }
        }
        console.log('reversed coords: ', tmpRestaurantsPolygons[i]);
        const polygonGeoObject = new ymaps.GeoObject(
          tmpRestaurantsPolygons[i],
          {fillColor: '#32CD32', strokeWidth: 1, opacity: 0.5, interactivityModel: 'default#transparent'}
        );
        this.polygonCollection.add(polygonGeoObject);
      }
    }
    if (this.restaurants.length > 0) {
      for (let i = 0; i < this.restaurants.length; i++) {
        const restaurantGeoObject = new ymaps.Placemark(
          [this.restaurants[i]['lat'], this.restaurants[i]['lon']], {
            hintContent: this.restaurants[i]['address'],
            balloonContentHeader: this.restaurants[i]['address'],
            balloonContentBody: '+' + this.restaurants[i]['phones'],
            balloonContentFooter: '<a href=рестораны/' + this.restaurants[i]['id'] + '>Подробнее</a>'
          }, {
            iconLayout: 'default#image',
            iconImageHref: 'assets/images/map-place-mark-icon.png',
            iconImageSize: [30, 30]
          }
        );
        this.restaurantCollection.add(restaurantGeoObject);
      }
      this.map.geoObjects.add(this.polygonCollection);
      this.map.geoObjects.add(this.restaurantCollection);
    }
    if (this.last_address.street)
      this.checkIfWeCanDeliverOrder();
  }

  getClientGeo(needGetDist = false) {
    this.isGeoStart = true;
    this.sharedData.changeRelatedStreets([]);
    navigator.geolocation.getCurrentPosition(position => {
      let lon = String(position.coords.longitude);
      let lat = String(position.coords.latitude);
      if (needGetDist) {
        const userCoord = [lat, lon];
        for (let i = 0; i < this.restaurants.length; i++) {
          const restCoord = [this.restaurants[i].lat, this.restaurants[i].lon];
          const dist = ymaps.coordSystem.geo.getDistance(userCoord, restCoord);
          this.restaurants[i].distance = Math.round(dist / 1000);
        }
        this.restaurants.sort(function (a, b) {
          return a['distance'] - b['distance'];
        });
      }
      this.mainService.getClientAdress(lat, lon)
        .subscribe(data => {
            this.last_address.street = data['address_details']['street'];
            this.last_address.house = data['address_details']['house'];
            this.last_address.corp = data['address_details']['corp'];
            this.sharedData.changeCanWeDeliver(!!data['can_we_deliver']['result']);
            this.deliveryImpossibilityReason = this.canWeDeliver ? '' : data['can_we_deliver']['reason'];
            this.sharedData.changeLastAddress(this.last_address);
            if (data['address_details']['street']) {
              this.deliveryImpossibilityReason = this.canWeDeliver ? '' : data['can_we_deliver']['reason'];
              if (needGetDist) {
                this.renderLastAddress();
                return;
              }
              this.requestStreetsSuggestions(this.last_address.street);
            } else {
              this.deliveryImpossibilityReason = 'Извините, не удалось определить ваше местоположение';
            }
          },
          error => {
            console.log('error while get clientGeo: ', error);
            this.menuService.defaultErrorDialog();
            this.sharedData.changeCanWeDeliver(false);
          });
    });
    this.isGeoStart = false;
  }

  onSelect(street) {
    this.last_address['street'] = street;
    this.sharedData.changeLastAddress(this.last_address);
    this.checkIfWeCanDeliverOrder();
  }

  setSubscriptions() {
    this.subscriptions.push(
      this.sharedData.currentLastAddress.subscribe(data => this.last_address = data),
      this.sharedData.currentCanWeDeliver.subscribe(data => this.canWeDeliver = data),
      this.sharedData.currentRelatedStreets.subscribe(data => this.related = data),
      this.sharedData.currentFinishedCheckingDeliveryPossibility.subscribe(data => this.finishedCheckingDeliveryPossibility = data));
  }

  ngOnInit() {
    this.setSubscriptions();
    this.isMobileApp = this.rootData.is_mobile_app;
    this.getMapData();
  }

  ngOnDestroy() {
    if (typeof this.map !== 'undefined') {
      this.map.destroy();
    }
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}

<div class="container">
    <ol class="breadcrumb" *ngIf="menu !== undefined">
        <li><a routerLink="/">Меню</a></li>
        <li><a routerLink="/{{ menu.id }}-{{ menu.slug }}">{{ menu.name }}</a></li>
    </ol>
    <div class="primary">
        <app-dishes-list></app-dishes-list>
        <a (click)="goBack()" class="back-page"> назад</a>
    </div>
</div>

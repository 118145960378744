import { Component, HostListener, OnInit } from '@angular/core';
import { MainMenuService } from "../main-menu.service";
import { MainService } from "../main.service";
import { SharedDataService } from "../shared-data.service";

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  orders = [];
  client: any;
  isLoading = false;
  is_telegram_connected: boolean;
  company: any;
  justAddedToBasket: boolean;
  private allOrdersLoaded = false;

  constructor(
    private menuService: MainMenuService,
    private mainService: MainService,
    private sharedData: SharedDataService
  ) {
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if ((window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight && !this.allOrdersLoaded) {
      this.getOrders();
    }
  }

  getOrders(added?: boolean) {
    if (this.isLoading) {
      console.log('skiping because loading already');
      return;
    }

    this.isLoading = true;

    let data = {
      'type': 'get'
    };

    if (this.orders.length > 0) {
      data['last_order_id'] = this.orders[this.orders.length - 1].id;
      console.log('last order id: ', this.orders[this.orders.length - 1].id);
    }

    this.mainService.getOrPostUserOrders(data)
      .subscribe(
        response => {
          response['title'] = 'Заказы';
          if (response['orders'] && response['orders'].length) {
            if (this.orders.length) {
              response['orders'].forEach(order => {
                this.orders.push(order);
              });
            } else {
              this.orders = response['orders'];
            }
          } else {
            this.allOrdersLoaded = true;
          }

          this.is_telegram_connected = response['client']['is_telegram_connected'];
          this.company = response['company'];
          this.client = response['client'];
          this.sharedData.changeData(response);
          this.isLoading = false;
          this.justAddedToBasket = added;
        },
        error => {
          this.isLoading = false;
          console.log('error while loading orders: ', error);
          this.menuService.defaultErrorDialog();
        }
      );

  }

  createPayment(order_id) {
    const data = {'order_id': order_id};
    this.mainService.createYandexPayment(data)
      .subscribe(
        response => {
          window.location.href = response['payment_url'];
        },
        error => {
          this.menuService.defaultErrorDialog();
        })
  }

  addAgain(orderId) {
    let data = {
      'type': 'add_again',
      'order_id': orderId
    };
    this.mainService.getOrPostUserOrders(data)
      .subscribe(
        response => {
          const dialogRef = this.menuService.openDialog(
            'Отлично!',
            'Блюда добавлены в корзину',
            true,
            true
          );
          dialogRef.subscribe(event => {
            this.isLoading = false;
            // this.getOrders(true);
          });
        },
        error => {
          console.log('error while adding order again: ', error);
          this.isLoading = false;
          this.menuService.defaultErrorDialog();
        }
      );
  }


  ngOnInit() {
    this.getOrders();
  }

}

<div class="primary">
  <div class="container">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="cart text-center" id="order_table">
          <app-products-carts></app-products-carts>
          <hr>
          <button *ngIf="basket_positions" (click)="addAllUnitsToBasket()" [ngClass]="justAddedToBasket ? 'active' : ''" class="btn btn-info">Добавить в корзину</button>
        </div>
      </div>
    </div>
  </div>
</div>

import { Directive, HostListener, ElementRef, AfterViewInit, EventEmitter, Output } from "@angular/core";

@Directive({
  selector: '[appAutoFocus]'
})
export class AutofocusDirective implements AfterViewInit {

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();

  private pos: number;
  private val: string;
  private specialCharsAmount: number;
  private specialChars = ['_', '(', ')', '-', ' '];

  constructor(
    private el: ElementRef
  ) {
  }

  @HostListener('keyup', ['$event']) onInputChange(event) {
    this.getSpecialCharsAmount();
    if (this.pos === 0) {
      this.setPosition(1);
    } else if (this.pos > this.val.length + this.specialCharsAmount) {
      this.setPosition(this.val.length + this.specialCharsAmount);
    } else {
      switch (event.key) {
        case('ArrowLeft'): {
          while (this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos))) {
            this.pos -= 1;
            if (!this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos - 1))) break;
          }
          this.setPosition(this.pos);
          break;
        }
        case('ArrowRight'): {
          while (this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos))) {
            this.pos += 1;
            if (!this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos + 1))) break;
          }
          this.setPosition(this.pos);
          break;
        }
      }
    }
  }

  @HostListener('click', ['$event']) onClick(event) {
    this.getSpecialCharsAmount();
    if (this.val) {
      if (this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos)) && this.val) {
        while (this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos))) {
          this.pos -= 1;
          if (!this.specialChars.includes(this.el.nativeElement.value.charAt(this.pos - 1))) break;
        }
        console.log(this.pos);
        this.setPosition(this.pos);
      }
    } else {
      this.setPosition(1);
    }
  }

  ngAfterViewInit() {
    this.el.nativeElement.focus();
    this.setPosition(1);
  }

  getSpecialCharsAmount() {
    this.pos = this.el.nativeElement.selectionStart;
    this.val = this.el.nativeElement.value;
    this.specialChars.forEach(char => {
      this.val = this.val.split(char).join('');
    });
    switch (true) {
      case [0, 1, 2, 3].includes(this.val.length): {
        this.specialCharsAmount = 1;
        break;
      }
      case [4, 5, 6].includes(this.val.length): {
        this.specialCharsAmount = 3;
        break;
      }
      case [7, 8].includes(this.val.length): {
        this.specialCharsAmount = 4;
        break;
      }
      case [9, 10].includes(this.val.length): {
        this.specialCharsAmount = 5;
        break;
      }
    }
  }

  setPosition(pos: Number) {
    setTimeout(() => {
      this.el.nativeElement.selectionStart = pos;
      this.el.nativeElement.selectionEnd = pos;
    });
  }
}

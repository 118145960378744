<div class="cart-list">

  <div class="cart-prod" *ngFor="let bp of basket_positions" id="{{ bp.position.id }}">
    <a class="cart-prod-remove" (click)="delete( bp )" *ngIf="showControls">
      <i class="icon-remove"></i>
    </a>
    <div class="cart-prod-header">
      <span class="cart-prod-numb">{{ bp.quantity }}</span>
      <div class="cart-prod-labels">
        <div *ngIf="bp.position.milliliters" class="label label-default">{{ bp.position.milliliters }} мл
        </div>
        <div *ngIf="bp.position.capacity" class="label label-default">{{ bp.position.capacity }}</div>
        <div *ngIf="bp.position.calories" class="label label-primary" style="display: none;">{{bp.position.calories }}&nbsp;ккал
        </div>
      </div>

      <div class="cart-prod-img card-prod-slider">
        <div *ngFor="let image of bp.position.images" [ngStyle]="{'background-image': 'url(' + image + ')'}"
             class="product-open-img"></div>
      </div>

      <div class="product-action" *ngIf="showControls">
        <a (click)="bpOperation(bp, 'decrease_quantity')" class="product-btn remove-from-cart">
          <i class="icon-minus"></i>
        </a>
        <a (click)="bpOperation(bp, 'increase_quantity')" class="product-btn add-to-cart">
          <i class="icon-plus"></i>
        </a>
      </div>
    </div>

    <div class="cart-prod-body">
      <div class="cart-prod-name">
        <a
          routerLink="/{{ bp.position.menu.id }}-{{ bp.position.menu.slug }}/{{ bp.position.id }}-{{ bp.position.slug }}.html"
          class="product-box"
          *ngIf="bp.position.category == null">
          {{ bp.position.name }}
        </a>
        <a
          routerLink="/{{ bp.position.menu.id }}-{{ bp.position.menu.slug }}/{{ bp.position.category.id }}-{{ bp.position.category.slug }}/{{ bp.position.id }}-{{ bp.position.slug }}.html"
          class="product-box" *ngIf="bp.position.category != null">
          {{ bp.position.name }}
        </a>
      </div>
      <div class="cart-prod-name" *ngIf="bp.parts && bp.parts.length > 0">
        <div *ngIf="!this.currentUrl.match('/s/')">Ваш выбор:</div>
        <div *ngIf="this.currentUrl.match('/s/')">Выбор вашего друга:</div>
        <div *ngFor="let part of bp.parts"> {{ part.position.name }}</div>
      </div>
      <div class="cart-prod-name" *ngIf="bp.toppings && bp.toppings.length > 0">
        Топпинги:
        <div *ngFor="let topping of bp.toppings"> {{ topping.position.name }} {{ topping.quantity }}&nbsp;шт<span *ngIf="topping.quantity > 1"> x {{ topping.price_single_item }}&nbsp;&#8381;</span>
        </div>
      </div>
    </div>

    <div class="cart-prod-footer">
      <div class="row">
        <div class="col-xs-6">
          <div class="cart-prod-price">
            <div id="amount{{ bp.id }}" class="cart-prod-price-title">{{ bp.quantity }}&nbsp;шт.</div>
            <div class="cart-prod-price-numb t-grey" *ngIf="bp.price_total">{{ bp.price_total / bp.quantity }}&nbsp;&#8381;</div>
            <div class="cart-prod-price-numb t-grey" *ngIf="bp.position.loyalty_points_price">{{
              bp.position.loyalty_points_price }}&nbsp;баллов
            </div>
          </div>
        </div>
        <div class="col-xs-6" *ngIf="bp.quantity > 1 && bp.price_total > 0">
          <div class="cart-prod-price text-right">
            <div class="cart-prod-price-title">ИТОГО:</div>
            <div class="cart-prod-price-numb">{{ bp.price_total }}&nbsp;&#8381;</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

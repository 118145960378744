/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../dishes-list/dishes-list.component.ngfactory";
import * as i5 from "../dishes-list/dishes-list.component";
import * as i6 from "../shared-data.service";
import * as i7 from "../main-menu.service";
import * as i8 from "./category.component";
import * as i9 from "../main.service";
var styles_CategoryComponent = [i0.styles];
var RenderType_CategoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryComponent, data: {} });
export { RenderType_CategoryComponent as RenderType_CategoryComponent };
function View_CategoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "ol", [["class", "breadcrumb"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\u041C\u0435\u043D\u044E"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 3, 0, currVal_2); var currVal_5 = i1.ɵinlineInterpolate(2, "/", _co.menu.id, "-", _co.menu.slug, ""); _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 7).target; var currVal_4 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_6 = _co.menu.name; _ck(_v, 8, 0, currVal_6); }); }
export function View_CategoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "primary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-dishes-list", [], null, null, null, i4.View_DishesListComponent_0, i4.RenderType_DishesListComponent)), i1.ɵdid(5, 245760, null, 0, i5.DishesListComponent, [i6.SharedDataService, i7.MainMenuService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "back-page"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u043D\u0430\u0437\u0430\u0434"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.menu !== undefined); _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_CategoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-category", [], null, null, null, View_CategoryComponent_0, RenderType_CategoryComponent)), i1.ɵdid(1, 114688, null, 0, i8.CategoryComponent, [i9.MainService, i6.SharedDataService, i2.ActivatedRoute, i3.Location, i2.Router, i7.MainMenuService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoryComponentNgFactory = i1.ɵccf("app-category", i8.CategoryComponent, View_CategoryComponent_Host_0, {}, {}, []);
export { CategoryComponentNgFactory as CategoryComponentNgFactory };

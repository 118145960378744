<div class="container">
  <div class="primary primary-product" *ngIf="dish">
    <ol class="breadcrumb">
      <li><a routerLink="/">Меню</a></li>
      <li *ngIf="dish.menu"><a routerLink="/{{ dish.menu.id }}-{{ dish.menu.slug }}">{{ dish.menu.name }}</a></li>
      <li *ngIf="dish.category">
        <a routerLink="/{{ dish.menu.id }}-{{ dish.menu.slug }}/{{ dish.category.id }}-{{ dish.category.slug }}">{{
        dish.category.name }}</a></li>
    </ol>

    <div id="position" class="row">
      <div class="col-md-6 col-md-offset-3">

        <div class="product-open">

          <div class="product-open-header">
            <div class="product-open-slider">

              <ng-container *ngIf="dish.images.length !== 0">
                <div *ngFor="let image of dish.images"
                     [ngStyle]="{ 'background-image' : 'url(' +  image + ')'}"
                     class="product-open-img">

                  <button class="btn btn-block btn-primary waves-effect waves-classic" type="button"
                          style="z-index: 2147483647; position: absolute;" *ngIf="client && client.role=='manager'"
                          (click)="delImg(image)">
                    Удалить картинку
                  </button>

                </div>
              </ng-container>

              <div *ngIf="dish.images.length === 0"
                   style="background-image: url('../../assets/images/noimage.jpg');"
                   class="product-open-img">
              </div>

              <div *ngIf="client && client.role == 'manager'">
                <input name="addImage" id="addImage" type="file" style="display:none;"
                       (change)="uploadImg($event.target.files)" #imageInput/>
                <button class="btn btn-block btn-primary waves-effect
                                  waves-classic" type="button"
                        (click)="imageInput.click()" style="z-index: 999; position: absolute;">
                  Добавить картинку
                </button>
              </div>

            </div>
            <div class="product-open-header-info">
              <div class="row">
                <div class="col-md-8 col-md-offset-2">
                  <div class="product-open-title">{{ dish.name }}</div>
                  <div>
                    <div *ngIf="checkIsNotNullUndefinedOrZero(gramsTotal)" class="label label-default">{{ gramsTotal }} г</div>
                    <div *ngIf="checkIsNotNullUndefinedOrZero(millilitersTotal)" class="label label-default">{{ millilitersTotal }} мл</div>
                    <div *ngIf="checkIsNotNullUndefinedOrZero(caloriesTotal)" class="label label-primary">{{ caloriesTotal }} ккал</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="product-pfc">
                  <span class="pfc-info" *ngIf="checkIsNotNullUndefinedOrZero(proteinsTotal)">Белки: {{ proteinsTotal }}&nbsp;</span>
                  <span class="pfc-info" *ngIf="checkIsNotNullUndefinedOrZero(fatsTotal)">Жиры: {{ fatsTotal }}&nbsp;</span>
                  <span class="pfc-info" *ngIf="checkIsNotNullUndefinedOrZero(carbsTotal)">Углеводы: {{ carbsTotal }}&nbsp;</span>
                </div>
              </div>
          </div>
          <div class="product-open-body">
            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="product-open-text">
                  {{ dish.description }}
                </div>


                <div *ngIf="dish.pos_type === 'constructor'">

                  <div class="tab">

                    <div class="group" *ngFor="let part_group of dish.parts">
                      <p>{{ part_group.name }}</p>
                      <mat-radio-group [(ngModel)]="part_group.selected" [required]="true" class="md-no-underline"
                                       (change)="getTotal()">
                        <div *ngFor="let d of part_group.dishes">
                          <mat-radio-button (click)="changeUrlParts(part_group.id, d.id)" [value]="d" class="green-style">{{ d.name }} <span *ngIf="d.price"> {{ d.price }} &#8381;</span>
                          </mat-radio-button>
                          <span *ngIf="client && client.role=='manager'"
                                [ngStyle]="{'display': (client.role=='manager' ? 'block' : 'none')}">tillipad id: {{ d.ext_id }} <br/> tillypad_name: {{ d.tillypad_name }} <br/>iiko_id: {{ d.iiko_id }} <br/>iiko_name: {{ d.iiko_name }} </span>
                        </div>
                      </mat-radio-group>
                    </div>

                  </div><!--.tab-->

                  <div class="product-open-table toppings" *ngIf="dish.toppings && dish.toppings.length > 0">
                    <table *ngFor="let topping_group of dish.toppings">
                      <tbody>
                      <tr *ngFor="let topping of topping_group.dishes">
                        <td>
                          <div class="spin">
                            <a class="product-btn remove-from-cart minus-topping" (click)="decreaseAmount(topping)">
                              <i class="icon-minus"></i>
                            </a>
                            <span class="count-topping">{{ !!topping.qty ? topping.qty : 0 }}</span>
                            <a class="product-btn add-to-cart plus-topping" (click)="increaseQuantity(topping)">
                              <i class="icon-plus"></i>
                            </a>
                          </div>
                        </td>
                        <td>
                          {{ topping.name }} <span *ngIf="client && client.role=='manager'"
                                                   [ngStyle]="{'display': (client.role=='manager' ? 'block' : 'none')}">ext_id: {{ topping.ext_id }} <br/>iiko_id: {{ topping.iiko_id }} </span>
                        </td>
                        <td id="price{{ topping.id }}">
                          {{ topping.price }}&nbsp;&#8381;
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </div>  <!--.product-open-table-->


                </div><!--ng-constructor-->

                <div class="product-open-price">
                  <div class="row">
                    <div class="col-md-6" *ngIf="dish.price">
                      <div class="product-open-price-item text-center">
                        <div class="product-open-price-title">Цена</div>
                        <div class="product-open-price-numb">{{ dish.price }} &nbsp;&#8381;</div>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="dish.loyalty_points_price">
                      <div class="product-open-price-item text-center">
                        <div class="product-open-price-title">Цена в баллах</div>
                        <div class="product-open-price-numb">{{ dish.loyalty_points_price }} &nbsp;баллов</div>
                      </div>
                    </div>
                    <!-- Поидее устаревшая часть
                                                            <div class="col-md-6" *ngIf="dish.pos_type == 'group' && selected_group_part ">
                                                                <div class="product-open-price-item text-center">
                                                                    <div class="product-open-price-title">Цена</div>
                                                                    <div class="product-open-price-numb">{{ selected_group_part.price }} &nbsp;&#8381;</div>
                                                                </div>
                                                            </div>
                    -->
                    <div class="col-md-6"
                         *ngIf="dish.pos_type == 'constructor' && dish.toppings.length > 0 && price_total && dish.price !== price_total">
                      <div class="product-open-price-item text-center">
                        <div class="product-open-price-title">Итого</div>
                        <div class="product-open-price-numb bold t-green">
                          <span>{{ price_total }}&nbsp;&#8381;</span>
                        </div>
                      </div>
                    </div>

                    <div clas="col-md-6" *ngIf="client && client.role=='manager'"
                         [ngStyle]="{'display': (client && client.role=='manager' ? 'inline-block' : 'none'), 'padding-left': '2rem'}">
                      <div class="product-open-price-item text-center">
                        <label>Ext_id:</label>
                        <input type="text" [(ngModel)]="ext_id"><input type="button" (click)="saveExtId()"
                                                                       value="V"><br/>
                        <span>{{ ext_name }}</span>
                      </div>
                      <div class="product-open-price-item text-center">
                        <label>Iiko_id:</label>
                        <input type="text" [(ngModel)]="iiko_id"><input type="button" (click)="saveExtId()"
                                                                        value="V"><br/>
                        <span>{{ iiko_name }}</span>
                      </div>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>


        </div><!--.product-open-->

        <div class="product-open-footer">


          <button id="constructorToBasket" class="btn btn-success btn-w-m"
                  type="button"
                  (click)="toBasket()">
            Заказать
            <span *ngIf="price_total && !dish.loyalty_points_price">
                            ({{ price_total }} &#8381;)
                        </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="relatedDishes != null">
    <div class="col-md-6 col-md-offset-3">
      <div class="related-dishes-card">
        <div class="related-dishes-order">C этим заказывают:</div>
        <div id="related-dishes">
          <ng-container *ngFor="let dishRelated of relatedDishes">
            <div class="product col" id="{{ dishRelated.id }}">
              <a class="product-box" title="{{ dishRelated.name }}" routerLink="/{{ dishRelated.link }}">
                <div class="product-name">
                  <span class="name-dish">{{ dishRelated.name }}</span>
                </div>
                <div class="product-wrap">
                  <div class="product-img">
                    <div class="product-img-slider">
                      <div *ngFor="let image of dishRelated.images">
                        <div class="product-link">
                          <div class="product-img-slider-inner" [ngStyle]="{'background-image': 'url(' + image + ')'}"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-price" *ngIf="dishRelated.price">
                  {{ dishRelated.price }} &#8381;
                </div>
                <div class="product-price" *ngIf="dishRelated.loyalty_points_price">
                  {{ dishRelated.loyalty_points_price }} баллов
                </div>
              </a>
              <div class="product-action">
                <a class="product-btn add-to-cart order-button" id="link_{{ dishRelated.id }}" (click)="relatedToBasket(dishRelated)">
                  <i class="icon-plus"></i>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div id="notFound" class="prymary" *ngIf="false">
    <div role="alert" class="alert alert-warning">Данное блюдо недоступно!</div>
  </div>
  <a (click)="goBack()" class="back-page"> назад</a>

</div>

<div *ngIf="isButtonDisabled">
  <div class="screenDisabled">
  </div>
  <div class="centerSpinner">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div>
    <!-- <div id="overlay" ng-if="isLoading"> понять потом или надо заменять broadcast

    </div> -->
</div>
<app-top-menu-mobi></app-top-menu-mobi>
<!--.mobi-menu-->
<div class="main bg-wood">

    <div class="page">
        <div class="header">
            <div class="container">
                <div class="header-inner">
                    <div class="logo">
                        <a routerLink="/" >
                            <img src="{{ companyLogo }}" alt="{{ companyName }}">
                        </a>

                      <a [href]="mobileAppLink" *ngIf="showNewDesignBanner" class="logo-banner">
                          <h5 class="logo-banner__text">
                            Скоро мы перейдем на новый сайт, а данная версия будет закрыта
                            <br>
                            <a>Перейти на новую версию</a>
                          </h5>
                      </a>
                    </div>
                    <!--.logo-->
                    <div>
                      <app-top-menu [searchIsVisible]="searchIsVisible"></app-top-menu>
                    </div>
                    <div class="burger" (click)="burgerClick()">
                        <span></span>
                    </div>
                    <!--.header-inner-->
                </div>
            </div>
            <!--.header-->
        </div>
        <div id="popup" class="container" style="display: none; position: fixed; z-index: 9999;">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <div id="login-text"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div id="popup" class="container" style="display: none; position: fixed; z-index: 9999;">-->
            <!--<div class="modal-dialog" role="document">-->
                <!--<div class="modal-content">-->
                    <!--<div class="modal-body">-->
                        <!--<div id="login-text"></div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->

        <div *ngIf="showBgBar" style="display: block;">
              <!--<div *ngIf="showBgBar">-->
                  <div [ngStyle]="{'background-image': bgImage | async}" class="page-header page-header-striped">
                      <div class="container">
                          <div class="h1" *ngIf="title" style="display: none;" [ngStyle]="{display: (title ? 'block' : 'none')}">{{ title }}</div>
                      </div>
                  </div>
              <!--</div>-->
        </div>

        <div class="page">
            <router-outlet></router-outlet>
        </div><!--.page-->

        <div class="device-btn" *ngIf="!mobileApp && mobileApp !== undefined">
            <a href="https://itunes.apple.com/ru/app/id{{ app_store_id }}?mt=8"><img src='assets/images/app-store.svg' width="170" height="60" alt="app store"></a>
            <a href="https://play.google.com/store/apps/details?id={{ google_play_id }}&hl=ru"><img src='assets/images/google-play.svg' width="170" height="60"
                                                                                                                    alt="google play"></a>
        </div>

    </div><!--.main-->
    <footer id="footer">
        <div class="container">
            <div class="row">
                <div class="col-sm-4">
                    <span class="by">Предоставлено: <a href="http://online-cafe.me" target="_blank">online-cafe.me</a></span>
                </div>
                <div class="col-sm-1">
                </div>
                <div class="col-sm-7">
                    <!--                    <p>ООО "На Парах"  ОГРН ХХХХХХХХ  Политика конфиденциальности</p> -->
                  <span style="margin-right: 1ex;"><a routerLink="/контакты">Контакты</a></span>
                  <span style="margin-right: 1ex;"><a routerLink="/policy">Пользовательское соглашение</a></span>
                  <span style="margin-right: 1ex;"><a routerLink="/условия_доставки">Условия доставки</a></span>
                  <span><a routerLink="/проверка-доставки">Проверка доставки</a></span>
                </div>
            </div>
        </div>
    </footer>
</div>

<div class="fly-cart" id="basketLink" [ngStyle]="{display: (basketCounter > 0 && showFlyBasket ? 'block' : 'none') }" [ngClass]="justAddedToBasket ? 'added' : ''">
    <a routerLink="/корзина">
        <div class="fly-cart-text" style="cursor: pointer;" [ngClass]="justAddedToBasket ? 'active' : ''" *ngIf="justAddedToBasket">
            Добавлено в корзину!
        </div>
    </a>
    <div class="fly-cart-icon">
        <span class="fly-cart-numb" id="basketCounter" *ngIf="basketCounter > 0">{{ basketCounter }}</span>
        <a routerLink="/корзина">
            <i class="icon-busket"></i>
        </a>
    </div>
</div>

<div *ngIf="mobileAppType && showAlertBanner" class="page-overlay">
  <div class="alert-banner">
    <div class="alert-banner-content">
      <div class="alert-banner-text">Эта версия приложения больше не поддерживается. Пожалуйста скачайте новое приложение.</div>
    </div>
    <div class="device-btn">
      <a *ngIf="mobileAppType === 'ios'" href="https://itunes.apple.com/ru/app/id{{ app_store_id }}?mt=8">
        <img src='assets/images/app-store.svg' width="170" height="60" alt="app store">
      </a>
      <a *ngIf="mobileAppType === 'android'" href="https://play.google.com/store/apps/details?id={{ google_play_id }}&hl=ru">
        <img src='assets/images/google-play.svg' width="170" height="60" alt="google play">
      </a>
    </div>
  </div>
</div>

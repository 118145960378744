<div class="container">
  <div class="primary">
    <div class="row">
      <div class="col-md-8 col-md-offset-2">
        <div class="order">
          <div style="margin-top: 10px">
            <div id="deliveryAddressContainer"
                 *ngIf="isChildMapShowing"
                 [ngClass]="{'form-group': true, 'has-error': (!canWeDeliver && last_address) }">
              <div class="row">
                <div class="col-xs-10 order-title">
                  Проверьте возможность доставки по вашему адресу
                </div>
                <div (click)="childMap.getClientGeo()" class="delivery-location-icon history-icon col-xs-2"></div>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <label>Улица:</label>
                  <input #deliveryStreet
                         type="text"
                         required
                         placeholder="Улица *"
                         class="form-control"
                         (focus)="childMap.searchStreet(deliveryStreetInput.nativeElement)"
                         (blur)="childMap.checkIfWeCanDeliverOrder()"
                         [(ngModel)]="last_address.street"
                         [matAutocomplete]="auto">
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of related" (onSelectionChange)="childMap.onSelect(option)" [value]="option">
                      <span *ngIf="option">{{option}}</span>
                    </mat-option>
                  </mat-autocomplete>
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <label>Дом:</label>
                  <input id="deliveryHouse"
                         type="text"
                         required
                         placeholder="Номер дома *"
                         class="form-control"
                         maxlength="6"
                         [(ngModel)]="last_address.house"
                         (blur)="childMap.checkIfWeCanDeliverOrder()"
                         (keyup.enter)="childMap.checkIfWeCanDeliverOrder()"
                         (focus)="childMap.onFocusInputAddress()">
                </div>
                <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <label>Корпус:</label>
                  <input id="deliveryCorp"
                         type="text"
                         placeholder="Корпус"
                         class="form-control"
                         maxlength="4"
                         [(ngModel)]="last_address.corp"
                         (blur)="childMap.checkIfWeCanDeliverOrder()"
                         (keyup.enter)="childMap.checkIfWeCanDeliverOrder()"
                         (focus)="childMap.onFocusInputAddress()">
                </div>
              </div>
              <span class="help-block" *ngIf="isChildMapShowing && childMap && childMap.deliveryImpossibilityReason && !canWeDeliver && last_address">{{ childMap.deliveryImpossibilityReason }}</span>
               <span class="help-block success-text" *ngIf="isSuccessAddress()">
                  Доставка возможна
              </span>
            </div>
            <div id="deliveryMap" *ngIf="restaurants_polygons.length !== 0 || restaurants.length !== 0">
              <app-map [restaurants]="restaurants" [restaurants_polygons]="restaurants_polygons" #childMapID></app-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <a (click)="goBack()" class="back-page"> назад</a>
</div>

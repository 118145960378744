<div class="container">
    <form class="form-search visible-xs-block visible-sm-block" (ngSubmit)="makeSearch()">
      <mat-form-field floatLabel="never">
        <input #searchDish matInput type="search" [(ngModel)]="searchTerm" name="search" placeholder="ПОИСК"
               [matAutocomplete]="auto" class="search-label">
        <i mat-button *ngIf="searchTerm" matSuffix mat-icon-button aria-label="Clear" (click)="searchTerm=''">
          <mat-icon>close</mat-icon>
        </i>
        <mat-autocomplete #auto="matAutocomplete" minlength="2">
          <mat-option *ngFor="let option of suggestions" (click)="onSelect(option.dish)" (onSelectionChange)="onSelect(option.dish)" [value]="option.display">
            <span>{{ option.display }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    </form>
    <div class="categories">
        <div class="row">


            <div class="menu col-md-4 col-md-offset-0 col-sm-8 col-sm-offset-2"
                 *ngFor ="let menu of menus; let i = index; trackBy: trackMenus" [class.menu-margin]="i > 2">
                <div class="cat">
                    <div *ngIf="menu.image != null" class="cat-img">
                        <div class="active">
                            <a routerLink="{{ menu.id }}-{{ menu.slug }}">
                                <img [src]="menu.image">
                            </a>
                        </div>
                    </div>

                    <div *ngIf="menu.image == null" class="cat-img">
                        <div class="carousel-item">
                            <img src="assets/images/noimage-menu.jpg">
                        </div>
                    </div>
                    <a class="btn btn-info btn-lg btn-block" style="color: #fff !important;" routerLink="{{ menu.id }}-{{ menu.slug }}">
                       {{ menu.name }}
                    </a>
                </div>
            </div>

        </div>
    </div>
</div>
